import React from "react";
import ReactDom from "react-dom";
import AvatarEditor from "react-avatar-editor";
import { Slider } from "@material-ui/core";
import ApiService from "../service/ApiService";
import {
  MDBPopover,
  MDBPopoverBody,
  MDBBtn
} from "mdbreact";
import "./profile.css";
import { connect } from "react-redux";
 class ProfileImage extends React.Component {
  constructor(props) {
    super();
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleFileChange = this._handleFileChange.bind(this);
    this._setEditorRef = this._setEditorRef.bind(this);
    this._handleZoomSlider = this._handleZoomSlider.bind(this);
    this.state = {
      cropperOpen: false,
      img: null,
      zoom: 2,
      croppedImg: null,
      defaultimg: "/user-img.png",
      vieweeid: null,
      deleted: "true",
    };
  }
  //   componentDidMount() {
  //     let profileimg = this.props.profileimg;
  //     this.setState({
  //       croppedImg: profileimg,
  //       vieweeid:this.props.vieweeid
  //     });

  //   }
  //   componentDidUpdate(){
  //     if(this.state.vieweeid !== this.props.vieweeid){
  //       this.setState({
  //        vieweeid:  this.props.vieweeid,
  //        croppedImg:this.props.profileimg
  //       })
  //     }
  //  }

  _handleFileChange(e) {
    window.URL = window.URL || window.webkitURL;
    let url = window.URL.createObjectURL(e.target.files[0]);
    ReactDom.findDOMNode(this.refs.in).value = "";
    let state = this.state;
    state.img = url;
    state.cropperOpen = true;
    this.setState(state);
  }
  _handleSave(e) {
    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      let state = this.state;
      state.img = null;
      state.cropperOpen = false;
      state.croppedImg = croppedImg;
      this.setState(state);

      var fileDetails = this.state.croppedImg;
      var indexOfBase64 = fileDetails.indexOf("base64");
      var fileByteArray = fileDetails.substr(indexOfBase64 + 7);
      var indexOfExtension = fileDetails.indexOf("/");
      var indexOfDeleimiter = fileDetails.indexOf(";");
      var fileExtension = fileDetails.substring(
        indexOfExtension + 1,
        indexOfDeleimiter
      );
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      var userbody = {
        userProfileId: this.props.LoginData.loginSuccesData.userProfileId,
        imageByteArrayString: fileByteArray,
        sessionId: cookieValue,
        extension: "." + fileExtension,
      };

      ApiService.uploadProfilePic(userbody)
        .then((res) => {
          this.setState({
            // croppedImg: res.data.dataItem.filePath,

            deleted: "false",
          });
          var profileimage;
          profileimage = res.data.dataItem.filePath;
          this.props.sendProfile(profileimage);
        })
        .catch((err) => {
          Promise.reject(err);
          // sessionStorage.clear("userProfileId");
          // window.location = "./";
        });
    }
  }
  _handleCancel() {
    let state = this.state;
    state.cropperOpen = false;
    this.setState(state);
  }
  _setEditorRef(editor) {
    this.editor = editor;
  }
  _handleZoomSlider(event, value) {
    let state = this.state;
    state.zoom = value;
    this.setState(state);
  }
  removeProfile = (e) => {
    e.preventDefault();
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let userProfileId = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.DeleteProfile("profile", userProfileId, cookieValue).then(
      (res) => {
        if (res.data.status === "success") {
          this.setState({
            croppedImg: null,
            deleted: "true",
          });
          var profileimage;
          profileimage = null;
          this.props.sendProfile(profileimage);
        }
      }
    );
  };

  render() {
    let profileimage;
    if (
      this.props.profileimg === null ||
      (this.props.profileimg === "" && this.state.deleted === "true")
    ) {
      profileimage = <img alt="profile" src={this.state.defaultimg} />;
    }
    // else if(this.state.croppedImg !== null && this.state.croppedImg !== ""){
    //   profileimage = <img alt="profile" src={this.state.croppedImg} />
    // }
    else {
      profileimage = <img alt="profile" src={this.props.profileimg} />;
    }
    return (
      <div className="myimage">
        {profileimage}

        {this.props.selfprofile === "true" ? (
          <MDBPopover
            placement="right"
            popover
            clickable
            id="popper4"
            className="profile-pop"
          >
            <MDBBtn className="camera-button">
              <i
                className="fa fa-camera"
                style={{
                  padding: "7px",
                  cursor: "pointer",
                }}
              ></i>
            </MDBBtn>
            <div>
              <MDBPopoverBody>
                <button className="profile-update">
                  <i className="fa fa-image"></i>
                  Update
                  <input
                    ref="in"
                    type="file"
                    accept="image/*"
                    onChange={this._handleFileChange}
                    size="60"
                    style={{ position: "relative", opacity: "0" }}
                  />
                </button>

                <button
                  className="profile-update"
                  onClick={this.removeProfile.bind(this)}
                >
                  <i className="fa fa-trash"></i>
                  Remove
                </button>
              </MDBPopoverBody>
            </div>
          </MDBPopover>
        ) : (
          <></>
        )}
        {this.state.cropperOpen && (
          <div class="cropper-wrapper">
            <div className="disable-bg"> </div>
            <AvatarEditor
              ref={this._setEditorRef}
              image={this.state.img}
              width={200}
              height={200}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={this.state.zoom}
              rotate={0}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontSize: 14,
                  marginRight: 10,
                  paddingBottom: 22,
                  fontWeight: 600,
                  padding: "5px",
                  color: "#000",
                }}
              >
                Zoom
              </label>
              <Slider
                min={1}
                max={10}
                step={0.1}
                value={this.state.zoom}
                onChange={this._handleZoomSlider}
                style={{ width: 200, color: "#000" }}
              />
            </div>

            <div>
              <button
                label="CANCEL"
                labelposition="before"
                containerelement="label"
                onClick={this._handleCancel}
                className="profile-change-btn"
              >
                Cancel
              </button>
              <button
                label="SAVE"
                labelposition="before"
                containerelement="label"
                onClick={this._handleSave}
                className="profile-change-btn"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(ProfileImage);