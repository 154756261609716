import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import "./auth.css";
import { connect } from "react-redux";

const categories = [
  {
    id: 1,
    categoryName: "Music",
    categoryImage: require("../images/mic.svg"),
    categoryInfo: "Stage Artist, Composers, Instrumental",
    Checked: false,
  },
  {
    id: 2,
    categoryName: "Dancing",
    categoryImage: require("../images/dancing.svg"),
    categoryInfo: "Stage Artist, Composers, Instrumental",
    Checked: false,
  },
  {
    id: 3,
    categoryName: "Acting",
    categoryImage: require("../images/video.svg"),
    categoryInfo: "Stage Artist, Composers, Instrumental",
    Checked: false,
  },
  // {
  //   id: 4,
  //   categoryName: "Appreciator",
  //   categoryImage: require("../images/appr.svg"),
  //   categoryInfo: "Stage Artist, Composers, Instrumental",
  //   Checked: false,
  // },
];

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000000",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    height: "80px",
    width: "80px",
  },
}))(Tooltip);

class Category extends Component {
  constructor(props) {
    super();
    this.state = {
      roles: [],
    };
    this.selectRole = this.selectRole.bind(this);
  }
  componentDidMount() {
    localStorage.getItem("userform");
    if (this.props.LoginData.loginSuccesData.userProfileId) {
      this.props.history.push("/feed");
    }
    else if (localStorage.getItem("passion")){
         let selectedroles = JSON.parse(localStorage.getItem("passion"))
      
       
        
        this.setState({
          roles: selectedroles
        })

        if(selectedroles.includes("Music") && selectedroles.includes("Dancing") && selectedroles.includes("Acting")){
          document.getElementById("Dancing").checked = true;
          document.getElementById("Music").checked = true;
          document.getElementById("Acting").checked = true;
          // document.getElementById("Appreciator").disabled = true;
         
         }
         else if(selectedroles.includes("Music") && selectedroles.includes("Acting")){
          document.getElementById("Music").checked = true;
          document.getElementById("Acting").checked = true;
          // document.getElementById("Appreciator").disabled = true;
          
         }
         else if(selectedroles.includes("Music") && selectedroles.includes("Dancing")){
          document.getElementById("Music").checked = true;
          document.getElementById("Dancing").checked = true;
          // document.getElementById("Appreciator").disabled = true;
         
    
         }
         else if(selectedroles.includes("Acting") && selectedroles.includes("Dancing")){
          document.getElementById("Acting").checked = true;
          document.getElementById("Dancing").checked = true;
          // document.getElementById("Appreciator").disabled = true;
        
    
         }
         else if(selectedroles.includes("Acting") ){
          document.getElementById("Acting").checked = true;
          // document.getElementById("Appreciator").disabled = true;
         
         }
        
         else if(selectedroles.includes("Dancing") ){
          document.getElementById("Dancing").checked = true;
          // document.getElementById("Appreciator").disabled = true;
         
         }
         else if(selectedroles.includes("Music") ){
          document.getElementById("Music").checked = true;
          // document.getElementById("Appreciator").disabled = true;
         
         }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (localStorage.getItem("userform")) {
      let newdata = this.state.roles;
      localStorage.setItem("passion", JSON.stringify(newdata));
      this.props.history.push("/skills");
    } else {
      this.props.history.push("/sign-up");
    }
  }
  handleCancel(e){
    e.preventDefault();
    localStorage.clear('userform');
    this.props.history.push("/")
  }
  // disableothers = () => {
  //   if (this.state.roles.indexOf("Appreciator") >= 0) {
  //     document.getElementById("Acting").disabled = true;

  //     document.getElementById("Dancing").disabled = true;
  //     document.getElementById("Music").disabled = true;
  //   }
  // };

  // enableothers = () => {
  //   document.getElementById("Acting").disabled = false;
  //   document.getElementById("Dancing").disabled = false;
  //   document.getElementById("Music").disabled = false;
  // };

  // disableappreciator = () => {
  //   if (
  //     this.state.roles.indexOf("Music") >= 0 ||
  //     this.state.roles.indexOf("Acting") >= 0 ||
  //     this.state.roles.indexOf("Dancing") >= 0
  //   ) {
  //     document.getElementById("Appreciator").disabled = true;
  //   }
  // };

  // enableappreciator = () => {
  //   if (
  //     this.state.roles.indexOf("Music") <= -1 &&
  //     this.state.roles.indexOf("Acting") <= -1 &&
  //     this.state.roles.indexOf("Dancing") <= -1
  //   ) {
  //     document.getElementById("Appreciator").disabled = false;
  //   }
  // };

  selectRole(e) {
    if (e.target.checked === true) {
      this.setState(
        {
          roles: [...this.state.roles, e.target.value],
        },
        () => {
          // this.disableothers();
          // this.disableappreciator();
        }
      );
    } else {
      let remove = this.state.roles.indexOf(e.target.value);
      this.setState(
        {
          roles: this.state.roles.filter((_, i) => i !== remove),
        },
        () => {
          // this.enableothers();
          // this.enableappreciator();
        }
      );
    }
  }


  render() {
    var isEnabled = false
    if(this.state.roles.length > 0){
      isEnabled = true
    }
    return (
      <div className="container-fluid category-bg">
        <h2>Select your passion</h2>
        <MDBContainer>
          <MDBRow center>
            <MDBCol className="col-lg-12 col-md-10">
              <div className="category-cont">
                <ul>
                  {categories.map((category, i) => (
                    <li key={i}>
                      <input
                        type="checkbox"
                        id={category.categoryName}
                        name="role"
                        onClick={this.selectRole}
                        value={category.categoryName}
                        className="check"
                      />

                      <label
                        htmlFor={category.categoryName}
                        className={category.id}
                      >
                        <div className="info-tool">
                          <LightTooltip
                            disableFocusListener
                            title={category.categoryInfo}
                          >
                            <button>
                              <i className="fa fa-info"></i>
                            </button>
                          </LightTooltip>
                        </div>
                        <img alt="timer" src={category.categoryImage} />

                        <h4>{category.categoryName}</h4>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </MDBCol>
          
          </MDBRow>
        
          <div className="row button-controls" style={{maxWidth:"700px", margin:"20px auto"}}>
                <div className="col-md-2 col-sm-9">
                  <button value="cancel" className="button-cancel">
                    <Link to="/" onClick={this.handleCancel.bind(this)}>Cancel</Link>
                  </button>
                </div>

                <div className="col-md-2 offset-md-6 col-sm-2">
                  <button value="back" className="button-cancel">
                    <Link to="/sign-up">Back</Link>
                  </button>
                </div>
                <div className="col-md-2 col-sm-2">
                <button
                  type="submit"
                  rounded="true"
                  className="btn-login cat-next float-right"
                  onClick={this.handleSubmit.bind(this)}
                  disabled={!isEnabled}
                >
                  Next
                </button>
                </div>
              </div>
     
        </MDBContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(Category);