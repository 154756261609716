import React, { Component } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import ApiService from "../service/ApiService";
import { connect } from "react-redux";

class AppriciatesList extends Component {
  constructor(props) {
    super();
    this.state = {
      activeIndex: 0,
      followers: [],
      following: [],
      viewee: null,
      self: "false",
      updated: false,
    };
  }

  componentDidMount() {
    this.setState({
      viewee: this.props.location.vieweeid,
      self: this.props.location.self,
    });
    if (this.props.location.ActiveTab) {
      this.setState({
        activeIndex: this.props.location.ActiveTab,
      });
    }
    this.fetchAppreciatorList();
  }

  fetchAppreciatorList = () => {
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;

    let vieweeid = this.props.location.vieweeid;
    ApiService.GetFollowers(viewerId, vieweeid, cookieValue).then((res) => {
      if (res.data.dataItemslist.length > 0) {
        this.setState({
          followers: res.data.dataItemslist,
          viewee: this.props.location.vieweeid,
        });
      } else {
        this.setState({
          followers: null,
        });
      }
    });

    ApiService.GetFollowing(viewerId, vieweeid, cookieValue).then((res) => {
      if (res.data.dataItemslist.length > 0) {
        this.setState({
          following: res.data.dataItemslist,
          viewee: this.props.location.vieweeid,
        });
      } else {
        this.setState({
          following: null,
        });
      }
    });
  };

  handleChange = (_, activeIndex) => this.setState({ activeIndex });
  removefollowing = (id) => {
    // var follow;
    // follow = "false";
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let followdata = {
      followerUserProfileId: this.props.LoginData.loginSuccesData.userProfileId,
      followingUserProfileId: id,
      sessionId: cookieValue,
    };
    ApiService.UnFollowUser(followdata).then((res) => {
      if (res.data.status === "success") {
        this.setState({}, this.fetchAppreciatorList);
      }
    });
  };

  removefollower = (id) => {
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let followdata = {
      followerUserProfileId: id,
      followingUserProfileId: this.props.LoginData.loginSuccesData.userProfileId,
      sessionId: cookieValue,
    };
    ApiService.RemoveFollower(followdata).then((res) => {
      if (res.data.status === "success") {
        this.setState(
          {
            updated: true,
          },
          this.fetchAppreciatorList
        );
      }
    });
  };

  render() {
    const { activeIndex } = this.state;
    // let vieweeid = this.props.location.vieweeid;

    return (
      <div className="container-fluid category-bg">
        <div className="row">
          <div className="col-lg-8 col-md-12 mx-auto">
            <Tabs
              value={activeIndex}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              id="usertab"
            >
              <Tab label="Admirers" id="admirer" />
              <Tab label="Admiring" id="admirer" />
            </Tabs>
            {activeIndex === 0 && (
              <TabContainer>
                <div className="follower_sheet">
                  <ul>
                    {this.state.followers !== null ? (
                      this.state.followers &&
                      this.state.followers.map((followers, index) => (
                        <li key={index}>
                          <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-9 d-flex follow-mob">
                              <h5>
                                {followers.profilePic === null ? (
                                  <img src="/user-img.png" alt="" />
                                ) : (
                                  <img src={followers.profilePic} alt=""></img>
                                )}{" "}
                                <span className="appr_name">
                                  <p>{followers.userName}</p>
                                  <h5>{followers.fullName}</h5>{" "}
                                </span>
                              </h5>
                            </div>

                            {this.props.location.self === "true" ? (
                              <div className="col-lg-3 col-md-3 col-sm-3 follow-block">
                                <button
                                  className="friend_remove"
                                  onClick={() =>
                                    this.removefollower(followers.userProfileId)
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </li>
                      ))
                    ) : (
                      <>
                        <h2>No admirers yet</h2>
                      </>
                    )}
                  </ul>
                </div>
              </TabContainer>
            )}
            {activeIndex === 1 && (
              <TabContainer>
                <div className="follower_sheet">
                  <ul>
                    {this.state.following !== null ? (
                      this.state.following.map((following, index) => (
                        <li key={index}>
                          <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-9 d-flex follow-mob">
                              <h5>
                                {following.profilePic === null ? (
                                  <img src="/user-img.png" alt="" />
                                ) : (
                                  <img src={following.profilePic} alt=""></img>
                                )}{" "}
                                <span className="appr_name">
                                  <p>{following.userName}</p>
                                  <h5>{following.fullName}</h5>{" "}
                                </span>
                              </h5>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-3 follow-block">
                              {this.props.location.self === "true" ? (
                                <button
                                  className="friend_remove"
                                  onClick={() =>
                                    this.removefollowing(
                                      following.userProfileId
                                    )
                                  }
                                >
                                  Remove
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <>
                        <h2>Not admiring anyone yet</h2>
                      </>
                    )}
                  </ul>
                </div>
              </TabContainer>
            )}

            {/* <MDBNav className="mt-2 nav-pills mx-auto">
                <MDBNavItem>
                  <MDBNavLink link to="#" active={this.state.items["default"] === "1"} onClick={this.togglePills("default", "1")} >
                    Admirers
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink link to="#" active={this.state.items["default"] === "2"} onClick={this.togglePills("default", "2")} >
                    Admiring
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav> */}
            {/* <MDBTabContent activeItem={this.state.items["default"]}>
                <MDBTabPane tabId="1">
                
                </MDBTabPane>
                <MDBTabPane tabId="2">
               
                </MDBTabPane>
                
              </MDBTabContent> */}
          </div>
        </div>
      </div>
    );
  }
}
function TabContainer(props) {
  return (
    <Typography component="div" className="feedspace">
      {props.children}
    </Typography>
  );
}
const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(AppriciatesList);

