import React, { Component } from "react";
import SendIcon from "@material-ui/icons/Send";
import { MDBInput } from "mdbreact";
import ApiService from "../service/ApiService";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { MDBModal } from "mdbreact";
import LikeModal from "./likeModal";
import { Clapping } from "../images/clapping.svg";
import { connect } from "react-redux";
import CommentModal from "./commentModal";

class Comments extends Component {
  constructor(props) {
    super();
    this.state = {
      comment: "",
      showcomment: [],
      newcomment: [],
      postsPerPage: 2,
      page: 0,

      commentadd: "false",
      commentcount: 0,
      like: false,
      likecount: 0,
      modal: false,
      selectedPost: null,
      commentsendDisable: false,

      likemodal: false,
      reportmodal: false,

      user: "",
      postIssue: "",
      reportsuccess: "",
    };
  }
  componentDidMount() {
    this.fetchComments();
    this.setState({
      commentcount: this.props.postcomment,
      like: this.props.liked,
      likecount: this.props.postlike,
    });
    let user = this.props.LoginData?.loginSuccesData?.userProfileId;
    this.setState({
      user: user,
    });
  }
  fetchComments = () => {
    const { postsPerPage, page } = this.state;

    let postid = this.props.postid;
    let userid;
    if (this.props.LoginData?.loginSuccesData?.userProfileId) {
      userid = this.props.LoginData?.loginSuccesData?.userProfileId;
    } else if (this.props.LoginData.isadmin === 'true') {
      userid = "admin";
    }

    ApiService.ViewComments(userid, postid, page, postsPerPage).then((res) => {
      this.setState({
        showcomment: res.data.dataItemslist,
      });
    });
  };
  toggle = (i) => {
    this.setState({
      modal: true,
      selectedPost: i,
    });
  };
  showLikes = (i) => {
    this.setState({
      likemodal: true,
      selectedPost: i,
    });
  };

  likemodalclose = () => {
    this.setState({
      likemodal: false,
    });
  };
  closeReport = () => {
    this.setState({
      reportmodal: false,
    });
  };

  report = (id) => {
    this.setState({
      reportmodal: true,
      selectedPost: id,
    });
  };
  reportComplaint = (e) => {
    e.preventDefault();
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    let reportdata = {
      postId: this.state.selectedPost,
      reason: this.state.postIssue,
      userProfileId: viewerId,
      sessionId: cookieValue,
    };
    ApiService.ReportComplaint(reportdata).then((res) => {
      if (res.data.status === "success") {
        this.setState({
          reportsuccess: "Post successfully reported",
        });
      }
    });
  };

  commentsmodalclose = (modalreq) => {
    this.setState({
      modal: false,
      commentModalList: [],
    });
  };
  commentdelete = (req) => {
    this.setState(
      (prevState) => ({
        commentcount: prevState.commentcount - 1,
      }),
      this.fetchComments
    );
  };

  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      commentsendDisable: true,
    });
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    let postid = this.props.postid;
    let commentnum = new Number(this.state.commentcount);

    let commentpostdata = {
      userProfileId: viewerId,
      postId: postid,
      sessionId: cookieValue,
      commentText: this.state.comment,
    };
    ApiService.PostComment(commentpostdata).then((res) => {
      if (res.data.status === "success") {
        this.setState(
          {
            commentcount: commentnum + 1,
            comment: "",
            commentsendDisable: false,
          },
          this.fetchComments
        );
        // if (this.state.newcomment.length <= 2) {
        //   this.setState({
        //     newcomment: [res.data.dataItem, ...this.state.newcomment],
        //     comment: "",
        //     commentcount: commentnum + 1,
        //   });
        // }

        // else {
        //   this.setState({
        //     newcomment: [res.data.dataItem],
        //     comment: "",
        //     commentcount: commentnum + 1,
        //   });
        // }

        // this.props.refreshFunction(true)
      } else {
        alert("failed to save comment");
      }
    });
  };

  handleLike = (e) => {
    let n = new Number(this.state.likecount);
    let postid = this.props.postid;
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    if (this.state.like === "false") {
      this.setState({
        likecount: n + 1,
        like: "true",
      });
      ApiService.PostLike(postid, viewerId, cookieValue).then((res) => {});
    } else {
      this.setState({
        likecount: n - 1,
        like: "false",
      });
      ApiService.PostUnLike(postid, viewerId, cookieValue).then((res) => {});
    }
  };

  render() {
    let enabled = false;
    if (
      this.state.comment.length > 1 &&
      this.state.commentsendDisable === false
    ) {
      enabled = true;
    }

    return (
      <>
        <ul className="prfl-buttons">
          <li key="like">
            {this.props.LoginData.isadmin === 'true' || this.props.LoginData.bookerlogin[0] === "success" ? (
              <button>
                {this.state.like === "false" ? (
                  <img src={require("../images/clapping.svg")} />
                ) : (
                  <img src={require("../images/clapping-dark.svg")} />
                )}
              </button>
            ) : (
              <button onClick={this.handleLike.bind(this)}>
                {this.state.like === "false" ? (
                  <img src={require("../images/clapping.svg")} />
                ) : (
                  <img src={require("../images/clapping-dark.svg")} />
                )}
              </button>
            )}

            <button onClick={() => this.showLikes(this.props.postid)}>
              <span className="highlight">{this.state.likecount}</span>
            </button>
          </li>
          <li key="comment">
            <ChatBubbleOutlineOutlinedIcon />{" "}
            <button onClick={() => this.toggle(this.props.postid)}>
              <span className="highlight">{this.state.commentcount}</span>
            </button>
            {/* <CommentCount/> {this.state.commentcount} */}
          </li>

          <li key="report">
            {this.props.LoginData.isadmin === 'true' || this.props.LoginData.bookerlogin[0] === "success" ? (
              <></>
            ) : (
              <button onClick={() => this.report(this.props.postid)}>
                {/* <Complainticon />{" "} */}

                <img src={require("../images/complaint.svg")} alt="" />
              </button>
            )}
          </li>
        </ul>

        <ul className="comments-list">
          {this.state.newcomment &&
            this.state.newcomment.map((newcomment, index) => (
              <li key={index}>
                {newcomment.userProfilePic === null ? (
                  <img src="/user-img.png" alt="" />
                ) : (
                  <img
                    src={newcomment.userProfilePic}
                    className="img-fluid"
                    alt=""
                  />
                )}

                <div>
                  <p className="main-title text-left">
                    <b>{newcomment.userName}</b>
                  </p>
                  <p>{newcomment.commenttext}</p>
                </div>
              </li>
            ))}
        </ul>
        {this.state.newcomment !== [] && this.state.newcomment.length >= 2 ? (
          <></>
        ) : (
          <ul className="comments-list">
            {this.state.showcomment &&
              this.state.showcomment.map((comment, index) => (
                <li key={index.toString()}>
                  {comment.userProfileImg === null ? (
                    <img src="/user-img.png" alt="" />
                  ) : (
                    <img
                      src={comment.userProfileImg}
                      className="img-fluid"
                      alt=""
                    />
                  )}

                  <div>
                    <p className="main-title text-left">
                      <b>{comment.userProfileName}</b>
                    </p>
                    <p>{comment.commentText}</p>
                  </div>
                </li>
              ))}
            {/* {this.state.showcomment.length <= 1 ? (
            <></>
          ) : (
            <button 
            onClick={this.loadmoreComments.bind(this)}
            >
              View more comments
            </button>
          )} */}
          </ul>
        )}

        <div className="send-msg">
          {this.props.LoginData.loginSuccesData.sessionId ? (
            <form onSubmit={this.onSubmit}>
              <MDBInput
                type="textarea"
                rows="1"
                label="Add Comment"
                className="post-comment"
                onChange={this.handleChange("comment")}
                value={this.state.comment}
                style={{ paddingLeft: "15px" }}
              >
                <button
                  className="send-button"
                  id={this.props.postid}
                  onClick={this.onSubmit}
                  disabled={!enabled}
                >
                  <SendIcon />
                </button>
              </MDBInput>
            </form>
          ) : (
            <></>
          )}
        </div>
        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggle}
          size="lg"
          overflowScroll={false}
        >
          <CommentModal
            userpostid={this.state.selectedPost}
            commentsmodalclose={this.commentsmodalclose}
            commentdelete={this.commentdelete}
            commentcount={this.state.commentcount}
            postuserid={this.props.postuserid}
            user={this.state.user}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.likemodal}
          toggle={this.showlikes}
          size="lg"
          id={this.state.selectedPost}
          overflowScroll={false}
          className="like-modal"
        >
          <LikeModal
            userpostid={this.state.selectedPost}
            likemodalclose={this.likemodalclose}
            likescount={this.state.likecount}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.reportmodal}
          toggle={this.report}
          size="lg"
          overflowScroll={false}
        >
          <div className="modal-content modal-feed-view ">
            <div className="modal-feed-head">
              <h4>Report Abuse</h4>
              <button onClick={this.closeReport}>
                <i className="fa fa-times"></i>
              </button>
            </div>
            {this.state.reportsuccess === "" ? (
              <>
                {" "}
                <p className="mb-3">
                  Please mention the issue you have with this post
                </p>
                <form onSubmit={this.reportComplaint}>
                  <MDBInput
                    type="textarea"
                    rows="4"
                    label="Write your grievance"
                    icon="pencil"
                    onChange={this.handleChange("postIssue")}
                    value={this.state.postIssue}
                    name="postIssue"
                  ></MDBInput>
                  <button
                    id="btn-famenet"
                    style={{ margin: "0 auto", display: "block" }}
                    onClick={this.reportComplaint}
                  >
                    Submit
                  </button>
                </form>
              </>
            ) : (
              <>
                <div className="post-detail">
                  <h4
                    className="text-center"
                    style={{ display: "block", margin: "0 auto" }}
                  >
                    {this.state.reportsuccess}
                  </h4>
                  <br />
                  <button onClick={this.closeReport} className="close-btn">
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </MDBModal>
      </>
    );
  }
}


const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(Comments);
