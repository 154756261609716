import React from "react";

function Likeicon() {
  return (
   <svg id="heart_3_" data-name="heart (3)" xmlns="http://www.w3.org/2000/svg" width="21.611" height="20.082" viewBox="0 0 21.611 20.082">
  <g id="Group_19" data-name="Group 19" transform="translate(0 0)">
    <path id="Path_18" data-name="Path 18" d="M19.844,18.784a5.905,5.905,0,0,0-8.655,0l-.382.4-.382-.4a5.909,5.909,0,0,0-8.352-.307q-.159.148-.307.307a6.628,6.628,0,0,0,0,9.012l8.479,8.942a.772.772,0,0,0,1.092.03l.03-.03L19.844,27.8A6.627,6.627,0,0,0,19.844,18.784Zm-1.118,7.948h0l-7.918,8.352L2.888,26.732a5.064,5.064,0,0,1,0-6.886A4.365,4.365,0,0,1,9.057,19.6q.129.119.248.248l.942.993a.8.8,0,0,0,1.121,0l.942-.993a4.365,4.365,0,0,1,6.169-.248q.129.119.248.248A5.018,5.018,0,0,1,18.726,26.732Z" transform="translate(0 -16.896)"/>
  </g>
</svg>
  );
}

function Commenticon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20.001" height="20.001" viewBox="0 0 20.001 20.001">
  <path id="Subtraction_1" data-name="Subtraction 1" d="M-4506.72-5262h-9.777l1.985-3.4A9.991,9.991,0,0,1-4517-5272a9.958,9.958,0,0,1,.785-3.893,10,10,0,0,1,2.144-3.179,9.985,9.985,0,0,1,3.179-2.143A9.951,9.951,0,0,1-4507-5282a9.945,9.945,0,0,1,3.892.786,9.974,9.974,0,0,1,3.179,2.143,9.975,9.975,0,0,1,2.144,3.179A9.942,9.942,0,0,1-4497-5272a9.945,9.945,0,0,1-2.841,6.981,9.957,9.957,0,0,1-3.091,2.156,9.916,9.916,0,0,1-3.8.859l.007,0Zm-.279-18.432a8.381,8.381,0,0,0-3.282.663,8.412,8.412,0,0,0-2.681,1.807,8.412,8.412,0,0,0-1.807,2.68,8.382,8.382,0,0,0-.662,3.282,8.427,8.427,0,0,0,2.931,6.391l-1.257,2.041h6.993l-.007,0a8.386,8.386,0,0,0,5.808-2.542,8.385,8.385,0,0,0,2.4-5.886,8.385,8.385,0,0,0-.663-3.282,8.406,8.406,0,0,0-1.807-2.68,8.4,8.4,0,0,0-2.681-1.807A8.376,8.376,0,0,0-4507-5280.431Z" transform="translate(4517 5282)"/>
</svg>


    );
}

function Shareicon(){
return(
    <svg id="send_1_" data-name="send (1)" xmlns="http://www.w3.org/2000/svg" width="20.082" height="20.082" viewBox="0 0 20.082 20.082">
  <path id="Path_19" data-name="Path 19" d="M12.907,20.083H10.825a1.414,1.414,0,0,1-1.411-1.351l-1.173-6.89-6.89-1.173A1.414,1.414,0,0,1,0,9.257V7.176A3.365,3.365,0,0,1,2.571,3.9L18.342.04l.007,0a1.412,1.412,0,0,1,1.7,1.7l0,.007-3.86,15.771a3.365,3.365,0,0,1-3.277,2.571ZM18.636,1.26,2.87,5.118A2.113,2.113,0,0,0,1.255,7.176V9.257a.157.157,0,0,0,.157.157.627.627,0,0,1,.105.009l7.374,1.255a.628.628,0,0,1,.513.513l1.255,7.374a.627.627,0,0,1,.009.105.157.157,0,0,0,.157.157h2.081a2.113,2.113,0,0,0,2.057-1.615L18.823,1.447a.157.157,0,0,0-.187-.187Z" transform="translate(0 0)"/>
  <path id="Path_20" data-name="Path 20" d="M208.628,119.53a.628.628,0,0,1-.444-1.071l6.276-6.276a.628.628,0,1,1,.887.887l-6.276,6.276a.626.626,0,0,1-.444.184Z" transform="translate(-199.842 -107.606)"/>
</svg>

);
}

export { Likeicon, Commenticon, Shareicon };
