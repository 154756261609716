import React from "react";
import ApiService from "../service/ApiService";
import { MDBCardBody } from "mdbreact";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class BlockingSearch extends React.Component {
  constructor(props) {
    super();
    this.state={
      open:false,
      search: "",
      result: [],
      blockedusers: [],
      disable: false,
    }
  }
  handleClickAway = () =>{
  this.setState({
    open:false
  })
  }
  componentDidMount() {

    this.fetchblockedusers();
  }
  
  
  fetchblockedusers = () => {
    let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let profileid = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.FetchBlockedUsers(profileid, cookieValue).then((res) => {
      this.setState({
        blockedusers: res.data.dataItemslist,
      });
    });
  };
  blockuser = (userid) => {
    this.setState({
      disable:true
    })
    let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let data = {
      blockerUserProfileId: this.props.LoginData.loginSuccesData.userProfileId,
      blockedUserProfileId: userid,
      sessionId: cookieValue,
    };
    ApiService.BlockUser(data).then((res) => {
   
      if (res.data.status === "success") {
        this.setState(
          {
            disable: false,
            open:false
          },
          this.fetchblockedusers
        );
      }
    });
  };
  unblock = (blockedid) => {
    this.setState({
      disable:true
    })
    let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let profileid = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.UnblockUser(blockedid, profileid, cookieValue).then((res) => {
    
      if (res.data.status === "success")
        this.setState(
          {
            disable: false,
          },
          this.fetchblockedusers
        );
    });
  };
  
  
  handleSearch = (input) => (e) => {
    
    e.preventDefault();
   
    this.setState({
      [input]: e.target.value,
    });
   if (this.props.LoginData.loginSuccesData.userProfileId) {
      let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      let profileid = this.props.LoginData.loginSuccesData.userProfileId;
      let search = e.target.value;
      if (search.length > 1) {
        ApiService.Search("block",search, cookieValue, profileid).then((res) => {
          if (res.data.dataItemslist.length > 0) {
            let prev = this.state.open
            this.setState({
              result: res.data.dataItemslist,
              open:!prev
            });
          }
        });
      }
     
    }
  
  
  };
  
  render() {
    return(
      <>
      <MDBCardBody className="edit-card">
      <div className="text-center">
        <h3 className="mb-5">
          <strong>Block Account</strong>
        </h3>
      </div>

  
       
       <ClickAwayListener onClickAway={this.handleClickAway}>
       <div className="search-div-blocking" >
        <i className="fa fa-search"></i>
        <div className="content-blocking">
              <form autoComplete="off"  >
              
                <input
                  type="text"
                  placeholder="Search here"
                onChange={this.handleSearch("search").bind(this)}
                
                  name="search"
                  // value={this.state.search}
                  className=
                   "blocking-search-input"
                  
                ></input>
               
              </form>
            </div>
            {this.state.open ? (
                 <div className="search-result-blocking">
                 <ul style={{ margin: "0" }}>
                 {this.state.result.map((result, i) => (
                  <li key={i}>
                    <Link
                      to={{
                        pathname: `/default-profile/${result.userProfileId}`,
                        state: result.userProfileId,
                      }}
                    >
                      {result.profilePic === null ? (
                        <img src="/user-img.png" alt="user"/>
                      ) : (
                        <img src={result.profilePic} alt=""></img>
                      )}

                      <h6>
                        {result.userName}
                        <br />
                        <span>{result.fullName}</span>
                      </h6>
                    </Link>

                    <button
                      onClick={() => this.blockuser(result.userProfileId)}
                      className="block-link"
                      disabled={this.state.disable}
                    >
                      <h5>Block</h5>
                    </button>
                  </li>
                ))}
                   </ul>
                   </div>
            ):null}
         </div>

        </ClickAwayListener>

        <div className="blocked-users">
          <ul>
            {this.state.blockedusers && this.state.blockedusers.length > 0
              ? this.state.blockedusers.map((blocked, i) => (
                  <li key={blocked.blockId}>
                    {blocked.blockedAccountProfilePic !== null ? (
                      <img src={blocked.blockedAccountProfilePic} alt=""/>
                    ) : (
                      <img src="user-img.png" alt=""></img>
                    )}

                    <h5>
                      {blocked.blockedAccountName}
                     
                    </h5>
                    <button onClick={() => this.unblock(blocked.blockId)} disable={this.state.disable}>
                      <h6>Unblock</h6>
                    </button>
                  </li>
                ))
              : null}
          </ul>
        </div>
        </MDBCardBody>
         </>
      
    )
  }
}


const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(BlockingSearch);