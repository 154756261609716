import React, { Component } from "react";
import { MDBCardBody, MDBInput } from "mdbreact";
import ApiService from "../service/ApiService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SelectFun from "@material-ui/core/Select";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ToggleSwitch from "../reusable/ToggleSwitch";
import { connect } from "react-redux";
const animatedComponents = makeAnimated();

const categories = [
  {
    id: 1,
    categoryName: "MUSIC",
    categoryImage: require("../images/mic.svg"),
    categoryInfo: "Stage Artist, Composers, Instrumental",
    Checked: false,
  },
  {
    id: 2,
    categoryName: "DANCING",
    categoryImage: require("../images/dancing.svg"),
    categoryInfo: "Stage Artist, Composers, Instrumental",
    Checked: false,
  },
  {
    id: 3,
    categoryName: "ACTING",
    categoryImage: require("../images/video.svg"),
    categoryInfo: "Stage Artist, Composers, Instrumental",
    Checked: false,
  },
];

class EditUserDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      fullName: "",
      userName: "",
      bio: "",
      emailId: "",
      skillTags: [],
      passion: [],
      selectOptions: [],
      value: [],
      loading: true,
      charLimit: 30,
      success: false,
      state: "",
      city: "",
      citiesList: [],
      intentCheck: false,
      professionalFlag: "",
      isError: {
        fullName: true,
        userName: true,
        emailId: true,
      },
      errorMessage: {
        fullName: "",
        userName: "",
        emailId: "",
      },
    };

    this.selectRole = this.selectRole.bind(this);
  }
  componentDidMount() {
    this.fetchUserData();

    if (localStorage.getItem("userSelectedPassion")) {
      this.getOptionsCall();
    } else {
      this.props.history.push("/feed");
    }
  }
  handleChangeState = (e) => {
    this.setState({
      state: e.target.value,
    });
    ApiService.GetDistrict(e.target.value).then((res) => {
      this.setState({
        citiesList: res.data,
      });
    });
  };

  fetchUserData = () => {
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const userProfileId = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.getUserData(userProfileId, cookieValue).then((res) => {
    
      if (
        res.data.dataItem.address === null ||
        res.data.dataItem.address === "undefined"
      ) {
        res.data.dataItem.address.state = "";
        res.data.dataItem.address.district = "";
      }
      this.setState({
        fullName: res.data.dataItem.fullName,
        userName: res.data.dataItem.userName,
        bio: res.data.dataItem.bio,
        emailId: res.data.dataItem.emailId,
        passion: res.data.dataItem.passion,
        skillTags: res.data.dataItem.skillTags,
        state: res.data.dataItem.address.state,
        city: res.data.dataItem.address.district,
        intentCheck: res.data.dataItem.availableForBookingEvents,
        professionalFlag: res.data.dataItem.professionalFlag,
        loading: false,
      });

      ApiService.GetDistrict(res.data.dataItem.address.state).then((res) => {
        this.setState({
          citiesList: res.data,
        });
      });

      if (res.data.dataItem.skillTags) {
        res.data.dataItem.skillTags.map((sk) => {
          let data = {
            label: sk.skill,
            value: sk.skillTagId,
          };
          this.state.value.push(data);
        });
      }
      if (
        this.state.passion.includes("MUSIC") &&
        this.state.passion.includes("DANCING") &&
        this.state.passion.includes("ACTING")
      ) {
        document.getElementById("MUSIC").checked = true;

        document.getElementById("DANCING").checked = true;
        document.getElementById("ACTING").checked = true;
        document.getElementById("MUSIC").disabled = true;

        document.getElementById("DANCING").disabled = true;
        document.getElementById("ACTING").disabled = true;
      } else if (
        this.state.passion.includes("MUSIC") &&
        this.state.passion.includes("DANCING")
      ) {
        document.getElementById("MUSIC").checked = true;
        document.getElementById("DANCING").checked = true;
        document.getElementById("MUSIC").disabled = true;
        document.getElementById("DANCING").disabled = true;
      } else if (
        this.state.passion.includes("DANCING") &&
        this.state.passion.includes("ACTING")
      ) {
        document.getElementById("DANCING").checked = true;
        document.getElementById("ACTING").checked = true;
        document.getElementById("ACTING").disabled = true;
        document.getElementById("DANCING").disabled = true;
      } else if (
        this.state.passion.includes("MUSIC") &&
        this.state.passion.includes("ACTING")
      ) {
        document.getElementById("MUSIC").checked = true;
        document.getElementById("ACTING").checked = true;
        document.getElementById("MUSIC").disabled = true;
        document.getElementById("ACTING").disabled = true;
      } else if (this.state.passion.includes("MUSIC")) {
        document.getElementById("MUSIC").checked = true;
        document.getElementById("MUSIC").disabled = true;
      } else if (this.state.passion.includes("ACTING")) {
        document.getElementById("ACTING").checked = true;
        document.getElementById("ACTING").disabled = true;
      } else if (this.state.passion.includes("DANCING")) {
        document.getElementById("DANCING").checked = true;
        document.getElementById("DANCING").disabled = true;
      }
    });
  };

  handleInputChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };
  // selectRole = (category) => {
  //   if(this.state.passion.includes(category.categoryname)){
  //     this.state.passion.pop(category.categoryname);
  //   }
  //   else{
  //     this.state.passion.push(category.categoryname);
  //   }
  //   let passions = [...this.state.passion];
  //   console.log(this.state.passion)

  // };
  selectRole = (e) => {
    if (e.target.checked === true) {
      this.setState(
        {
          passion: [...this.state.passion, e.target.value],
        },
        this.getOptionsCall
      );
    } else {
      let remove = this.state.passion.indexOf(e.target.value);
      this.setState(
        {
          passion: this.state.passion.filter((_, i) => i !== remove),
        },
        this.getOptionsCall
      );
    }
  };

  validateFullName = () => {
    if (this.state.fullName.length < 5) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          fullName: "Type your Name",
        },
        isError: { ...this.state.isError, fullName: false },
      });
    } else {
      this.setState({
        isError: { ...this.state.isError, fullName: true },
      });
    }
  };
  validateUserName = () => {
    if (this.state.userName.length < 5) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          userName: "Type your userName",
        },
        isError: { ...this.state.isError, userName: false },
      });
    } else {
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      let userProfile = this.props.LoginData.loginSuccesData.userProfileId;
      let validateUserName = {
        field: "un",
        value: this.state.userName,
        userProfileId: userProfile,
        sessionId: cookieValue,
      };
      ApiService.validateUserEmail(validateUserName).then((res) => {
        const usernameavail = res.data.status;
        if (usernameavail === "invalid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              userName: "Username is already in use",
            },
            isError: { ...this.state.isError, userName: false },
          });
        } else if (usernameavail === "valid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              userName: "Username is valid",
            },
            isError: { ...this.state.isError, userName: true },
          });
        }
      });
    }
  };
  validateEmailId = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (this.state.emailId.length < 2) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          emailId: "Type your email",
        },
        isError: { ...this.state.isError, emailId: false },
      });
    } else if (!pattern.test(this.state.emailId)) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          emailId: "Email format is incorrect",
        },
        isError: { ...this.state.isError, emailId: false },
      });
    } else {
      const cookieValue =  this.props.LoginData.loginSuccesData.sessionId;
      let userProfile = this.props.LoginData.loginSuccesData.userProfileId;
      let validateEmail = {
        field: "em",
        value: this.state.emailId,
        userProfileId: userProfile,
        sessionId: cookieValue,
      };
      ApiService.validateUserEmail(validateEmail).then((res) => {
        const emailavailability = res.data.status;
        if (emailavailability === "invalid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              emailId: "Email is already in use",
            },
            isError: { ...this.state.isError, emailId: false },
          });
        } else if (emailavailability === "valid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              emailId: "Email is valid",
            },
            isError: { ...this.state.isError, emailId: true },
          });
        }
      });
    }
  };
  handleDistChange = (e) => {
    this.setState({
      city: e.target.value,
    });
  };
  isFormValid = () => {
    const { fullName, emailId, userName } = this.state.isError;

    return fullName && emailId && userName;
  };
  async getOptionsCall() {
    let responseBody = new Array([]);
    if (this.state.passion && this.state.passion < 1) {
      let savedskills = localStorage.getItem("userSelectedPassion");
      let fSkills = JSON.parse(savedskills.toUpperCase());
      ApiService.getSkillTags(fSkills).then((res) => {
        responseBody = res.data;
        const options = responseBody.map((d) => ({
          value: d.skillTagId,
          label: d.skillTagDescription,
        }));

        this.setState({ selectOptions: options });
      });
    } else {
      let savedskills = this.state.passion;
      var fSkills = savedskills;
      ApiService.getSkillTags(fSkills).then((res) => {
        responseBody = res.data;
        const options = responseBody.map((d) => ({
          value: d.skillTagId,
          label: d.skillTagDescription,
        }));

        this.setState({ selectOptions: options });
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.isError.fullName === false ||
      this.state.isError.userName === false ||
      this.state.isError.emailId === false
    ) {
      return;
    } else {
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      const userProfileId = this.props.LoginData.loginSuccesData.userProfileId;
      const skillst = [];
      this.state.value.map((val) => {
        skillst.push(val.label);
      });
      var userEditedData = {
        userProfileId: userProfileId,
        sessionId: cookieValue,
        fullName: this.state.fullName,
        userName: this.state.userName,
        bio: this.state.bio,
        emailId: this.state.emailId,
        skillTags: skillst,
        passion: this.state.passion,
        address: { state: this.state.state, district: this.state.city },
        isReadyForBookings: this.state.intentCheck,
        professionalFlag: this.state.professionalFlag,
      };
      ApiService.saveUserData(userEditedData).then((res) => {
        this.setState({
          success: true,
        });

        var passionarr = JSON.stringify(this.state.passion);

        localStorage.setItem("userSelectedPassion", passionarr);
      });
    }
  };
  handleChange = (_, activeIndex) => this.setState({ activeIndex });
  handleSkillChange(e) {
    this.setState({ value: e });
  }

  render() {
    const isEnabled = this.isFormValid();
    let showdata;

    if (this.state.loading) {
      showdata = (
        <div
          style={{
            display: "block",
            marginTop: "30vh",
            marginLeft: "auto",
            marginRight: "auto",
            height: "100px",
            width: "100px",
          }}
        >
          <i
            className="fa fa-spinner fa-pulse"
            style={{
              fontSize: "60px",

              color: "#b81b21",
            }}
          ></i>
        </div>
      );
    } else if (this.state.success === false) {
      showdata = (
        <>
          {" "}
          <MDBCardBody className="edit-card">
            <div className="text-center">
              <h3 className="mb-5">
                <strong>General Profile Settings</strong>
              </h3>
            </div>
            <form onSubmit={this.handleSubmit} className="edit-form">
              <MDBInput
                label="Full Name"
                icon="user"
                group
                type="text"
                validate
                name="FullName"
                value={this.state.fullName}
                onChange={this.handleInputChange("fullName")}
                onBlur={this.validateFullName}
              >
                <div
                  className={`no-error${
                    this.state.isError.fullName ? " error-signup" : ""
                  }`}
                >
                  {this.state.errorMessage.fullName}
                </div>
              </MDBInput>

              <MDBInput
                label="User Name"
                icon="tag"
                group
                type="text"
                validate
                name="userName"
                value={this.state.userName}
                onChange={this.handleInputChange("userName").bind(this)}
                onBlur={this.validateUserName}
              >
                <div
                  className={`no-error${
                    this.state.isError.userName ? " error-signup" : ""
                  }`}
                >
                  {this.state.errorMessage.userName}
                </div>
              </MDBInput>

              <MDBInput
                label="E-mail"
                icon="envelope"
                type="email"
                value={this.state.emailId}
                onChange={this.handleInputChange("emailId")}
                onBlur={this.validateEmailId}
              >
                <div
                  className={`no-error${
                    this.state.isError.emailId ? " error-signup" : ""
                  }`}
                >
                  {this.state.errorMessage.emailId}
                </div>
              </MDBInput>

              <MDBInput
                type="textarea"
                rows="2"
                label="Bio"
                icon="pencil"
                validate
                name="Bio"
                value={this.state.bio}
                onChange={this.handleInputChange("bio")}
                maxLength="30"
              >
                <div className="error-signup" id="bio-count">
                  Remaining Characters:{" "}
                  {this.state.charLimit - this.state.bio.length}
                </div>
              </MDBInput>

              <label htmlFor="Intent" className="black-text">
                <i
                  data-test="fa"
                  className="fa fa-info prefix intent-prefix"
                ></i>
                <span>Intent of Joining FameNet </span>
              </label>
              <div className="custom-control custom-checkbox intent-check ml-5">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="appearancePublic"
                  checked="checked"
                  disabled
                  name="I only want to show my talent"
                />
                <label
                  className="custom-control-label"
                  htmlFor="appearancePublic"
                >
                  I only want to show my talent.
                </label>
              </div>
              <div className="custom-control custom-checkbox intent-check ml-5">
                <input
                  type="checkbox"
                  className="custom-control-input "
                  id="talentShowcase"
                  name="shows-ok"
                  value="I am ok if contacted for shows & events"
                  checked={this.state.intentCheck}
                  onClick={() =>
                    this.setState({
                      intentCheck: !this.state.intentCheck,
                    })
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="talentShowcase"
                >
                  I am ok if contacted for shows & events
                </label>
              </div>
              <div className="mt-2 mb-2">
                <i
                  data-test="fa"
                  className="fa fa-question-circle prefix question-prefix"
                ></i>
                <span>Are you professional&nbsp;</span>
                <ToggleSwitch
                  professionalFlagValue={this.state.professionalFlag}
                  isOn={this.state.professionalFlag === "Yes" ? true : false}
                  handleToggle={() =>
                    this.state.professionalFlag === "Yes"
                      ? this.setState({ professionalFlag: "No" })
                      : this.setState({ professionalFlag: "Yes" })
                  }
                ></ToggleSwitch>{" "}
              </div>

              <label htmlFor="passion" className="black-text">
                <i
                  data-test="fa"
                  className="fa fa-heart prefix passion-prefix"
                ></i>
                <span>Select your passion</span>
              </label>

              <div className="custom-checkbox intent-check ml-5">
                <ul className="ml-4 edit-passion" style={{ display: "flex" }}>
                  {categories.map((category, i) => (
                    <li key={i}>
                      <input
                        type="checkbox"
                        id={category.categoryName}
                        name="role"
                        onClick={this.selectRole}
                        value={category.categoryName}
                        className="custom-control-input "
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={category.categoryName}
                      >
                        {category.categoryName}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <label htmlFor="skills" className="black-text">
                <i
                  data-test="fa"
                  className="fa fa-star prefix skills-prefix"
                  style={{ fontSize: "24px", marginRight: "15px" }}
                ></i>
                <span>Select your skills</span>
              </label>
              <Select
                className="skilledit"
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={this.state.value}
                isMulti
                onChange={this.handleSkillChange.bind(this)}
                options={this.state.selectOptions}
                styles={{
                  indicatorSeparator: () => {}, // removes the "stick"
                  dropdownIndicator: (defaultStyles) => ({
                    ...defaultStyles,
                  }),
                }}
              />
              <br />
              <div className="state-fields">
                <div className="city-marker" style={{ width: "9%" }}>
                  <i
                    data-test="fa"
                    className="fa fa-map-marker prefix location-prefix"
                  ></i>
                </div>
                <div className="select-city">
                  <FormControl>
                    <InputLabel htmlFor="age-native-simple">State</InputLabel>
                    <SelectFun
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={this.handleChangeState}
                      value={this.state.state}
                    >
                      <MenuItem value="Andhra-Pradesh">Andhra Pradesh</MenuItem>
                      <MenuItem value="Arunachal-Pradesh">
                        Arunachal Pradesh
                      </MenuItem>
                      <MenuItem value="Assam">Assam</MenuItem>
                      <MenuItem value="Bihar">Bihar</MenuItem>
                      <MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
                      <MenuItem value="Goa">Goa</MenuItem>
                      <MenuItem value="Gujarat">Gujarat</MenuItem>
                      <MenuItem value="Himachal-Pradesh">
                        Himachal Pradesh
                      </MenuItem>
                      <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                      <MenuItem value="Karnataka">Karnataka</MenuItem>
                      <MenuItem value="Kerala">Kerala</MenuItem>
                      <MenuItem value="Madhya-Pradesh">Madhya Pradesh</MenuItem>
                      <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                      <MenuItem value="Manipur">Manipur</MenuItem>
                      <MenuItem value="Meghalaya">Meghalaya</MenuItem>
                      <MenuItem value="Mizoram">Mizoram</MenuItem>
                      <MenuItem value="Nagaland">Nagaland</MenuItem>
                      <MenuItem value="Odisha">Odisha</MenuItem>
                      <MenuItem value="Puducherry">Puducherry</MenuItem>
                      <MenuItem value="Punjab">Punjab</MenuItem>
                      <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                      <MenuItem value="Sikkim">Sikkim</MenuItem>
                      <MenuItem value="Tamil-Nadu">Tamil Nadu</MenuItem>
                      <MenuItem value="Telangana">Telangana</MenuItem>
                      <MenuItem value="Uttar-Pradesh ">Uttar Pradesh</MenuItem>
                      <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                      <MenuItem value="West-Bengal">West Bengal</MenuItem>
                      <MenuItem value="Andaman-Nicobar-Islands">Andaman and Nicobar Islands</MenuItem>
                              <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                              <MenuItem value="Dadra-Nagar-Haveli-Daman-Diu">Dadra and Nagar Haveli and Daman and Diu</MenuItem>
                              <MenuItem value="Delhi">Delhi</MenuItem>
                              <MenuItem value="Ladakh">Ladakh</MenuItem>
                              <MenuItem value="Lakshadweep">Lakshadweep</MenuItem>
							                <MenuItem value="Puducherry">Puducherry</MenuItem>
                    </SelectFun>
                  </FormControl>
                </div>
                <div className="select-city">
                  <FormControl>
                    <InputLabel htmlFor="age-native-simple">
                      District
                    </InputLabel>
                    <SelectFun
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={this.handleDistChange}
                      value={this.state.city}
                    >
                      {this.state.citiesList.map((districts, i) => (
                        <MenuItem value={districts} key={i}>
                          {districts}
                        </MenuItem>
                      ))}
                    </SelectFun>
                  </FormControl>
                </div>
              </div>

              <div className="form-over-border"></div>

              <div className="text-center mb-4 md-12">
                <button
                  className="btn-login z-depth-1a btn-block"
                  onClick={this.handleSubmit}
                  disabled={!isEnabled}
                  id="submits"
                >
                  Submit
                </button>
              </div>
            </form>
          </MDBCardBody>
        </>
      );
    } else {
      showdata = (
        <div className="profile-updated">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <h3>Profile successfully Updated</h3>
        </div>
      );
    }

    return <>{showdata}</>;
  }
}


const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(EditUserDetail);