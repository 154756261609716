import React from "react";
import { MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { Link } from "react-router-dom";


const FooterPage = () => {
  return (
    <MDBFooter className="font-small">
      <div className="footer-copyright text-center py-3" >
        <MDBContainer fluid>
        <MDBRow>
       <ul style={{display:'flex', margin:'0 auto'}}>
           <li ><Link to="/privacy-policy">Privacy Policy</Link></li>
           <li ><Link to="/terms-conditions">Terms and Condition</Link></li>
           <li ><Link to="#">Careers</Link></li>
           <li ><Link to="/help">Help</Link></li>
           <li ><Link to="/legal">Legal</Link></li>
           <li ><Link to="/sitemap">Sitemap</Link></li>
           <li ><Link to="/refund-policy">Sales & Refund policy</Link></li>





       </ul>
          
       </MDBRow>
          &copy; {new Date().getFullYear()} Copyright: <a href="/"> Famenet.in </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;