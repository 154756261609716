import React, { Component, useEffect, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  MDBDropdownToggle,
  MDBNavbarNav,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownMenu,
} from "mdbreact";
import SearchBar from "./search/Search";
import HomeIcon from "@material-ui/icons/Home";
import { NavLink, useHistory } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import NotificationBar from "./appreciator/notification.component.jsx";
import PostType from "./postType";
import { MDBBadge } from "mdbreact";
import ApiService from "../components/service/ApiService";
import Logout from "./admin/logout";
import {
  ImportContacts,
  MenuBook,
  MenuBookOutlined,
  MenuBookTwoTone,
} from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const RenderNav = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) => state.LoginData);
  const [isopen, setIsopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [unseennotifications, setUnseennotifications] = useState(0);
  const [opennotif, setOpennotif] = useState(false);
  const [image, setImage] = useState(null);

  const toggleCollapse = () => {
    setIsopen(!isopen);
  };
  const handleImageChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    if (LoginData.loginSuccesData.sessionId) {
      let cookieValue = LoginData.loginSuccesData.sessionId;
      let viewerId = LoginData.loginSuccesData.userProfileId;
      ApiService.ViewNotification(viewerId, cookieValue).then((res) => {
        if (res.data.status === "success" && res.data.dataItemslist !== null) {
          let nlist = res.data.dataItemslist.length;
          setUnseennotifications(nlist);
        }
      });
    }
  }, []);

  const getNotification = (notificationfromchild) => {
    setUnseennotifications(notificationfromchild);
  };

  const handleOpen = () => {
      setOpen(!open)
  };
  const handleClose = () => {
    setOpen(false)
  };
  if (LoginData.isadmin === "true") {
    return (
      <MDBNavbarNav right>
        <MDBNavItem>
          <SearchBar />
        </MDBNavItem>

        <MDBNavItem>
          <NavLink
            activeClassName="active"
            to={{
              pathname: `/admin`,
            }}
          >
            {/* <a  onClick={this.passurl}
            > */}

            <PersonIcon className="iconsize" />
            {/* </a> */}
          </NavLink>
        </MDBNavItem>
        <MDBNavItem>
          <Logout />
        </MDBNavItem>
      </MDBNavbarNav>
    );
  } else if (LoginData?.bookerlogin && LoginData.bookerlogin[0] === "success") {
    return (
      <MDBNavbarNav right>
        <MDBNavItem>
          <SearchBar />
        </MDBNavItem>
        <MDBNavItem>
          <NavLink exact to="/home" activeClassName="active">
            <HomeIcon className="homeicon" />
          </NavLink>
        </MDBNavItem>
        <MDBNavItem>
          <NavLink exact to="/booking-list" activeClassName="active">
            <ImportContacts className="homeicon" />
          </NavLink>
        </MDBNavItem>
        <MDBNavItem>
          <Logout />
        </MDBNavItem>
      </MDBNavbarNav>
    );
  } else if (
    LoginData.loginSuccesData.sessionId &&
    LoginData.loginSuccesData.isadmin !== "true"
  ) {
    return (
      <MDBNavbarNav>
        <MDBNavItem>
          <SearchBar />
        </MDBNavItem>
        <MDBNavItem className="main-nav">
          <button
            type="button"
            style={{
              background: "none",
              border: "none",
              position: "relative",
              margin: "0",
              padding: "0",
            }}
            onClick={handleOpen}
          >
            <AddCircleIcon />
          </button>
          {/* </NavLink> */}
        </MDBNavItem>

        <PostType open={open} close={handleClose} />

        <MDBNavItem>
          <NavLink exact to="/feed" activeClassName="active">
            <HomeIcon className="homeicon" />
          </NavLink>
        </MDBNavItem>
        <MDBNavItem>
          <MDBDropdown className="replace-position">
            <MDBDropdownToggle nav caret className="dropleft px-0">
              <i className="fa fa-bell "></i>
            </MDBDropdownToggle>
            {unseennotifications === 0 ? (
              <></>
            ) : (
              <MDBBadge pill color="black" className="notificationbadge">
                {unseennotifications}
              </MDBBadge>
            )}
            <MDBDropdownMenu className="notification-holder">
              <NotificationBar
                sendNumberToParent={getNotification}
                numberNoti={unseennotifications}
              />
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem>
        <MDBNavItem>
          <NavLink
            activeClassName="active"
            to={{
              pathname: `/default-profile/${LoginData.loginSuccesData.userProfileId}`,
              state: LoginData.loginSuccesData.userProfileId,
            }}
          >
            <PersonIcon className="iconsize" />
            {/* </a> */}
          </NavLink>
        </MDBNavItem>
      </MDBNavbarNav>
    );
  } else {
    return null;
  }
};

export default RenderNav;
