import React, { Component } from "react";
import { connect } from "react-redux";
import ApiService from "../service/ApiService";

class LikeModal extends Component {
  constructor(props) {
    super();
    this.state = {
      likesList: [],
      likesperpage: 5,
      likespagesize: 0,
      totalPages: null,
      scrolling: false,
    };
  }
  componentDidMount() {
    this.showLikesList();
  }
  // handleScroll = (e) =>{
  //     const {scrolling,totalPages,likespagesize} =this.state
  //     if (scrolling) return
  //     if (totalPages <= likespagesize) return

  //     const lastLi = document.querySelector('ul.likeslist > li:last-child')
  //     if (lastLi != null) {
  //     const lastLiOffset = lastLi.offsetTop + lastLi.clientHeight
  //     const pageOffset = window.pageYOffset + window.innerHeight
  //     var bottomOffset = 8
  //     if (pageOffset > lastLiOffset - bottomOffset) this.loadmorelikes()
  //     }
  // }
  showLikesList = () => {
    let userid;
    if (this.props.LoginData.loginSuccesData.userProfileId) {
      userid = this.props.LoginData.loginSuccesData.userProfileId;
    } else if (this.props.LoginData.isadmin === 'true') {
      userid = "admin";
    }

    const { likesperpage, likespagesize, likesList } = this.state;
    ApiService.ViewLikes(
      userid,
      this.props.userpostid,
      likespagesize,
      likesperpage
    ).then((res) => {
      this.setState({
        likesList: [...likesList, ...res.data.dataItemslist],
        // scrolling:false,
        totalPages: this.props.likescount / likesperpage,
      });
    });
  };
  closeLike = () => {
    let Modalopen = false;
    this.props.likemodalclose(Modalopen);
  };
  loadmorelikes = () => {
    this.setState(
      (prevState) => ({
        likespagesize: prevState.likespagesize + 1,
      }),
      this.showLikesList
    );
  };
  render() {
    return (
      <div className="modal-content modal-feed-view ">
        <div className="modal-feed-head">
          <h4>Claps</h4>
          <button onClick={this.closeLike}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <ul className="modal-comments-list likeslist">
          {this.state.likesList.length === 0 ? (
            <p>No claps to show</p>
          ) : (
            <>
              {this.state.likesList.map((like, index) => (
                <li key={index.toString()}>
                  {like.userProfileImg === null ? (
                    <img src="./user-img.png" alt="" />
                  ) : (
                    <img
                      src={like.userProfileImg}
                      className="img-fluid"
                      alt=""
                    />
                  )}

                  <div className="comment-width">
                    <p className="main-title text-left">
                      {like.userProfileName}
                    </p>
                    <p>{like.commentText}</p>
                  </div>
                  <div className="comment-time">
                    <h5>{like.timeStamp}</h5>
                  </div>
                </li>
              ))}

              {this.props.likescount > this.state.likesList.length ? (
                <button
                  className="lo_more"
                  onClick={this.loadmorelikes.bind(this)}
                >
                  Load more
                </button>
              ) : (
                <>No more Claps to show</>
              )}
            </>
          )}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(LikeModal);
