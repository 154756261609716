import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBDropdownToggle } from "mdbreact";
import ApiService from "../service/ApiService";
import { connect } from "react-redux";

class NotificationBar extends Component {
  constructor(props) {
    super();
    this.state = {
      notificationlist: [],
      notificationlength: null,
    };
  }
  componentDidMount() {
    if (
        this.props.LoginData.loginSuccesData.sessionId
    ) {
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
      ApiService.ViewNotification(viewerId, cookieValue).then((res) => {
        if (res.data.status === "success" && res.data.dataItemslist !== null) {
          let nlist = res.data.dataItemslist;
          let numberlist = res.data.dataItemslist.length;
          this.setState({
            notificationlist: nlist,
            notificationlength: numberlist,
          });
        }
      });
    }
  }
  toggleState = (id) => {
    let updatedList = this.state.notificationlist.map((x) => {
      if (x.notification.notificationId === id) {
        if (x.notification.active !== true) {
          let notificationupdate = this.state.notificationlength - 1;
          this.props.sendNumberToParent(notificationupdate);
          this.setState({
            notificationlength: this.state.notificationlength - 1,
          });
          const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
          const userProfile = this.props.LoginData.loginSuccesData.userProfileId;
          ApiService.NotificationClick(
            x.notification.notificationId,
            userProfile,
            cookieValue
          ).then((res) => {});
        }
      }
      if (x.notification.notificationId === id) {
        x.notification.active = true;
      }
      return x;
    });

    this.setState({
      notificationlist: updatedList,
    });
  };
  render() {
    return (
      <div className="notification-view">
        <h3>Notifications</h3>
        <ul id="notification-list">
          {this.state.notificationlength !== null ? (
            this.state.notificationlist.map((x) => {
              let classNames = ["notificationread"];

              if (x.notification.active) {
                classNames.push("active");
              }

              var str = x.notification.notificationMessage;
              var removepost = str.replace("post", "");

              var word = [removepost];
              word.push(
                <Link
                  className="notif-post-link"
                  to={{
                    pathname: `/viewpost`,
                    state: x.notification.postId,
                  }}
                >
                  post
                </Link>
              );
              var linkinsertion = word;

              return (
                <li
                  key={x.notification.notificationId}
                  className={classNames.join(" ")}
                  onClick={() =>
                    this.toggleState(x.notification.notificationId)
                  }
                >
                  {x.notification.notificationFromUserImg === null ? (
                    <img src="/user-img.png" alt="" />
                  ) : (
                    <img
                      src={x.notification.notificationFromUserImg}
                      className="img-fluid"
                      alt=""
                    />
                  )}

                  <div>
                    <p className="main-title text-left">
                      {/* {x.notification.notificationMessage && x.notification.notificationMessage.replace("post",`${<Link>wow</Link>}`)} */}
                      {x.notification.notificationMessage.includes("post")
                        ? linkinsertion
                        : x.notification.notificationMessage}
                    </p>
                    <span>
                      {/* 6 hours ago */}
                      {x.notification.timeStamp}
                    </span>
                  </div>
                </li>
              );
            })
          ) : (
            <p className="mb-0 no-notif-header"> No notifications to show</p>
          )}
        </ul>
        <MDBDropdownToggle className="viewall" id="viewall">
          <Link to={"/notifications"} className="pl-0">
            View All
          </Link>
        </MDBDropdownToggle>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
    return {
      LoginData: state.LoginData,
    };
  };
  
export default connect(mapStateToProps)(NotificationBar);
  