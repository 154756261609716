import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import ApiService from "../service/ApiService";
import "./feeds.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PostType from "../postType";
import Comments from "./comments";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import ReplyAllRoundedIcon from "@material-ui/icons/ReplyAllRounded";
import "./feeds.css";
import Youtube from "../images/youtube.png"
import { connect } from "react-redux";
// import { json } from "express";
// import { json } from "express";

class FeedContent extends Component {
  constructor(props) {
    super();
    this.state = {
      activeIndex: 0,
      circle: [],
      defaultImg: "user-img.png",
      loading: true,
      open: false,
      per: 15,
      initialvalue: 0,
      feedType: "random",
      totalPages: null,
      scrolling: false,
      commentLists: "",
      like: "false",
      addcomment: "false",
      copySuccess: false,
    };
  }

  componentDidMount() {
    this.setState({
      feedType: this.props.feedtype,
      activeIndex: this.props.activeIndex,
    });

    this.fetchCircleFeed();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  handleScroll = (e) => {
    const { scrolling, totalPages, initialvalue } = this.state;
    if (scrolling) return;
    if (totalPages <= initialvalue) return;
    const lastLi = document.querySelector("ul.circle > li:last-child");
    if (lastLi != null) {
      const lastLioffset = lastLi.offsetTop + lastLi.clientHeight;
      const pageOffset = window.pageYOffset + window.innerHeight;
      var bottomOffset = 20;
      if (pageOffset > lastLioffset - bottomOffset) this.LoadMore();
    }
  };
  fetchCircleFeed = () => {
    if (this.props.LoginData.loginSuccesData.userProfileId) {
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
      const { per, initialvalue, circle } = this.state;

      ApiService.viewFeed(
        this.props.feedtype,
        viewerId,
        cookieValue,
        initialvalue,
        per
      )
        .then((json) =>
          this.setState({
            circle: [...circle, ...json.data.dataItemslist],
            loading: false,
            scrolling: false,
            totalPages: (json.data.count/15)+1,
          })
        )

        .catch((err) => {
          Promise.reject(err);
          localStorage.clear("userProfileId");
          window.location = "./";
        });
    } else {
      localStorage.clear("userProfileId");
      window.location = "./";
    }
  };

  handleChange = (_, activeIndex) => this.setState({ activeIndex });
  handleLike = (e) => {
    e.preventDefault();
    this.setState({
      like: "true",
    });
  };

  LoadMore = () => {
    this.setState(
      (prevState) => ({
        initialvalue: prevState.initialvalue + 1,
        scrolling: true,
      }),
      this.fetchCircleFeed
    );
  };

  updateComment = (newComment) => {
    this.setState({
      commentLists: newComment,
      addcomment: "true",
    });
  };
  refreshcomment = (refresh) => {
    this.setState({
      addcomment: refresh,
    });
  };
  handleClickShowAlert = (e) => {
    this.setState({
      copySuccess: e,
    });

    setTimeout(() => {
      this.setState({
        copySuccess: false,
      });
    }, 2000);
  };
  processUserDescriptionText(description){
    const maxChar = 20
    if (description.length > maxChar) {
    description = description.substring(0, maxChar) + " . . .";
}
return description;

  }

  render() {
    const handleOpen = () => {
      this.setState({ open: !this.state.open });
    };
    const handleClose = () => {
      this.setState({ open: false });
    };
    if (this.state.loading === true && this.state.circle.length < 1) {
      return (
        <div
          style={{
            display: "block",
            margin: "30vh auto",
            height: "50vh",
            width: "50px",
          }}
        >
          <i
            className="fa fa-spinner fa-pulse"
            style={{
              fontSize: "60px",

              color: "#b81b21",
            }}
          />
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.circle.length < 1 &&
      this.state.activeIndex === 1
    ) {
      return (
        <div className="no-post">
          <CloudUploadIcon />
          <p>
            You have neither admired anyone nor you have uploaded any post to
            show in your circle
          </p>
          <div className="bordered-button">
            <button onClick={handleOpen}>Upload</button>
          </div>
          <PostType open={this.state.open} close={handleClose} />
        </div>
      );
    } else if (
      this.state.loading === false &&
      this.state.circle.length < 1 &&
      this.state.activeIndex === 0
    ) {
      return (
        <div className="no-post">
          <SentimentVeryDissatisfiedIcon />
          <p>
            Due to technical issues we are currently not able to show
            recommended posts. Meanwhile you can check your circle posts.
          </p>
        </div>
      );
    }
    return (
      <>
        <ul className="circle">
          {this.state.circle.map((circleFeed, index) => (
            <li key={index.toString()}>
              <div className="feed-view">
                <div className="feed-head">
                  <Link
                    to={{
                      pathname: `/default-profile/${circleFeed.userProfileId}`,
                      state: circleFeed.userProfileId,
                    }}
                  >
                    {circleFeed.userProfilePic === null ? (
                      <img
                        src={this.state.defaultImg}
                        className="img-profile-feed"
                        alt="post"
                      />
                    ) : (
                      <img
                        src={circleFeed.userProfilePic}
                        alt="post"
                        className="img-profile-feed"
                      />
                    )}

                    <div className="profile-feed-name">
                      <h4 className="main-title text-left">
                        {circleFeed.userName} 
                      </h4>
                      <h5> {this.processUserDescriptionText(circleFeed.userDescription)}</h5>
                    </div>
                  </Link>
                  <div className="profile-feed-time">
                    <h5>{circleFeed.timeStamp}</h5>

                    <h6
                      className={`${
                        this.state.copySuccess === circleFeed.postId
                          ? "copied-link"
                          : "hide-copied"
                      }`}
                    >
                      Link copied{" "}
                    </h6>

                    <span
                      onClick={(e) =>
                        navigator.clipboard.writeText(
                          `https://famenet.in/famepost/${circleFeed.postId}`
                        ) && this.handleClickShowAlert(circleFeed.postId)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <ReplyAllRoundedIcon
                        style={{ transform: "scaleX(-1)", marginTop: "-10px" }}
                      />
                    </span>
                  </div>
                </div>
                <div className="post-detail">
                  <h4>{circleFeed.title}</h4>
                  <p>{circleFeed.description}</p>
                </div>
                {circleFeed.mediaType === "image" ? (
                  <img
                    src={circleFeed.imageFileLocation}
                    alt="post"
                    className="main-view"
                  />
                ) : (
                  <>
                  <img src={Youtube} className="yt-logo-view" alt="" />
                  <ReactPlayer
                    className="videoFrame main-view"
                    url={circleFeed.youTubeVideoDetails.youTubeVideoUrl}
                    controls={true}
                  />
                  </>
                )}
                {/* <iframe width="100%" height="400px" src="https://youtu.be/6qYpEcXl4QI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                {/* <ul className="prfl-buttons">
                  <li>
                    <Likes
                      postlike={circleFeed.postLikeCount}
                      liked={circleFeed.isLikedByViewer}
                      postid={circleFeed.postId}
                    />
                  </li>
                </ul> */}
                <Comments
                  commentlist={this.state.commentLists}
                  postid={circleFeed.postId}
                  refreshFunction={this.updateComment}
                  postcomment={circleFeed.postCommentCount}
                  postlike={circleFeed.postLikeCount}
                  liked={circleFeed.isLikedByViewer}
                  postuserid={circleFeed.userProfileId}
                />
              </div>
            </li>
          ))}
        </ul>

        {/* <button onClick={this.LoadMore}>Load More</button> */}
      </>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(FeedContent);
