import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import ApiService from "../service/ApiService";
import "./auth.css";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

export default class SignUp extends Component {
  documentData;
  constructor(props) {
    super();
    this.state = {
      fullname: "",
      username: "",
      email: "",
      password: "",
      bio: "",
      CheckedItems: false,
      isDisabled: true,
      charLimit: 30,
      state: "",
      city: "",
      citiesList: [],
      CheckedTerms: false,

      isError: {
        fullname: false,
        username: false,
        email: true,
        password: false,
        state:false,
        city:false,
       
      },
      errorMessage: {
        fullname: "",
        username: "",
        // email: "",
        password: "",
        state:"",
        city:""
      },
    };
    this.toggleShow = this.toggleShow.bind(this);
  }
  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }
  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };
  HandleCheckbox(e) {
    if (e.target.checked) {
      this.setState({
        CheckedItems: true,
      });
    } else {
      this.setState({
        CheckedItems: false,
      });
    }
  }

  handleTerms(e) {
    if (e.target.checked) {
      this.setState({
        CheckedTerms: true,
        
      });
     
    } else {
      this.setState({
        CheckedTerms: false,
      });
    }
  }

  validateFullName = () => {
    if (this.state.fullname.length < 5) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          fullname: "Type your Name",
        },
        isError: { ...this.state.isError, fullname: false },
      });
    } else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          fullname: "Full name is valid",
        },
        isError: { ...this.state.isError, fullname: true },
      });
    }
  };
  validateState = () =>{
    if(this.state.state === ""){
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          state: "Please select state",
        },
        isError: { ...this.state.isError, state: false },
      })
    }
    else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          state: "State selected",
        },
        isError: { ...this.state.isError, state: true },
      });
    }
  }
  validateCity = () =>{
    if(this.state.city === ""){
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          city: "Please select district",
        },
        isError: { ...this.state.isError, city: false },
      })
    }
    else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          city: "District selected",
        },
        isError: { ...this.state.isError, city: true },
      });
    }
  }
  validatePassword = () => {
    var passw = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    );
    if (this.state.password.length < 5) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          password: "Type your Password",
        },
        isError: { ...this.state.isError, password: false },
      });
    } else if (!passw.test(this.state.password)) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          password:
            "Please use atleast one uppercase, one lowercase, one number and one special character",
        },
        isError: { ...this.state.isError, password: false },
      });
    } else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          password: "Password is valid",
        },
        isError: { ...this.state.isError, password: true },
      });
    }
  };
  validateEmail = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (this.state.email.length < 2) {
      this.setState({
        errorMessage: { ...this.state.errorMessage, email: "Type your email" },
        isError: { ...this.state.isError, email: false },
      });
    } else if (!pattern.test(this.state.email)) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          email: "Email format is incorrect",
        },
        isError: { ...this.state.isError, email: false },
      });
    } else {
      ApiService.validateEmail(this.state.email).then((res) => {
        const emailavailability = res.data;
        if (emailavailability === "invalid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              email: "Email is already in use",
            },
            isError: { ...this.state.isError, email: false },
          });
        } else if (emailavailability === "valid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              email: "Email is valid",
            },
            isError: { ...this.state.isError, email: true },
          });
        }
      });
    }
  };

  validateUsername = () => {
    if (this.state.username.length < 2) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          username: "Type your username",
        },
        isError: { ...this.state.isError, username: false },
      });
    } else {
      ApiService.validateUsername(this.state.username).then((res) => {
        const usernameavail = res.data;
        if (usernameavail === "invalid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              username: "Username is already in use",
            },
            isError: { ...this.state.isError, username: false },
          });
        } else if (usernameavail === "valid") {
          this.setState({
            errorMessage: {
              ...this.state.errorMessage,
              username: "username is valid",
            },
            isError: { ...this.state.isError, username: true },
          });
        }
      });
    }
  };
  // isFormValid = () => {

  //   if (
  //     this.state.isError.fullname === false ||
  //     this.state.isError.username === false ||
  //     this.state.isError.email === false ||
  //     this.state.isError.password === false
  //   ) {
  //      return false;
  //   }
  // }
  isFormValid = () => {
    const { fullname, email, username, password, state,city } = this.state.isError;
    const {CheckedTerms} = this.state

    return fullname && email && username && password && CheckedTerms && state && city;
  };
  handleSubmit = (e) => {
    if (
      this.state.isError.fullname === false ||
      this.state.isError.username === false ||
      this.state.isError.email === false || 
      this.state.isError.password === false ||
      this.state.isError.state === false ||
      this.state.isError.city === false
    ) {
      e.preventDefault();
      return;
    } else {
      localStorage.setItem("userform", JSON.stringify(this.state));
      this.props.history.push("/category");
    }
  };
  handleChangeState = (e) => {
    this.setState({
      state: e.target.value,
    });
    ApiService.GetDistrict(e.target.value).then((res) => {
      this.setState({
        citiesList: res.data,
      });
    });
  };
  handleDistChange = (e) => {
    this.setState({
      city: e.target.value,
    });
  };

  componentDidMount() {
    if (localStorage.getItem("userform")) {
      let existingdata = JSON.parse(localStorage.getItem("userform"));

      this.setState({
        fullname: existingdata.fullname,
        username: existingdata.username,
        email: existingdata.email,
        password: existingdata.password,
        bio: existingdata.bio,
        state:existingdata.state,
        city:existingdata.city,
        isError: {
          ...this.state.isError,
          username: true,
          fullname: true,
          email: true,
          password: true,
          state:true,
          city:true
        },
        errorMessage: {
          ...this.state.errorMessage,
          username: "username is valid",
          fullname: "fullname is valid",
          password: "password is valid",
          email: "email is valid",
          state: "state selected",
          city :"city selected"
        },
      });
    }
  }

  render() {
    const isEnabled = this.isFormValid();
    const { email, username, fullname , state, city} = this.state;

    return (
      <div className="container-fluid bg-login">
        <div className="row">
          <div className="col-lg-3 offset-lg-3 col-md-5 offset-md-1 login-image">
            <h1>Discover India's most Talented people</h1>
            <img alt="timer" src={require("../images/signupimg.png")} />
          </div>
          <div className="col-md-6 col-sm-12 login-form">
            <form onSubmit={this.handleSubmit}>
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="8">
                    <MDBCardBody>
                      <div className="text-center">
                        <h2 className="mb-5">
                          <strong>Sign up to FameNet</strong>
                        </h2>
                      </div>
                      <MDBInput
                        label="Full Name"
                        required
                        id="FullName"
                        icon="user"
                        group
                        type="text"
                        name="fullname"
                        value={fullname}
                        onChange={this.handleChange("fullname")}
                        onBlur={this.validateFullName}
                        className="inputtt"
                      ></MDBInput>

                      <div
                        className={`no-error${
                          this.state.isError.fullname ? " error-signup" : ""
                        }`}
                      >
                        {this.state.errorMessage.fullname}
                      </div>

                      <MDBInput
                        label="User Name"
                        icon="tag"
                        id="UserName"
                        group
                        type="text"
                        name="username"
                        value={username}
                        onChange={this.handleChange("username")}
                        onBlur={this.validateUsername}
                      ></MDBInput>
                      <div
                        className={`no-error${
                          this.state.isError.username ? " error-signup" : ""
                        }`}
                      >
                        {this.state.errorMessage.username}
                      </div>

                      {/* <MDBInput
                        label="E-mail"
                        icon="envelope"
                        id="Email"
                        group
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange("email")}
                        onBlur={this.validateEmail}
                      ></MDBInput>
                      <div
                        className={`no-error${
                          this.state.isError.email ? " error-signup" : ""
                        }`}
                      >
                        {this.state.errorMessage.email}
                      </div> */}

                      <MDBInput
                        label="Password"
                        icon="key"
                        group
                        name="password"
                        type={this.state.hidden ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handleChange("password")}
                        onBlur={this.validatePassword}
                      >
                        {this.state.hidden ? (
                          <i
                            toggle="#input-pwd"
                            className="fa fa-eye field-icon toggle-password"
                            icon="pencil"
                            onClick={this.toggleShow}
                          ></i>
                        ) : (
                          <i
                            toggle="#input-pwd"
                            className="fa fa-eye-slash field-icon toggle-password"
                            icon="pencil"
                            onClick={this.toggleShow}
                          ></i>
                        )}
                      </MDBInput>
                      <div
                        className={`no-error${
                          this.state.isError.password ? " error-signup" : ""
                        }`}
                      >
                        {this.state.errorMessage.password}
                      </div>

                      <MDBInput
                        type="textarea"
                        rows="2"
                        label="Bio"
                        icon="pencil"
                        onChange={this.handleChange("bio")}
                        value={this.state.bio}
                        name="Bio"
                        maxLength="30"
                        id="bio"
                      >
                        <div className="error-signup" id="bio-count">
                          Remaining Characters:{" "}
                          {this.state.charLimit - this.state.bio.length}
                        </div>
                      </MDBInput>
                      <div className="state-fields">
                        <div className="city-marker">
                          <i
                            data-test="fa"
                            className="fa fa-map-marker prefix location-prefix"
                          ></i>
                        </div>
                        <div className="select-city">
                          <FormControl>
                            <InputLabel htmlFor="age-native-simple">
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={this.handleChangeState}
                              value={this.state.state}
                              onBlur={this.validateState}

                            >
                              <MenuItem value="Andhra-Pradesh">
                                Andhra Pradesh
                              </MenuItem>
                              <MenuItem value="Arunachal-Pradesh">
                                Arunachal Pradesh
                              </MenuItem>
                              <MenuItem value="Assam">Assam</MenuItem>
                              <MenuItem value="Bihar">Bihar</MenuItem>
                              <MenuItem value="Chhattisgarh">
                                Chhattisgarh
                              </MenuItem>
                              <MenuItem value="Goa">Goa</MenuItem>
                              <MenuItem value="Gujarat">Gujarat</MenuItem>
                              <MenuItem value="Himachal-Pradesh">
                                Himachal Pradesh
                              </MenuItem>
                              <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                              <MenuItem value="Karnataka">Karnataka</MenuItem>
                              <MenuItem value="Kerala">Kerala</MenuItem>
                              <MenuItem value="Madhya-Pradesh">
                                Madhya Pradesh
                              </MenuItem>
                              <MenuItem value="Maharashtra">
                                Maharashtra
                              </MenuItem>
                              <MenuItem value="Manipur">Manipur</MenuItem>
                              <MenuItem value="Meghalaya">Meghalaya</MenuItem>
                              <MenuItem value="Mizoram">Mizoram</MenuItem>
                              <MenuItem value="Nagaland">Nagaland</MenuItem>
                              <MenuItem value="Odisha">Odisha</MenuItem>
                              <MenuItem value="Puducherry">Puducherry</MenuItem>
                              <MenuItem value="Punjab">Punjab</MenuItem>
                              <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                              <MenuItem value="Sikkim">Sikkim</MenuItem>
                              <MenuItem value="Tamil-Nadu">Tamil Nadu</MenuItem>
                              <MenuItem value="Telangana">Telangana</MenuItem>
                              <MenuItem value="Uttar-Pradesh ">Uttar Pradesh</MenuItem>
                              <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                              <MenuItem value="West-Bengal">West Bengal</MenuItem>
                              <MenuItem value="Andaman-Nicobar-Islands">Andaman and Nicobar Islands</MenuItem>
                              <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                              <MenuItem value="Dadra-Nagar-Haveli-Daman-Diu">Dadra and Nagar Haveli and Daman and Diu</MenuItem>
                              <MenuItem value="Delhi">Delhi</MenuItem>
                              <MenuItem value="Ladakh">Ladakh</MenuItem>
                              <MenuItem value="Lakshadweep">Lakshadweep</MenuItem>
							                <MenuItem value="Puducherry">Puducherry</MenuItem>

                              
                            </Select>
                            <div
                        className={`no-error ml-0 mt-1${
                          this.state.isError.state ? " error-signup" : ""
                        }`}
                      >
                        {this.state.errorMessage.state}
                      </div>
                          </FormControl>
                         
                        </div>
                        
                        <div className="select-city">
                          <FormControl>
                            <InputLabel htmlFor="age-native-simple">
                              District
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={this.handleDistChange}
                              value={this.state.city}
                              onBlur={this.validateCity}
                            >
                              {this.state.citiesList.map((districts, i) => (
                                <MenuItem value={districts} key={i}>
                                  {districts}
                                </MenuItem>
                              ))}
                            </Select>
                            <div
                        className={`no-error ml-0 mt-1${
                          this.state.isError.city ? " error-signup" : ""
                        }`}
                      >
                        {this.state.errorMessage.city}
                      </div>
                          </FormControl>
                        </div>
                      </div>

                      <label htmlFor="Intent" className="black-text">
                        <i
                          data-test="fa"
                          className="fa fa-info prefix intent-prefix"
                        ></i>
                        <span>Intent of Joining FameNet </span>
                      </label>
                      <div className="custom-control custom-checkbox intent-check ml-5">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="appearancePublic"
                          checked
                          disabled={this.state.isDisabled}
                          name="I only want to show my talent"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="appearancePublic"
                        >
                          I only want to show my talent.
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox intent-check ml-5">
                        <input
                          type="checkbox"
                          className="custom-control-input "
                          id="talentShowcase"
                          onClick={this.HandleCheckbox.bind(this)}
                          name="shows-ok"
                          value="I am ok if contacted for shows & events"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="talentShowcase"
                        >
                          I am ok if contacted for shows & events
                        </label>
                      </div>

                      <div className="custom-control custom-checkbox ml-2 mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input "
                          id="termscheck"
                          onClick={this.handleTerms.bind(this)}
                          name="terms-ok"
                          value="I accept"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="termscheck"
                        >
                         I accept the <Link to="/terms-conditions" style={{color:"#b81b21"}}>Terms and conditions</Link>
                        </label>
                      </div>
                    
                      
                      <div className="form-over-border"></div>

                      <div className="text-center mb-4 md-12">
                        <button
                          className="btn-login z-depth-1a btn-block"
                          onClick={this.handleSubmit}
                          disabled={!isEnabled}
                          id="submits"
                        >
                          Next
                        </button>
                      </div>

                      {/* <p className="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2">
                        or Sign up with:
                      </p>
                      <div className="row my-3 d-flex justify-content-center">
                        <MDBBtn social="gplus" color="danger">
                          Google
                        </MDBBtn>
                      </div> */}
                    </MDBCardBody>
                    <MDBModalFooter className="pt-3 mb-1 ">
                      <p className="font-small black-text d-flex login-footer">
                        Already a member?
                        <Link className="lm-1 pur-text" to={"/"}>
                          <strong>&nbsp; Sign in</strong>
                        </Link>
                      </p>
                    </MDBModalFooter>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
