import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

class Logout extends Component {
  state = {
    navigate: false,
  };
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
};

  logout = () => {
    
    this.setState({ navigate: true });

    localStorage.clear();
    sessionStorage.clear();
    const { cookies } = this.props;
    cookies.remove('isBooker');
    window.location = "/";
  };
  render() {
    return (
      <Button onClick={this.logout} style={{ padding: "0", minWidth: "0" }}>
        <i
          className="fa fa-sign-out"
          aria-hidden="true"
          style={{ color: "#fff", fontSize: "22px" }}
        ></i>
      </Button>
    );
  }
}
export default withCookies(Logout)