import React, { Component } from "react";
import ReactDom from "react-dom";
import AvatarEditor from "react-avatar-editor";
import { Slider } from "@material-ui/core";
import ApiService from "../service/ApiService";
import { MDBPopover, MDBPopoverBody, MDBBtn } from "mdbreact";
import "./profile.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
class CoverImage extends Component {
  constructor(props) {
    super();
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleFileChange = this._handleFileChange.bind(this);
    this._setEditorRef = this._setEditorRef.bind(this);
    this._handleZoomSlider = this._handleZoomSlider.bind(this);

    this.state = {
      cropperOpen: false,
      img: null,
      zoom: 1,
      croppedImg: null,
      following: "",
      vieweeid: null,
      deleted: "true",
      location:null,
    };
  }
  componentDidMount() {


  }

  _handleFileChange(e) {
    window.URL = window.URL || window.webkitURL;
    let url = window.URL.createObjectURL(e.target.files[0]);
    ReactDom.findDOMNode(this.refs.in).value = "";
    let state = this.state;
    state.img = url;
    state.cropperOpen = true;
    this.setState(state);
  }
  _handleSave(e) {
    if (this.editor) {
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      let state = this.state;
      state.img = null;
      state.cropperOpen = false;
      state.croppedImg = croppedImg;
      this.setState(state);

      var fileDetails = this.state.croppedImg;
      var indexOfBase64 = fileDetails.indexOf("base64");
      var fileByteArray = fileDetails.substr(indexOfBase64 + 7);
      var indexOfExtension = fileDetails.indexOf("/");
      var indexOfDeleimiter = fileDetails.indexOf(";");
      var fileExtension = fileDetails.substring(
        indexOfExtension + 1,
        indexOfDeleimiter
      );
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId
      var userbody = {
        userProfileId: this.props.LoginData.loginSuccesData.userProfileId,
        imageByteArrayString: fileByteArray,
        sessionId: cookieValue,
        extension: "." + fileExtension,
      };

      ApiService.uploadCoverPic(userbody)
        .then((res) => {
          this.setState({
            // croppedImg: res.data.dataItem.filePath,
            loading: false,
            deleted: "false",
          });
          var coverimage;
          coverimage = res.data.dataItem.filePath;
          this.props.sendCover(coverimage);
        })

        .catch((err) => {
          Promise.reject(err);
          // sessionStorage.clear("userProfileId");
          // window.location = "./";
        });
    }
  }
  _handleCancel() {
    let state = this.state;
    state.cropperOpen = false;
    this.setState(state);
  }
  _setEditorRef(editor) {
    this.editor = editor;
  }
  _handleZoomSlider(event, value) {
    let state = this.state;
    state.zoom = value;
    this.setState(state);
  }
  handleFollow = (e) => {
    e.preventDefault();
    this.setState({
      following: "true",
    });
    var follow;
    follow = "true";
    this.props.sendFollowToParent(follow);
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId
    let followdata = {
      followerUserProfileId: this.props.LoginData.loginSuccesData.userProfileId,
      followingUserProfileId: this.props.vieweeid,
      sessionId: cookieValue,
    };
    ApiService.FollowUser(followdata).then((res) => {});
  };
  handleUnfollow = (e) => {
    e.preventDefault();
    this.setState({
      following: "false",
    });
    var follow;
    follow = "false";
    this.props.sendUnfollow(follow);
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId
    let followdata = {
      followerUserProfileId: this.props.LoginData.loginSuccesData.userProfileId,
      followingUserProfileId: this.props.vieweeid,
      sessionId: cookieValue,
    };
    ApiService.UnFollowUser(followdata).then((res) => {});
  };
  removeCover = (e) => {
    e.preventDefault();
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId
    let userProfileId = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.DeleteProfile("cover", userProfileId, cookieValue).then(
      (res) => {
        if (res.data.status === "success") {
          this.setState({
            croppedImg: "",
            deleted: "true",
          });
          var coverimage;
          coverimage = null;
          this.props.sendCover(coverimage);
        }
      }
    );
  };

  render() {
    let AdmireButton;
    if (
      this.props.isFollowingTheUser === "true" &&
      this.props.isSelfProfile === "false"
    ) {
      AdmireButton = (
        <button
          className="btn-following"
          onClick={this.handleUnfollow.bind(this)}
          follownumber={this.props.isFollowingTheUser}
        >
          {" "}
          Admiring <i className="fa fa-check"></i>
        </button>
      );
    } else if (
      this.props.isFollowingTheUser === "false" &&
      this.props.isSelfProfile === "false" &&
      this.props.LoginData.loginSuccesData.userProfileId
    ) {
      AdmireButton = (
        <button
          className="btn-follow"
          onClick={this.handleFollow.bind(this)}
          follownumber={this.props.isFollowingTheUser}
        >
          Admire
        </button>
      );
    } else if (this.props.LoginData.isadmin === "true") {
      AdmireButton = (
        <>
          <button
            className="btn-follow"
            // onClick={this.handleFollow.bind(this)}
          >
            Deactivate
          </button>
        </>
      );
    } else if ( 
        this.props.LoginData?.bookerlogin[0] === "success"
    ) {
      AdmireButton = (
        <>
          <button className="btn-follow">
            <Link to={`/book-artist/${this.props.vieweeid}`} style={{ color: "#fff" }}>
              Book
            </Link>
          </button>
        </>
      );
    } else {
      AdmireButton = <></>;
    }

    if (
      // this.state.croppedImg === null &&
      this.props.coverimg === null ||
      (this.props.coverimg === "" && this.state.deleted === "true")
    ) {
      return (
        <div className="cover-profile">
          <div
            className={
              this.props.isSelfProfile === "true"
                ? "default-cover"
                : "default-cover-another"
            }
          >
            <h3>Cover Image</h3>
            {this.props.isSelfProfile === "true" ? (
              <p>You have not uploaded a cover image</p>
            ) : (
              <></>
            )}

            {this.props.isSelfProfile === "true" ? (
              <button type="file" className="cover-upload">
                <i className="fa fa-camera"></i> Upload
                <input
                  type="file"
                  ref="in"
                  accept="image/*"
                  onChange={this._handleFileChange}
                />
                {this.state.file === undefined ? (
                  <></>
                ) : (
                  <img src={this.state.file} alt="" />
                )}
              </button>
            ) : (
              <></>
            )}
            {this.state.cropperOpen && (
              <div className="cover-cropper">
                <div className="disable-bg"> </div>
                <AvatarEditor
                  ref={this._setEditorRef}
                  image={this.state.img}
                  scale={this.state.zoom}
                  rotate={0}
                  height={250}
                  width={700}
                />

                <div className="cover-save">
                  <label
                    style={{
                      fontSize: 14,
                      marginRight: 10,
                      paddingBottom: 22,
                      fontWeight: 600,
                      padding: "5px",
                      color: "#000",
                    }}
                  >
                    Zoom
                  </label>
                  <Slider
                    min={1}
                    max={10}
                    step={0.1}
                    value={this.state.zoom}
                    onChange={this._handleZoomSlider}
                    style={{ width: 200, color: "#000", marginRight: "10px" }}
                  />

                  <button
                    label="CANCEL"
                    onClick={this._handleCancel}
                    className="profile-change-btn"
                  >
                    Cancel
                  </button>
                  <button
                    label="SAVE"
                    onClick={this._handleSave}
                    className="profile-change-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
          {AdmireButton}
        </div>
      );
    }

    return (
      <>
        <div className="cover-profile">
          {/* {
          this.state.croppedImg !== null && 
          this.state.croppedImg !== "" ? (
            <img
              className="img-fluid"
              alt="cover"
              src={this.state.croppedImg}
            />
          ) : ( */}
          <img className="img-fluid" alt="cover" src={this.props.coverimg} />
          {/* )} */}

          {this.props.isSelfProfile === "true" ? (
            <MDBPopover
              placement="left"
              popover
              clickable
              id="popper4"
              className="cover-pop"
            >
              <MDBBtn className="pop-btn">
                <i className="fa fa-camera" aria-hidden="true"></i>
              </MDBBtn>
              <div>
                <MDBPopoverBody>
                  <button type="file" className="cover-edit cover-btn">
                    <i className="fa fa-image"></i> <span>Update</span>
                    <input
                      type="file"
                      ref="in"
                      accept="image/*"
                      onChange={this._handleFileChange}
                    />
                    {this.state.file === undefined ? (
                      <></>
                    ) : (
                      <img src={this.state.file} alt="" />
                    )}
                  </button>
                  <button
                    onClick={this.removeCover.bind(this)}
                    className="cover-btn"
                  >
                    {" "}
                    <i className="fa fa-trash"></i> Remove
                  </button>
                </MDBPopoverBody>
              </div>
            </MDBPopover>
          ) : (
            <></>
          )}
          {this.state.cropperOpen && (
            <div className="cover-cropper">
              <div className="disable-bg"> </div>
              <AvatarEditor
                ref={this._setEditorRef}
                image={this.state.img}
                scale={this.state.zoom}
                rotate={0}
                height={250}
                width={700}
              />

              <div className="cover-save">
                <div className="cover-editors">
                  <label
                    style={{
                      fontSize: 14,

                      fontWeight: 600,

                      color: "#000",
                    }}
                  >
                    Zoom
                  </label>
                  <Slider
                    min={1}
                    max={10}
                    step={0.1}
                    value={this.state.zoom}
                    onChange={this._handleZoomSlider}
                    style={{ width: 200, color: "#000" }}
                    label="zoom"
                  />

                  <button
                    label="CANCEL"
                    onClick={this._handleCancel}
                    className="profile-change-btn"
                  >
                    Cancel
                  </button>
                  <button
                    label="SAVE"
                    onClick={this._handleSave}
                    className="profile-change-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}

          {AdmireButton}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(CoverImage);