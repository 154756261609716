import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import CheckAuthentication from "../../checkAuthentication";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const LoginData = useSelector((state) => state.LoginData);

  useEffect(()=>{
      if(LoginData.loginSuccesData.userProfileId){
    document.cookie = `sessionId=${LoginData.loginSuccesData.sessionId}`;

    localStorage.setItem(
      "userProfileId",
      LoginData.loginSuccesData.userProfileId
    );
    localStorage.setItem(
      "userSelectedPassion",
      JSON.stringify(LoginData.loginSuccesData.passionList)
    );
      }
      else if(LoginData.isadmin === "true"){
        localStorage.setItem("isAdmin", true);
      }
      else if (LoginData.bookerlogin[0] === "success"){
        document.cookie = `isBooker=${LoginData.bookerlogin[1]}`;
      }
  },[])
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
       
        LoginData.loginSuccesData.userProfileId || LoginData.isadmin === "true" || LoginData.bookerlogin[0] === "success"
         ?  <Component {...props} /> : <Redirect to="/" />
         
      
    }
    />
  );
};

export default PrivateRoute;
