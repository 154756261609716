import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import './posts.css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000000",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    height: "80px",
    width: "80px",
  },
}))(Tooltip);

export default class SelectToPost extends Component {
  constructor(props) {
    super();
    this.state = {
      roles: [],
      selectedRole: [],
    };
    this.selectRole = this.selectRole.bind(this);
  }
  componentDidMount() {
    let getPassion = JSON.parse(localStorage.getItem("userSelectedPassion"));

    this.setState({
      selectedRole: getPassion,
    });

    if (getPassion.length === 1) {
      this.setState({
        checked: "true",
        disabled: "true",
        roles: getPassion,
      });
    }
  }
  disableothers = () => {
    if (this.state.selectedRole.length > 2) {
      if (this.state.roles.indexOf("ACTING") >= 0) {
        document.getElementById("DANCING").disabled = true;
        document.getElementById("MUSIC").disabled = true;
      } else if (this.state.roles.indexOf("DANCING") >= 0) {
        document.getElementById("ACTING").disabled = true;
        document.getElementById("MUSIC").disabled = true;
      } else if (this.state.roles.indexOf("MUSIC") >= 0) {
        document.getElementById("ACTING").disabled = true;
        document.getElementById("DANCING").disabled = true;
      }
     
    }
    if (
      this.state.selectedRole.length > 1 &&
      this.state.selectedRole.includes("MUSIC") &&
      this.state.selectedRole.includes("DANCING")
    ) {
      if (this.state.roles.indexOf("DANCING") >= 0) {
        document.getElementById("MUSIC").disabled = true;
      } else if (this.state.roles.indexOf("MUSIC") >= 0) {
        document.getElementById("DANCING").disabled = true;
      }
     
    }
    if (
      this.state.selectedRole.length > 1 &&
      this.state.selectedRole.includes("ACTING") &&
      this.state.selectedRole.includes("DANCING")
    ) {
      if (this.state.roles.indexOf("DANCING") >= 0) {
        document.getElementById("ACTING").disabled = true;
      } else if (this.state.roles.indexOf("ACTING") >= 0) {
        document.getElementById("DANCING").disabled = true;
      }
     
    }
    if (
      this.state.selectedRole.length > 1 &&
      this.state.selectedRole.includes("ACTING") &&
      this.state.selectedRole.includes("MUSIC")
    ) {
      if (this.state.roles.indexOf("MUSIC") >= 0) {
        document.getElementById("ACTING").disabled = true;
      } else if (this.state.roles.indexOf("ACTING") >= 0) {
        document.getElementById("MUSIC").disabled = true;
      }
    
    }
  };
  enableothers = () => {
    if (this.state.selectedRole.length > 2) {
      if (this.state.roles.length <= 1) {
        document.getElementById("ACTING").disabled = false;
        document.getElementById("MUSIC").disabled = false;
        document.getElementById("DANCING").disabled = false;
      }
    }

    if (
      this.state.selectedRole.length > 1 &&
      this.state.selectedRole.includes("MUSIC") &&
      this.state.selectedRole.includes("DANCING")
    ) {
      if (this.state.roles.length <= 1) {
        document.getElementById("MUSIC").disabled = false;
        document.getElementById("DANCING").disabled = false;
      }
    }
    if (
      this.state.selectedRole.length > 1 &&
      this.state.selectedRole.includes("ACTING") &&
      this.state.selectedRole.includes("DANCING")
    ) {
      if (this.state.roles.length <= 1) {
        document.getElementById("ACTING").disabled = false;
        document.getElementById("DANCING").disabled = false;
      }
    }
    if (
      this.state.selectedRole.length > 1 &&
      this.state.selectedRole.includes("ACTING") &&
      this.state.selectedRole.includes("MUSIC")
    ) {
      if (this.state.roles.length <= 1) {
        document.getElementById("ACTING").disabled = false;
        document.getElementById("MUSIC").disabled = false;
      }
    }
  };

  selectRole(e) {
    if (e.target.checked === true) {
      this.setState(
        {
          roles: [...this.state.roles, e.target.value],
        },
        () => {
          this.disableothers();
        }
      );
    } else {
      let remove = this.state.roles.indexOf(e.target.value);
      this.setState(
        {
          roles: this.state.roles.filter((_, i) => i !== remove),
        },
        () => {
          this.enableothers();
        }
      );
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    if (this.state.roles.length === 1) {
      let passionforpost = this.state.roles;
      sessionStorage.setItem("passion", JSON.stringify(passionforpost));
      this.props.history.push("/upload-video");
    } else {
      alert("please select a passion");
    }
  }
  checkCategory = (category) => {
    if (category === "MUSIC") {
      return require("../images/mic.svg");
    } else if (category === "ACTING") {
      return require("../images/video.svg");
    } else if (category === "DANCING") {
      return require("../images/dancing.svg");
    }
  };
  checkInfo = (category) =>{
    if (category === "MUSIC") {
      return "Singing, pianist, guitarist"
    }
    else if (category === "ACTING") {
      return "Mimicry, theator artist"
    }
    else if (category === "DANCING") {
      return "Kathak, bharatnatyam"
    }
  }
  render() {
    if (this.state.selectedRole.length === 1) {

    }

    return (
      <div className="container-fluid category-bg">
        <h2>Choose your post category</h2>
        <MDBContainer>
          <MDBRow center>
            <MDBCol className="col-lg-12 col-md-10">
              <div className="category-cont third-cont">
                <ul>
                  {this.state.selectedRole.map((category, i) => (
                    <li key={i} className={this.state.selectedRole.length ===  1 ? "one-passion" : ""}>
                      <input
                        type="checkbox"
                        id={category}
                        name="role"
                        onClick={this.selectRole}
                        value={category}
                        className="check"
                        checked={this.state.checked}
                        disabled={this.state.disabled}
                      />

                      <label htmlFor={category} className={i}>
                        <div className="info-tool">
                          <LightTooltip
                            disableFocusListener
                            title={this.checkInfo(category)}
                          >
                            <button>
                              <i className="fa fa-info"></i>
                            </button>
                          </LightTooltip>
                        </div>
                        <img
                          alt={category}
                          src={this.checkCategory(category)}
                        />

                        <h4>{category}</h4>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </MDBCol>
            <MDBCol md="8" lg="5">
              <button
                type="submit"
                rounded="rounded"
                className="btn-login passion-next"
                onClick={this.handleSubmit.bind(this)}
              >
                Next
              </button>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}
