import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import "./booker.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ApiService from "../service/ApiService";
import { Link } from "react-router-dom";
import artistSearch from "../images/searchicon.png";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import SelectFun from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

const handleDragStart = (e) => e.preventDefault();
const animatedComponents = makeAnimated();
export default class BookerHome extends Component {
  constructor(props) {
    super();

    this.state = {
      citiesList: [],
      skill: "",
      state: "",
      city: "",

      loading: true,
      searchResult: [],
      per: 10,
      page: 0,
      total_pages: null,
      scrolling: false,
      skillsResult: [],
      selectedValue: null,
      inputValue: "",
      skillArr: [],
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
    if (sessionStorage.getItem("ArtistSearch")) {
      let existingdata = JSON.parse(sessionStorage.getItem("ArtistSearch"));

      this.setState({
        citiesList: existingdata.citiesList,
        skill: existingdata.skill,
        state: existingdata.state,
        city: existingdata.city,
        page: existingdata.page,
        per: 10,
        skillsResult: existingdata.skillsResult,
        selectedValue: existingdata.selectedValue,
        inputValue: existingdata.inputValue,
        skillArr: existingdata.skillArr,
        searchResult: existingdata.searchResult,
      });
    }
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  handleChangeState = (e) => {
    this.setState({
      state: e.target.value,
      searchResult: [],
      page: 0,
      city: "none",
    });
    ApiService.GetDistrict(e.target.value).then((res) => {
      this.setState({
        citiesList: res.data,
        searchResult: [],
      });
    });
  };
  handleDistChange = (e) => {
    this.setState({
      city: e.target.value,
      searchResult: [],
      page: 0,
    });
  };

  handleSearch = () => {
    // e.preventDefault();

    const { per, page, searchResult } = this.state;

    let body = {
      skill: this.state.skillsResult,
      state: this.state.state,
      district: this.state.city,
      pageNum: page,
      pageSize: per,
    };

    ApiService.SearchArtist(body).then((res) => {
      this.setState({
        searchResult: [...searchResult, ...res.data],
        selectedValue: null,
        scrolling: false,
        total_pages: res?.data[0]?.totalResultCount / per,
        loading: false,
      });
      sessionStorage.setItem("ArtistSearch", JSON.stringify(this.state));
    });
  };

  handleSearchAgain = () => {
    // e.preventDefault();

    const { per, page, searchResult } = this.state;

    let body = {
      skill: this.state.skillsResult,
      state: this.state.state,
      district: this.state.city,
      pageNum: page,
      pageSize: per,
    };

    ApiService.SearchArtist(body).then((res) => {
      this.setState({
        searchResult: res.data,
        selectedValue: null,
        scrolling: false,
        total_pages: res?.data[0]?.totalResultCount / per,
        loading: false,
      });
      sessionStorage.setItem("ArtistSearch", JSON.stringify(this.state));
    });
  };

  handleScroll = (e) => {
    const { scrolling, total_pages, page } = this.state;
    if (scrolling) return;
    if (total_pages <= page) return;

    const lastLi = document.querySelector("ul.all-talent > li:last-child");

    if (lastLi != null && this.state.total_pages > 1) {
      const lastLioffset = lastLi.offsetTop + lastLi.clientHeight;
      const pageOffset = window.pageYOffset + window.innerHeight;
      var bottomOffset = 20;
      if (pageOffset > lastLioffset - bottomOffset) this.LoadMore();
    }
  };

  LoadMore = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
        scrolling: true,
      }),
      this.handleSearch
    );
  };
  removeSkillTag = (id) => {
    this.state.skillsResult.splice(
      this.state.skillsResult.findIndex(function (i) {
        return i === id;
      }),
      1
    );
    this.setState(
      {
        page: 0,
        searchResult: [],
      },
      this.handleSearch
    );
  };

  handleInputChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };
  handleValueChange = (value) => {
    this.setState(
      {
        selectedValue: value,
        page: 0,
        searchResult: [],
      },
      this.handleSearch
    );

    this.state.skillsResult.push(value.skillTagDescription);
  };

  // componentDidUpdate() {

  //       if (this.state.skillsResult>0) {
  //        this.setState({
  //            searchResult: []
  //        },this.handleSearch)
  //       }

  //   }
  getUnique(prod, comp) {
    // store the comparison  values in array
    const unique = prod
      .map((e) => e[comp])

      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the false indexes & return unique objects
      .filter((e) => prod[e])
      .map((e) => prod[e]);

    return unique;
  }

  loadOptions = () => {
    return ApiService.SearchArtistTags(this.state.inputValue).then((res) => {
      return res.data;
    });
  };
  render() {
    const { activeIndex } = this.state;
    let enabled = false;
    if (
      this.state.skillsResult &&
      this.state.skillsResult.length > 0 &&
      this.state.state
    ) {
      enabled = true;
    }
    const { width } = this.state;
    const isMobile = width <= 770;

    return (
      <div className="profile-bg">
        <MDBContainer className="search-art-cont">
          {/* Search Artist */}

          <div className="search-artist">
            <h4>I am Looking for...</h4>
            <div className="row mr-0 ml-0">
              <div className="col-md-4">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={this.state.selectedValue}
                  closeMenuOnSelect={false}
                  getOptionLabel={(e) => e.skillTagDescription}
                  getOptionValue={(e) => e.skillTagId}
                  loadOptions={this.loadOptions}
                  onInputChange={this.handleInputChange}
                  onChange={this.handleValueChange}
                  placeholder="Type something here"
                  styles={{
                    indicatorSeparator: () => {}, // removes the "stick"
                    dropdownIndicator: (defaultStyles) => ({
                      ...defaultStyles,
                      display: "none", // your changes to the arrow
                      fontSize: "12px",
                    }),
                  }}
                  className="mt-3 select-search-skill"
                />
                <pre></pre>
              </div>
              <div className="col-md-3 pl-0">
                <FormControl className="select-dis-search">
                  <InputLabel htmlFor="age-native-simple">State</InputLabel>
                  <SelectFun
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={this.handleChangeState}
                    value={this.state.state}
                  >
                    <MenuItem value="none" selected>
                      None
                    </MenuItem>
                    <MenuItem value="Andhra-Pradesh">Andhra Pradesh</MenuItem>
                    <MenuItem value="Arunachal-Pradesh">
                      Arunachal Pradesh
                    </MenuItem>
                    <MenuItem value="Assam">Assam</MenuItem>
                    <MenuItem value="Bihar">Bihar</MenuItem>
                    <MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
                    <MenuItem value="Goa">Goa</MenuItem>
                    <MenuItem value="Gujarat">Gujarat</MenuItem>
                    <MenuItem value="Himachal-Pradesh">
                      Himachal Pradesh
                    </MenuItem>
                    <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                    <MenuItem value="Karnataka">Karnataka</MenuItem>
                    <MenuItem value="Kerala">Kerala</MenuItem>
                    <MenuItem value="Madhya-Pradesh">Madhya Pradesh</MenuItem>
                    <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                    <MenuItem value="Manipur">Manipur</MenuItem>
                    <MenuItem value="Meghalaya">Meghalaya</MenuItem>
                    <MenuItem value="Mizoram">Mizoram</MenuItem>
                    <MenuItem value="Nagaland">Nagaland</MenuItem>
                    <MenuItem value="Odisha">Odisha</MenuItem>
                    <MenuItem value="Puducherry">Puducherry</MenuItem>
                    <MenuItem value="Punjab">Punjab</MenuItem>
                    <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                    <MenuItem value="Sikkim">Sikkim</MenuItem>
                    <MenuItem value="Tamil-Nadu">Tamil Nadu</MenuItem>
                    <MenuItem value="Telangana">Telangana</MenuItem>
                    <MenuItem value="Uttar-Pradesh ">Uttar Pradesh</MenuItem>
                    <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                    <MenuItem value="West-Bengal">West Bengal</MenuItem>
                    <MenuItem value="Andaman-Nicobar-Islands">
                      Andaman and Nicobar Islands
                    </MenuItem>
                    <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                    <MenuItem value="Dadra-Nagar-Haveli-Daman-Diu">
                      Dadra and Nagar Haveli and Daman and Diu
                    </MenuItem>
                    <MenuItem value="Delhi">Delhi</MenuItem>
                    <MenuItem value="Ladakh">Ladakh</MenuItem>
                    <MenuItem value="Lakshadweep">Lakshadweep</MenuItem>
                    <MenuItem value="Puducherry">Puducherry</MenuItem>
                  </SelectFun>
                </FormControl>
              </div>
              <div className="col-md-3 pl-0">
                <FormControl className="select-dis-search">
                  <InputLabel htmlFor="age-native-simple">District</InputLabel>
                  <SelectFun
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={this.handleDistChange}
                    value={this.state.city}
                    className="select-dis-search"
                    placeholder="District"
                  >
                    <MenuItem value="none" selected>
                      None
                    </MenuItem>
                    {this.state.citiesList.map((districts, i) => (
                      <MenuItem value={districts} key={i}>
                        {districts}
                      </MenuItem>
                    ))}
                  </SelectFun>
                </FormControl>
              </div>
              <div className="col-md-2">
                <button
                  className="btn-login z-depth-1a btn-block mt-3 pt-2 pb-2"
                  onClick={this.handleSearch}
                  disabled={!enabled}
                >
                  Search
                </button>
              </div>
              <div className="col-md-12 d-flex mb-5">
                <div className="custom-control custom-checkbox mr-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="professional"
                    checked={this.state.professional}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="professional"
                  >
                    Professional
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="unprofessional"
                    checked={this.state.professional}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="unprofessional"
                  >
                    Un-Professional
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="skilltags-res ">
                <ul>
                  {this.state.skillsResult &&
                    this.state.skillsResult.length > 0 &&
                    this.state.skillsResult.map((item, i) => {
                      return (
                        <li key={i}>
                          <button id={i}>
                            {item}
                            <span onClick={() => this.removeSkillTag(item)}>
                              <i className="fa fa-times"></i>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>

            {/* search result */}

            {/* <ul className="circle">
                {this.state.searchResult.map((result,index)=>(
                     <li key={index.toString()}>
                         {result.userProfileId}
                     </li>
                ))}
            </ul> */}

            {!isMobile ? (
              <div className="col-md-12">
                <ul className="all-talent">
                  {this.state.searchResult?.length > 0 && (
                    <div className="row text-left list-head">
                      <div className="col-md-6">
                        <p>Name</p>
                      </div>
                      <div className="col-md-4">
                        <p>Location</p>
                      </div>
                      <div className="col-md-2">
                        <p>Ratings</p>
                      </div>
                    </div>
                  )}
                  {this.state.searchResult?.length > 0 ? (
                    this.getUnique(
                      this.state.searchResult,
                      "userProfileId"
                    ).map((result, i) => (
                      <li>
                        <div className="row talent-profile booker-results booking-result pr-2 pl-2">
                          <div className="col-md-2">
                            {result.profilePic === null ? (
                              <img src="/user-img.png"></img>
                            ) : (
                              <img src={result.profilePic}></img>
                            )}
                          </div>
                          <div className="col-md-4">
                            <p className="m-0 p-0">{result.userName}</p>
                            <div className="d-block">
                              {result.skillTags &&
                                result.skillTags.map((skill, ind) => {
                                  return <h6 key={ind}>{skill}</h6>;
                                })}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <p className="p-0 mt-2 mb-0">
                              {result.address.state}
                            </p>
                            <h5 className="p-0 m-0">
                              {result.address.district}
                            </h5>
                          </div>
                          <div className="col-md-1">
                            <i className="fa fa-star mt-4"></i>
                          </div>
                          <div className="col-md-2">
                            <Link to={`userprofile/${result.userProfileId}`}>
                              View Profile
                            </Link>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <>
                      <img
                        src={artistSearch}
                        style={{
                          display: "block",
                          margin: "2% auto 10% auto",
                          width: "30%",
                        }}
                      />
                    </>
                  )}
                </ul>
              </div>
            ) : (
              <ul className="all-talent">
                {this.state.searchResult?.length > 0 ? (
                  this.getUnique(this.state.searchResult, "userProfileId").map(
                    (result, i) => (
                      <li>
                        <div
                          className="col-md-12 talent-profile"
                          key={i.toString()}
                        >
                          <div className="booking-result-mobile">
                            {result.profilePic === null ? (
                              <img src="/user-img.png"></img>
                            ) : (
                              <img src={result.profilePic}></img>
                            )}
                            <div className="result-data">
                              <h4 className="username">{result.userName}</h4>
                              <div className="d-flex mob-skill-tags">
                                {result.skillTags &&
                                  result.skillTags
                                    .slice(0, 3)
                                    .map((skill, ind) => {
                                      return (
                                        <>
                                          <h6 key={ind}>{skill}</h6>
                                        </>
                                      );
                                    })}
                                {result.skillTags.length - 3 > 0 && (
                                  <h6>{result.skillTags.length - 3}</h6>
                                )}
                              </div>
                              <p className="mob-address">
                                {result.address.state},{result.address.district}
                              </p>
                              {/* <i className="fa fa-star"></i> */}
                              <Link to={`userprofile/${result.userProfileId}`}>
                                View Profile
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  )
                ) : (
                  <div className="col-md-12 pb-5">
                    <img
                      src={artistSearch}
                      style={{
                        display: "block",
                        margin: "5% auto",

                        width: "30%",
                      }}
                    />
                  </div>
                )}
              </ul>
            )}

            {/* search results */}
          </div>
        </MDBContainer>
      </div>
    );
  }
}
