import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import ApiService from "../components/service/ApiService";
import Comments from "../components/feed/comments";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import "../components/feed/feeds.css";
import { ChatBubbleOutline } from "@material-ui/icons";
import Clap from "../components/images/clapping.svg";

export default class FamePost extends Component {
  constructor(props) {
    super();
    this.state = {
      postdata: [],
      defaultImg: "user-img.png",
      loading: true,
      open: false,
      commentLists: "",
      like: "false",
      addcomment: "false",
      Nopostmessage: false,
    };
  }
  componentDidMount() {
    this.fetchPost();
  }

  fetchPost = () => {
    const postId = this.props.match.params.id;

    ApiService.SearchbyPost(this.props.match.params.id).then((res) => {
      this.setState({
        postdata: res.data.dataItem,
        loading: false,
        Nopostmessage: false,
      });
    });
  };

  handleLike = (e) => {
    e.preventDefault();
    this.setState({
      like: "true",
    });
  };
  updateComment = (newComment) => {
    this.setState({
      commentLists: newComment,
      addcomment: "true",
    });
  };
  refreshcomment = (refresh) => {
    this.setState({
      addcomment: refresh,
    });
  };
  render() {
    if (this.state.loading === true && this.state.postdata.length < 1) {
      return (
        <div
          style={{
            display: "block",
            margin: "30vh auto",
            height: "50vh",
            width: "50px",
          }}
        >
          <i
            className="fa fa-spinner fa-pulse"
            style={{
              fontSize: "60px",

              color: "#b81b21",
            }}
          />
        </div>
      );
    } else if (this.state.Nopostmessage === true) {
      return (
        <div style={{ overflow: "auto", width: "100%", background: "#ebebeb" }}>
          <ul
            className="circle"
            style={{
              width: "100%",
              background: "#ebebeb",
              paddingTop: "1vw",
              marginTop: "0",
            }}
          >
            <li>
              <div className="feed-view-single">
                <div className="feed-head" style={{ minHeight: "80vh" }}>
                  <h4
                    style={{
                      textAlign: "center",
                      display: "block",
                      width: "100%",
                      padding: "35vh 0",
                    }}
                  >
                    <PriorityHighIcon className="exclaim-icon" />
                    <br />
                    The post has either been deleted by the owner or currently
                    unavailable
                  </h4>
                </div>
              </div>
            </li>
          </ul>
        </div>
      );
    }
    return (
      <div style={{ overflow: "auto", width: "100%", background: "#ebebeb" }}>
        <ul
          className="circle"
          style={{
            width: "100%",
            background: "#ebebeb",
            paddingTop: "1vw",
            marginTop: "0",
          }}
        >
          <li key={this.state.postdata.postId}>
            <div className="feed-view-single">
              <div className="feed-head">
                <Link
                  to={{
                    pathname: `/default-profile/${this.state.postdata.userProfileId}`,

                    state: this.state.postdata.userProfileId,
                  }}
                >
                  {this.state.postdata.userProfilePic === null ? (
                    <img
                      src={this.state.defaultImg}
                      className="img-profile-feed"
                      alt="post"
                    />
                  ) : (
                    <img
                      src={this.state.postdata.userProfilePic}
                      alt="post"
                      className="img-profile-feed"
                    />
                  )}

                  <div className="profile-feed-name">
                    <h4 className="main-title text-left">
                      {this.state.postdata.userName}
                    </h4>
                  </div>
                </Link>
                <div className="profile-feed-time">
                  <h5>{this.state.postdata.timeStamp}</h5>
                </div>
              </div>
              <div className="post-detail">
                <h4>{this.state.postdata.title}</h4>
                <p>{this.state.postdata.description}</p>
              </div>
              {this.state.postdata.mediaType === "image" ? (
                <img
                  src={this.state.postdata.imageFileLocation}
                  alt="post"
                  className="main-view"
                />
              ) : (
                <ReactPlayer
                  className="videoFrame main-view"
                  url={this.state.postdata.youTubeVideoDetails.youTubeVideoUrl}
                  controls={true}
                />
              )}

              <ul className="prfl-buttons">
                <li key="like">
                  <button onClick={this.handleLike.bind(this)}>
                    <img src={Clap} />
                  </button>

                  <button onClick={()=>this.props.history.push('/')}>
                    <span className="highlight">
                      {this.state.postdata.postLikeCount}
                    </span>
                  </button>
                </li>
                <li key="comment">
                  <ChatBubbleOutline/>{" "}
                  <button onClick={()=>this.props.history.push('/')}>
                    <span className="highlight">{this.state.postdata.postCommentCount}</span>
                  </button>
                  {/* <CommentCount/> {this.state.commentcount} */}
                </li>

              </ul>

              {/* <Comments
                commentlist={this.state.commentLists}
                postid={this.state.postdata.postId}
                refreshFunction={this.updateComment}
                postcomment={this.state.postdata.postCommentCount}
                postlike={this.state.postdata.postLikeCount}
                liked={this.state.postdata.isLikedByViewer}
                postuserid={this.state.postdata.userProfileId}
              /> */}
            </div>
          </li>
          
        </ul>

        {/* <button onClick={this.LoadMore}>Load More</button> */}
      </div>
    );
  }
}
