import axios from "axios";
import { axiosData } from "./axiosapi";

export const Login = (data) => {
  return axiosData().post("/user/login", data);
};

export const BookerLogin = (data) => {
  return axiosData().post("/booker/login", data);
};

export const RegisterUser = (userobj) => {
    return axiosData().post("/user/register", userobj);
  };

  export const BookerRegister = (body) =>{
      return axiosData().post("/booker/register", body);
  }


