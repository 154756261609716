import React, { Component } from "react";
import ReactPlayer from "react-player";


export default class Guide extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
      
          <div className="container-fluid guide">
           
            <ReactPlayer
                   
                    url={'/public/videos/famenet-guide.mp4'}
                    controls={true}
                  />
           
          </div>
     
      </>
    );
  }
}
