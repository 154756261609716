import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBInput } from "mdbreact";
import { Link } from "react-router-dom";

export default class RefundPolicy extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-4 pr-0 pb-4 pl-0">
          <div className="privacy-head">
            <h3>Sales & Refund Policy</h3>
            <p>
              Famenet is completely dedicated to serving its users the best free platform where they can share their
              arts and connect with other artists.And the best part is we are completely free .
              We dont provide any service or sell any product in the name of FameNet .
              So we strongly discourage any malpractices done on the name of FameNet of providing any paid services .</p>
             
            <p>
             As we donot provide any paid product or services .So our users can without  nay hesitation enjoy the free platform 
             and  create their artisitc profiles and share their arts  to people .
             At FameNet neithter ask for any money nor do we refund any money .
            </p>
          </div>

          
          
          </div>
</>
    );
  }
}
