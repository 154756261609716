import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ApiService from "../service/ApiService";

 class CompletePost extends Component {
  constructor(props) {
    super();
    this.state = {
      videoTitle: "",
      videoDetail: "",
      postUrl: "",
      passion: "",
      charLimit: 120,
      posttype: "",
      largevideothumb: "",
      mediumvideothumb: "",
      disable: true,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("passion")) {
      let passiontopost = JSON.parse(sessionStorage.getItem("passion"));
      let passion = passiontopost.toString();

      let posturl = JSON.parse(sessionStorage.getItem("videourl"));
      let largevideothumb = sessionStorage.getItem("largevideothumb");
      let mediumvideothumb = sessionStorage.getItem("mediumvideothumb");

      this.setState({
        postUrl: posturl,
        passion: passion,
        posttype: "video",
        largevideothumb: largevideothumb,
        mediumvideothumb: mediumvideothumb,
      });
    } else if (localStorage.getItem("image")) {
      let posturl = JSON.parse(localStorage.getItem("image"));
      this.setState({
        postUrl: posturl,
        posttype: "image",
      });
    }
  }
  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  handlesubmit = (e) => {
    this.setState({
      disable: false,
    });
    let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let profileid = this.props.LoginData.loginSuccesData.userProfileId;
    let videoid = this.state.postUrl;
    var ID = "";
    videoid = videoid
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (videoid[2] !== undefined) {
      ID = videoid[2].split(/[^0-9a-z_]/i);
      ID = ID[0];
    } else {
      ID = videoid;
    }

    e.preventDefault();
    if (sessionStorage.getItem("videourl")) {
      var userPost = {
        title: this.state.videoTitle,
        description: this.state.videoDetail,
        youTubeVideoDetails: {
          youTubeVideoUrl: this.state.postUrl,
          youTubeVideoId: ID,
          youTubeVideoDefaultThumbNail: "imgggg1",
          youTubeVideoMediumThumbNail: "imgggg2",
          youTubeVideoHighThumbNail: this.state.largevideothumb,
        },
        userProfileId: profileid,
        sessionId: cookieValue,
        mediaType: "video",
        passion: this.state.passion.toUpperCase(),
      };

      ApiService.uploadPost(userPost)
      .then((res) => {
        if (res.data.status === "success") {
          sessionStorage.setItem("activetab", 1);
          window.location = "/feed";
        }
      });
    } else if (localStorage.getItem("image")) {
      let imagename = localStorage.getItem("image-name");
      let imageext = imagename.split(".").pop();
      var imgPost = {
        title: this.state.videoTitle,
        description: this.state.videoDetail,
        userProfileId: profileid,
        sessionId: cookieValue,
        mediaType: "image",
        passion: "PICTURE",
        imageFileExtension: "." + imageext,
        imageFile: this.state.postUrl,
      };
      ApiService.uploadPost(imgPost).then((res) => {
        if (res.data.status === "success") {
          sessionStorage.setItem("activetab", 1);
          window.location = "/feed";
          this.setState({
            disable: false,
          });
        }
      });
    }
  };

  render() {
    let isEnabled = true;
    if (this.state.videoDetail.length > 10 && this.state.disable === true) {
      isEnabled = false;
    } else {
      isEnabled = true;
    }

    return (
      <div className="container-fluid category-bg">
        <div className="row">
          {this.state.posttype === "video" ? (
            <h2>Complete Video Details</h2>
          ) : (
            <h2>Complete Post Details</h2>
          )}
        </div>
        <div className="row post-data-row mx-auto">
          <div
            className="col-lg-6 col-md-6 col-sm-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "300px",
            }}
          >
            <div className="video-thumb-medium">
              {this.state.posttype === "image" ? (
                <img src={"data:image/png;base64," + this.state.postUrl} alt=""/>
              ) : (
                <img src={this.state.largevideothumb} alt=""/>
              )}
            </div>
          </div>

          <div
            className="col-lg-6 mb-5 col-sm-6 mx-auto col-md-6"
            style={{ marginTop: "-20px" }}
          >
            <label className="black-text">Title</label>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("videoTitle").bind(this)}
              value={this.state.videoTitle}
              name="videoTitle"
            />
            <br />
            <label className="black-text">Description</label>
            <textarea
              type="text"
              className="form-control py-2"
              rows="6"
              onChange={this.handleChange("videoDetail")}
              value={this.state.videoDetail}
              name="videoDetail"
              maxLength="120"
            />
            <div>
              Remaining Characters:{" "}
              {this.state.charLimit - this.state.videoDetail.length}
            </div>
          </div>
        </div>

        <div className="upload-controls mx-auto row post-data-row my-5">
          <div className="col-md-2 col-sm-9">
            <button value="cancel" className="button-cancel">
              <Link to="/feed">Cancel</Link>
            </button>
          </div>

          <div className="col-md-1 offset-md-7 col-sm-1">
            <button value="back" className="button-cancel">
              {this.state.posttype === "image" ? (
                <Link to="/feed"></Link>
              ) : (
                <Link to="/upload-video">Back</Link>
              )}
            </button>
          </div>
          <div className="col-md-2 col-sm-2">
            <button
              value="Next"
              className="btn-login float-right"
              onClick={this.handlesubmit}
              disabled={isEnabled}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(CompletePost);
