import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ReactComponent as ImgIcon } from "../images/img.svg";

import { ReactComponent as ActIcon } from "../images/video.svg";
import { ReactComponent as SingIcon } from "../images/mic.svg";
import { ReactComponent as DanceIcon } from "../images/dancing.svg";
import "./profile.css";
import ProfilePosts from "./ProfilePosts";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function PassionTabs(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [getdelete,setGetDelete] = useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CheckPassion = (passion) => {
    if (passion === "MUSIC") {
      return <SingIcon />;
    } else if (passion === "ACTING") {
      return <ActIcon />;
    } else if (passion === "DANCING") {
      return <DanceIcon />;
    }
  };

  const MakeUpper = (passion) => {
    if (passion === "MUSIC") {
      return "Music";
    } else if (passion === "ACTING") {
      return "Acting";
    } else if (passion === "DANCING") {
      return "Dancing";
    }
  };
  const [selectedpassion, setselectedpassion] = useState("PICTURE");
  function getdeldata(del){
    props.senddelpost(del)
  
  }
  
  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          className="tabindicate"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label="Pictures"
            icon={<ImgIcon />}
            {...a11yProps(0)}
            onClick={() => setselectedpassion("PICTURE")}
            vieweeid={props.vieweeid}
          />
          {props.userPassion.map((passion, i) => (
            <Tab
              label={MakeUpper(passion)}
              icon={CheckPassion(passion)}
              {...a11yProps(i + 1)}
              key={passion.toString()}
              id="capitalizeTab"
              onClick={() => setselectedpassion(passion)}
              vieweeid={props.vieweeid}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className="post-panel"
      >
        <ProfilePosts
          passion="PICTURE"
          selfprofile={props.selfprofile}
          vieweeid={props.vieweeid}
          getdel={getdeldata}
        />
      </TabPanel>
      {props.userPassion.map((passion, i) => (
        <TabPanel
          value={value}
          index={i + 1}
          dir={theme.direction}
          className="post-panel"
          key={i + 1}
        >
          <ProfilePosts
            passion={passion}
            selfprofile={props.selfprofile}
            vieweeid={props.vieweeid}
            p={selectedpassion}
            getdel={getdeldata}
          />
        </TabPanel>
      ))}
    </>
  );
}
