import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Likeicon, Commenticon} from "../images/likesvg";

// import {checkAuthntication} from '../../App';

import { MDBContainer, MDBRow, MDBCol, MDBInput,MDBModal } from 'mdbreact';

export default class ProfileFeed extends Component {
  state = {
    modal: false
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // componentDidMount(){
  //   if(!this.props.isLoggedIn){
  //    this.props.history.goBack();
  //     return null
  //   }
  // }
  
  render() {
    return (
        <MDBContainer>
        {/* <MDBRow>
        <MDBCol>
                <ul className="feed-menu">
                    <li><Link href="#" className="active">Following</Link></li>
                    <li><Link href="#">Recommended</Link></li>
                    <div className="active-animate"></div>

                </ul>
            </MDBCol>
        </MDBRow> */}
    <MDBRow>
      <MDBCol>
        <div className="feed-view">
            <div className="feed-head">
          <img src={require('../images/anushka.jpg')} className="img-profile-feed" alt="anushka"/>
            <div className="profile-feed-name">
            <h3 className="main-title text-left">Anushka Sharma</h3>
            
            
            </div>
            <div className="profile-feed-time">
              <p>2 hours ago</p>
            </div>
            </div>
            
            <ul className="prfl-buttons">
                        <li><Link to={"/"}><Likeicon/> 200</Link> </li>

                        <li><Link onClick={this.toggle}><Commenticon/> 100</Link> </li>
                        


                    </ul>
                    <ul className="comments-list">
            <li>
          <img src={require('../images/anushka.jpg')} className="img-fluid " alt="anushka"/>

                <div>
                <h3 className="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} className="img-fluid" alt="anushka"/>

                <div>
                <h3 className="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
      
        </ul>
        <MDBInput type="textarea" rows="2" label="Add Comment" className="post-comment" />

        </div>
        <div className="feed-view">
            <div className="feed-head">
          <img src={require('../images/anushka.jpg')} className="img-profile-feed" alt="anushka"/>
            <div className="profile-feed-name">
            <h3 className="main-title text-left">Anushka Sharma</h3>
            
            </div>
            </div>
            <img src={require('../images/dummy2.png')} className="img-responsive main-view" alt="dummy2"/>
            <ul className="prfl-buttons">
                        <li><Link to={"/"}><Likeicon/> 200</Link> </li>

                        <li><Link to={"/"}><Commenticon/> 100</Link> </li>
                        


                    </ul>
                    <ul className="comments-list">
            <li>
          <img src={require('../images/anushka.jpg')} className="img-fluid " alt="anushka"/>

                <div>
                <h3 className="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} className="img-fluid" alt="anushka"/>

                <div>
                <h3 className="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
      
        </ul>
        <MDBInput type="textarea" rows="2" label="Add Comment" className="post-comment" />

        </div>
        <div className="feed-view">
            <div className="feed-head">
          <img src={require('../images/anushka.jpg')} className="img-profile-feed" alt="anushka"/>
            <div className="profile-feed-name">
            <h3 className="main-title text-left">Anushka Sharma</h3>
            
            </div>
            </div>
            <img src={require('../images/dummy3.png')} className="img-responsive main-view" alt="dummy3"/>
            <ul className="prfl-buttons">
                        <li><Link to={"/"}><Likeicon/> 200</Link> </li>

                        <li><Link ><Commenticon/> 100</Link> </li>
                        


                    </ul>
                    <ul className="comments-list">
            <li>
          <img src={require('../images/anushka.jpg')} className="img-fluid " alt="anushka"/>

                <div>
                <h3 className="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} className="img-fluid" alt="anushka"/>

                <div>
                <h3 className="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
      
        </ul>
        <MDBInput type="textarea" rows="2" label="Add Comment" className="post-comment" />

        </div>
       </MDBCol>
       <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg" id='comments'>
<div className="modal-content modal-feed-view">
            
<div className="modal-feed-head">
         
            </div>
            <MDBInput type="textarea" rows="2" label="Add Comment" className="modal-post-comment" />

                    <ul className="modal-comments-list">
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid " alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
            <li>
          <img src={require('../images/anushka.jpg')} class="img-fluid" alt="anushka"/>

                <div>
                <h3 class="main-title text-left">Ravi</h3>
            <h6>Nics Pic, Looks cool</h6>
                </div>
            </li>
      
        </ul>
       
        </div>
</MDBModal>
    </MDBRow>
    </MDBContainer>
   
    );
  }
}