import React, { Component } from "react";
import { Link } from "react-router-dom";


export default class AboutUs extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-4 pr-0 pb-4 pl-0">
          <div className="privacy-head">
            <h2>FameNet is one stop shop for promoting and hiring passionate and talented artists :</h2>
            <h3>FameNet is an official platform for :</h3>
            <h4>
            If You are an  artist  or a group :
            </h4>
            <hr></hr>
            <ul id="disc-list">
              <li>1- create your profile  and share your passion/talent videos and photos </li>
              <li>2- connect to various artists across India by admiring them </li>
              <li>3- Check out new talents in recommended feeds</li>
              <li>4- Easily share your profile and posts  to various intented people or group </li>
              <li>5- If only you opt for ,opportunity to get hired for shows and events </li>
              <li>6- If you dont , you wont be contacted for shows and events</li>
              </ul>

            <h4>
            If You are in entertainment Industry :
            </h4>
            <hr></hr>
            <ul>
              <li>1- create your profile  and connect to People in entertainment industry</li>
              <li>2- Check out  new talents be it a specific region or whole India</li>
               </ul>

               <h4>
               If you are looking for artists for shows or events :
            </h4>
            <hr></hr>
            <ul>
              <li>1- Book  artists by searching them in FameNet depending upon your requirement</li>
              <li>2- Keep a track of your bookings in your profile</li>
               </ul>
               <p>To know how to register and share your videos <Link to="/guide" style={{color:"#b81b21"}}>click here</Link> </p>
               <p><Link to="/sign-up" style={{color:"#b81b21"}}>Join now &nbsp;</Link><Link to="/" style={{color:"#b81b21"}}>Login</Link></p>
          </div>
        </div>
      </>
    );
  }
}
