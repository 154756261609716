import React, { Component } from "react";
import ApiService from "../service/ApiService";
import "./admin.css";
import { MDBDataTable, MDBTooltip } from "mdbreact";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";

export default class DeactivatedPosts extends Component {
  constructor(props) {
    super();
    this.state = {
      complaints: [],
      pagenum: 0,
      pagesize: 5,
      totalposts: 0,
  
    };
  }

  componentDidMount() {
    this.loadcomplaints();
  }
  loadcomplaints = () => {
    const { pagenum, pagesize } = this.state;
    ApiService.LoadDeactivatedPosts(pagenum, pagesize).then((res) => {
     
      let numofcomplaints = parseInt(res.data[0].totalNoOfDeactivatedPosts);
      this.setState({
        complaints: res.data,
        totalposts: numofcomplaints,
      
      });
    });
  };
  loadmorecomplaints = () => {
    this.setState(
      (prevState) => ({
        pagenum: prevState.pagenum + 1,
      }),
      this.loadcomplaints
    );
  };
  loadlesscomplaints = () => {
    this.setState(
      (prevState) => ({
        pagenum: prevState.pagenum - 1,
        complaints: [],
      }),
      this.loadcomplaints
    );
  };

  blockpost = (action,id) =>{
    let doaction  
    if(action === 'delete'){
      doaction = 'delete'
    }
    else{
      doaction = 'reactivate'
    }
    ApiService.DeactPost(id,doaction)
    .then((res)=>{
      if(res.data.status === 'success'){
       this.setState({
         complaints:[]
       },this.loadcomplaints)
      }
    })
  }
  render() {
    let data = {
      columns: [
        {
          label: "Sr. No.",
          field: "sr",
          sort: "asc",
          width: 50,
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 370,
        },

        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 100,
        },
        {
          label: "Post Id",
          field: "postid",
          sort: "asc",
          width: 100,
        },

        {
          label: "Uploader",
          field: "uploader",
          sort: "asc",
          width: 100,
        },

        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Time Stamp",
          field: "timestamp",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],



      rows: this.state.complaints.map((complaint, index) => ({
        sr: index+1,
        title: complaint.title,
        description: complaint.description,

        postid: complaint.postId,
        uploader: complaint.uploader,
        status: complaint.status,
        timestamp: complaint.timeStamp,
        action: <>
        <MDBTooltip
        domElement
        tag="span"
        placement="top"
      >
        
        <button onClick={()=>this.blockpost('reactivate',complaint.postId)}><BlockIcon/></button>
        <span><b>Reactivate Post</b></span>
        </MDBTooltip>
        <MDBTooltip
        domElement
        tag="span"
        placement="top"
      >
         
        <button onClick={()=>this.blockpost('delete',complaint.postId)}><DeleteIcon/></button>
        <span><b>Delete Post</b></span>
        </MDBTooltip>
        
        </>,
      })),
    };

    return (
      <>
        <h4>Deactivated Posts</h4>
        <MDBDataTable striped bordered hover data={data} paging={false} />

        <div className="pagination">
          {this.state.pagenum > 0 ? (
            <button onClick={this.loadlesscomplaints}>prev</button>
          ) : (
            <></>
          )}

          <button>{this.state.pagenum}</button>
          {this.state.complaints.length === this.state.totalposts ? (
            <></>
          ) : (
            <button onClick={this.loadmorecomplaints}>Next</button>
          )}
        </div>
      </>
    );
  }
}
