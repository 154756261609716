import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBInput } from "mdbreact";
import { Link } from "react-router-dom";

export default class TermsConditions extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-4 pr-0 pb-4 pl-0">
          <div className="privacy-head">
              <h4 className="text-center">By registering to  famenet.in website you are agreeing to be religiously  follow below terms and conditions .</h4>
            <h3 className="mt-2">Terms of Use</h3>
            <ol className="terms-list">
                <li>You may not post nude, partially nude, or sexually suggestive photos.</li>
                <li>You may not post any such thing that might hurt religious,communal sentiments of any person/place .</li>
                <li>You are responsible for any activity that occurs under your user name.</li>
                <li>You are responsible for keeping your password secure.</li>
                <li>You must not abuse, harass, threaten, impersonate or intimidate other Famenet users.</li>
                <li>You are solely responsible for your conduct and any data, text, information, screen names, graphics, photos, profiles, audio and video clips, links ("Content") that you submit, post, and display on the Famenet website.</li>
                <li>As we are using youtube videos extensively  for sharing purpose .Please make sure you abide by these policies:

                    <ul>
                        <li>The video you are sharing belongs to you and the owner /copyright holder of that particulr video</li>
                        <li>You must not share any such video you dont have permission to .</li>
                    </ul>
                </li>
                <li>You must not create or submit unwanted  comments to any Famenet users/posts .</li>
                <li>Any concern you might have for a particulr video ,you can report that particular post .</li>
                <li>Once the post gets enough complaints/grievances it might be subject to deletion .</li>

            </ol>
            <h5 className="text-center">Violation of any of these agreements will result in the termination or deactivation of your Famenet account. While Famenet prohibits such conduct and content on its site, you understand and agree that Famenet cannot be responsible for the Content posted on its web site and you nonetheless may be exposed to such materials and that you use the Famenet
 website at your own risk.
 </h5>
 <h5 className="text-center">Checkout <Link to={'./privacy-policy'}  style={{color:"#b81b21"}}>Privacy policy</Link></h5>
          </div>

        </div>
      </>
    );
  }
}
