import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./components/login/PrivateRoute";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/login/Login";
import Header from "./components/headerComponent.jsx";
import UserProfile from "./components/profile/profile.jsx";
import UserFeed from "./components/feed/UserFeed";
import UploadLink from "./components/posts/UploadVideo";
import CompletePost from "./components/posts/VideoDetails";
import ThankYou from "./components/login/Thankyou";
import AppriciatesList from "./components/appreciator/Appreciators.jsx";
import ProfileFeed from "./components/profile/ProfileFeed.jsx";
import DefaultProfile from "./components/profile/default.profile.jsx";
import Skills from "./components/login/SkillTags.jsx";
import NotificationPage from "./components/appreciator/notificationPage";
import SignUp from "./components/login/Signup.jsx";
import Category from "./components/login/Passion.jsx";
import EditProfile from "./components/profile/EditProfile.jsx";
import SelectToPost from "./components/posts/passiontopost";
import FooterPage from "./components/footer";
import ViewPost from "./components/viewpost";
import Admin from "./components/admin/admin";
import Help from "./components/help";
import PrivacyPolicy from "./components/privacypolicy";
import Guide from "./components/guide/guide";
import TermsConditions from "./components/termsandconditions";
import BookerSignup from "./components/login/bookerSignup.js";
import handleDragStart from "./components/feed/popular";
import PopularViews from "./components/feed/popular";
import BookerHome from "./components/booker/home";
import FamePost from "./components/famepost";
import NotFound from "./notfound";
import BookArtist from "./components/booker/bookArtist";
import AboutUs from "./components/aboutUs";
import VideosG from "./components/guide/videosg";
import SearchSkill from "./components/profile/SearchSkill";
import UserProfileShareable from "./components/profile/userProfiletoshare";
import CheckAuthentication from "./checkAuthentication";
import { useDispatch, useSelector } from "react-redux";
import BookingList from "./components/booker/bookingList";
import AboutUsNative from "./components/aboutUs - native";
import RefundPolicy from "./components/refund-policy";
import Legal from "./components/legal";
import Sitemap from "./components/sitemap";

// import SearchBar from './components/search.component.jsx';

const App = () => {
  const [isLoggedIn, setisLoggedin] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) => state.LoginData);

  useEffect(() => {
   
    var isLoggedIn = false;
    var profileId =
      LoginData.loginSuccesData.sessionId &&
      LoginData.loginSuccesData.userProfileId;
    var admin = LoginData.isadmin === "true";
    var booker = LoginData?.bookerlogin && LoginData.bookerlogin[0] === "success";
    if (profileId) {
      isLoggedIn = true;
    } else if (admin) {
      isLoggedIn = true;
    } else if (booker) {
      isLoggedIn = true;
    }

    setisLoggedin(isLoggedIn);
  }, []);

  return (
    <div className="App">
      <Header isLoggedIn={isLoggedIn} />
      <div className="auth-wrapper">
        <Switch>
          <PrivateRoute component={UserFeed} path="/feed" exact />
          <PrivateRoute path="/admin" component={Admin} exact />
          <PrivateRoute path="/home" component={BookerHome} exact />
          <PrivateRoute
            component={SearchSkill}
            path="/searchbyskill"
            exact={true}
          />
          <PrivateRoute path="/profile" component={UserProfile} exact={true} />

          <PrivateRoute path="/popular-views" component={PopularViews} />

          <PrivateRoute
            path="/upload-video"
            component={UploadLink}
            exact={true}
          />
          <PrivateRoute path="/post-detail" component={CompletePost} />
          <PrivateRoute
            path="/People"
            component={AppriciatesList}
            exact={true}
          />
          <PrivateRoute path="/profile-feed" component={ProfileFeed} />

          <PrivateRoute
            path="/default-profile/:id"
            component={DefaultProfile}
            exact={true}
          />
          <PrivateRoute path="/notifications" component={NotificationPage} />
          <PrivateRoute path="/edit-profile" component={EditProfile} />
          <PrivateRoute path="/select-passion" component={SelectToPost} />
          <PrivateRoute path="/book-artist/:id" component={BookArtist} />
          <PrivateRoute path="/booking-list" component={BookingList}/>
          <Route path="/viewpost" component={ViewPost} />
          <Route
            path="/userprofile/:id"
            component={UserProfileShareable}
            exact
          />

          <Route
            exact
            path="/"
            render={(props) => {
              if (LoginData.loginSuccesData.userProfileId) {
                return <Redirect to="/feed" />;
              } else if (LoginData.isadmin === "true") {
                return <Redirect to="/admin" />;
              } else if (LoginData?.bookerlogin && LoginData.bookerlogin[0] === "success") {
                return <Redirect to="/home" />;
              } else {
                return (
                  <Login
                    {...props}
                    return
                    handleLogin={(val) => this.handleLogin(val)}
                  />
                );
              }
            }}
          />
          <Route
            path="/signup-for-booking"
            render={(props) => {
              if (LoginData.loginSuccesData.userProfileId) {
                return <Redirect to="/feed" />;
              } else if (LoginData.isadmin === "true") {
                return <Redirect to="/admin" />;
              } else if (LoginData.bookerlogin[0] === "success") {
                return <Redirect to="/home" />;
              } else {
                return <BookerSignup {...props} />;
              }
            }}
          />
          <Route
            path="/sign-up"
            render={(props) => {
              if (LoginData.loginSuccesData.userProfileId) {
                return <Redirect to="/feed" />;
              } else if (LoginData.isadmin === "true") {
                return <Redirect to="/admin" />;
              } else if (LoginData.bookerlogin[0] === "success") {
                return <Redirect to="/home" />;
              } else {
                return <SignUp {...props} />;
              }
            }}
          />
          <Route
            exact
            path="/thankyou"
            render={(props) => (
              <ThankYou
                {...props}
                handleLogin={(val) => this.handleLogin(val)}
              />
            )}
          />
          <Route path="/skills" component={Skills} />
          <Route
            path="/category"
            render={(props) => {
              if (localStorage.getItem("userform")) {
                return <Category {...props} />;
              } else {
                return <Redirect to="/sign-up" />;
              }
            }}
          />
          <Route path="/help" component={Help} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/legal" component={Legal} />
          <Route path="/sitemap" component={Sitemap} />

          <Route path="/famepost/:id" component={FamePost} />

          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route exact path="/guide" component={Guide} />
          <Route exact path="/videosg/:name" component={VideosG} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/about-native" component={AboutUsNative} />

          <Route component={NotFound} />
          {/* <Route path="/404" component={NotFound} />
                <Redirect to="/404" /> */}
        </Switch>
      </div>
      <FooterPage />
    </div>
  );
};

export default withRouter(App);
