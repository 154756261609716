import axios from "axios";

const USER_API_BASE_URL = "https://service1.famenet.in/fameplus-user-profile-service/user";
const POST_MGMT_API_BASE_URL = "https://service2.famenet.in/fameplus-post-managementservice/postmgmt";
const NOTIFICATION_MGMT_BASE_URL ="https://service3.famenet.in/fameplus-notification-service/notification/list"
const ADMIN_API_BASE_URL = "https://service1.famenet.in/fameplus-user-profile-service/admin/";
class ApiService {
  Login(body) {
    return axios({
      method: "post",
      url: USER_API_BASE_URL + "/login",
      data: body,
    });
  }
  RegisterUser(userobj) {
    return axios({
      method: "post",
      url: USER_API_BASE_URL + "/register",
      data: userobj,
    });
  }

  getSkillTags(categoryData) {
    return axios({
      method: "post",
      url: USER_API_BASE_URL + "/skillTags",
      data: {
        categories: categoryData,
      },
    });
  }

  validateEmail(emailId) {
    return axios.get(USER_API_BASE_URL + "/validate/em/" + emailId);
  }

  validateUsername(username) {
    return axios.get(USER_API_BASE_URL + "/validate/un/" + username);
  }

  UserDetails(userbody) {
    return axios({
      method: "post",
      url: USER_API_BASE_URL + "/view",
      data: userbody
    });
  }
  uploadCoverPic(userbody){
    return axios({
      method:"post",
      url: USER_API_BASE_URL + "/upload/cover",
      data: userbody
    })
  }
  uploadProfilePic(userbody){
    return axios({
      method:"post",
      url: USER_API_BASE_URL + "/upload/profile",
      data: userbody
    })
  }
  getUserData(userProfileId,sessionId) {
    return axios.get(USER_API_BASE_URL + "/profile/edit/" + userProfileId + "/" + sessionId);
  }
  saveUserData(userEditedData){
    return axios({
      method:"post",
      url: USER_API_BASE_URL + "/profile/save",
      data: userEditedData
    })
  }
  validateUserEmail(validateEmail){
    return axios({
      method:"post",
      url: USER_API_BASE_URL + "/validate",
      data: validateEmail
    })
  }
  saveAbout(data){
    return axios({
      method:"post",
      url: USER_API_BASE_URL + "/about/save",
      data:data
    })
  }
  uploadPost(userPost){
    return axios({
      method:"post",
      url: POST_MGMT_API_BASE_URL  + "/post",
      data: userPost
    })
  }

  viewFeed(feedType,userProfileId,sessionId,Pageno,pagesize){
    return axios.get(POST_MGMT_API_BASE_URL + "/feed/" + feedType + "/" + userProfileId + "/" + sessionId + "/" + Pageno + "/" + pagesize);
  }

  viewPosts(userProfileId,passion,viewerProfileId,sessionId,Pageno,pagesize){
    return axios.get(POST_MGMT_API_BASE_URL + "/view/posts/" + userProfileId + "/" + passion + "/" + viewerProfileId + "/" + sessionId + "/" + Pageno + "/" + pagesize);
  }

  viewUserPost(postId,sessionId,userProfileId){
    return axios.get(POST_MGMT_API_BASE_URL + "/view/post/" + postId + "/" + sessionId + "/" + userProfileId)
  }

  youtubeThumbs(videodata){
    return axios({
      method:"post",
      url: POST_MGMT_API_BASE_URL  + "/youtube/thumbnails",
      data: videodata
    })
  }

  PostComment(commentpostdata){
    return axios({
      method:"post",
      url: POST_MGMT_API_BASE_URL  + "/comment",
      data: commentpostdata
    })
  }
  ViewComments(userProfileId,postId,pageNo,pageSize){
    return axios.get(POST_MGMT_API_BASE_URL + "/lookup/comments/" + userProfileId + "/" + postId + "/" + pageNo + "/" + pageSize)

  }
  ViewLikes(userProfileId,postId,pageNo,pageSize){
    return axios.get(POST_MGMT_API_BASE_URL + "/lookup/likes/" + userProfileId + "/" + postId + "/" + pageNo + "/" + pageSize)

  }
  PostLike(postId,userProfileId,sessionId){
    return axios.post(POST_MGMT_API_BASE_URL+ "/like/" + postId + "/" + userProfileId + "/" + sessionId)

  }
  PostUnLike(postId,userProfileId,sessionId){
    return axios.post(POST_MGMT_API_BASE_URL+ "/unlike/" + postId + "/" + userProfileId + "/" + sessionId)

  }
  FollowUser(followdata){
    return axios({
      method:"post",
      url: USER_API_BASE_URL  + "/follow" ,
      data: followdata
    })
  }
  UnFollowUser(followdata){
    return axios({
      method:"post",
      url: USER_API_BASE_URL  + "/unfollow" ,
      data: followdata
    })
  }

  GetFollowers(viewerId,userProfileId,sessionId){
    return axios.get(USER_API_BASE_URL+ "/followers/" + viewerId + "/" + userProfileId + "/" + sessionId)

  }
  GetFollowing(viewerId,userProfileId,sessionId){
    return axios.get(USER_API_BASE_URL+ "/following/" + viewerId + "/" + userProfileId + "/" + sessionId)

  }

  NotificationClick(notificationId,userProfileId,sessionId){
    return axios.get(USER_API_BASE_URL+"/view/notification/" + notificationId + "/" + userProfileId + "/" + sessionId)
  }

  DeletePost(postId,userProfileId,sessionId){
    return axios.get(POST_MGMT_API_BASE_URL + "/delete/post/" + postId + "/" + userProfileId + "/" + sessionId)
  }

  DeleteProfile(phototype,userProfileId,sessionId){
    return axios.get(POST_MGMT_API_BASE_URL+"/delete/image/" + phototype + "/" + userProfileId + "/" + sessionId)
  }

  Logout(userdata){
    return axios({
      method:"post",
      url: USER_API_BASE_URL  + "/logout" ,
      data: userdata
    })
  }

  EditPost(postdata){
    return axios({
      method:"post",
      url:POST_MGMT_API_BASE_URL + "/update/post",
      data:postdata
    })
  }

  Search(mode,searchText,sessionId,userProfileId){
    return axios.get(USER_API_BASE_URL + "/search/userprofile/" + mode + "/" + searchText + "/" + sessionId + "/" + userProfileId)
  }
 

  DeleteComment(commentId,userProfileId,sessionId){
    return axios.get(POST_MGMT_API_BASE_URL + "/delete/comment/" + commentId + "/" + userProfileId + "/" + sessionId)
  }
 
  DeleteNotification(notificationId,userProfileId,sessionId){
    return axios.get(POST_MGMT_API_BASE_URL + "/delete/notification/" + notificationId + "/" + userProfileId + "/" + sessionId)
  }

  ViewNotification(userProfileId,sessionId){
    return axios.get(NOTIFICATION_MGMT_BASE_URL + "/unseen/" + userProfileId + "/" + sessionId)
  }

  AllNotification(userProfileId,sessionId,page,per){
    return axios.get(NOTIFICATION_MGMT_BASE_URL + "/all/" + userProfileId + "/" + sessionId + "/"+ page + "/" + per)
  }
  
  ForgotPassword(userid,useremail){
    return axios.get(USER_API_BASE_URL + "/forgot/password/" + userid + "/" + useremail)
  }

  ResetPassword(body){
    return axios({
      method:"post",
      url:USER_API_BASE_URL + "/change/password",
      data:body
    })
  }

  GetDistrict(state){
    return axios.get(USER_API_BASE_URL + "/geoLocation/fetch/India/" + state)
  }

  ReportComplaint(body){
    return axios({
      method:"post",
      url:USER_API_BASE_URL + "/add/grievance",
      data:body
    })
  }

  RemoveFollower(body){
    return axios({
      method:"post",
      url:USER_API_BASE_URL + "/delete/follower",
      data:body
    })
  }

  ShowPopularArtists(state,district,userid,sessionid){
    return axios.get(USER_API_BASE_URL + "/" + state + "/" + district + "/" + userid + "/" + sessionid + "/popularface")

  }
  // Admin apis

  LoadComplaints(pagenum,pagesize){
    return axios.get(ADMIN_API_BASE_URL + "list/grievances/" + pagenum + "/" + pagesize)
  
  }
  LoadDeactivatedPosts(pagenum,pagesize){
    return axios.get(ADMIN_API_BASE_URL + "list/posts/deactivated/" + pagenum + "/" + pagesize)
  
  }
  SearchbyPost(postid){
    return axios.get(POST_MGMT_API_BASE_URL + "/admin/view/post/" + postid)

  }
  SearchbyProfile(text){
    return axios.get("https://service1.famenet.in/fameplus-user-profile-service/admin/search/userprofile/" + text)
  }

  ProfileAdminView(data){
    return axios.post(
     
      "https://service1.famenet.in/fameplus-user-profile-service/admin/view/profile/" + data ,
    
    )
  }
  PostsAdminView(userprofileid,passion,pageno,pagesize){
    return axios.get(POST_MGMT_API_BASE_URL + "/view/posts/admin/" + userprofileid + "/" + passion + "/" + pageno + "/" + pagesize)

  }

  // blocking

  BlockUser(data){
    return axios({
      method:"post",
      url:USER_API_BASE_URL + "/block",
      data:data
    })
  }

  FetchBlockedUsers(userprofileid,sessionid){
    return axios.get(USER_API_BASE_URL + "/view/blocked/" + userprofileid + "/" + sessionid )

  }

  UnblockUser(blockid,userprofileid,sessionid){
    return axios.post(USER_API_BASE_URL + "/unblock/" + blockid + "/" + userprofileid + "/" + sessionid,
     
    )
  }

  DeactPost(postId,action){
    return axios.get("https://service1.famenet.in/fameplus-user-profile-service/admin/" + postId + "/" + action)
  }

  SearchBySkill(data){
    return axios({
      method:"post",
      url:USER_API_BASE_URL + "/view/artists",
      data:data
    })
  }


  // booker

  BookerSignup(body){
    return axios({
      method:"post",
      url: "https://service1.famenet.in/fameplus-user-profile-service/booker/register",
      data: body
    })
  }
  SearchArtist(body){
    return axios({
      method:"post",
      url: "https://service1.famenet.in/fameplus-user-profile-service/booker/view/userprofiles",
      data: body
    })
  }
  SearchArtistTags(search){
    return axios.get("https://service1.famenet.in/fameplus-user-profile-service/booker/fetch/skillTags/" + search
    )
  }
  BookerLogin(body) {
    return axios({
      method: "post",
      url: "https://service1.famenet.in/fameplus-user-profile-service/booker/login",
      data: body,
    });
  }

  BookingArtist(body) {
    return axios({
      method: "post",
      url: "https://service1.famenet.in/fameplus-user-profile-service/booker/booking/add",
      data: body,
    });
  }
  
  BookingList(id){
    return axios.get("https://service1.famenet.in/fameplus-user-profile-service/booker/booking/list/" + id)

  }
  



 
  
}

export default new ApiService();
