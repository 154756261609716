import React, { Component } from "react";
import { MDBCardBody, MDBInput } from "mdbreact";
import ApiService from "../service/ApiService";
import { connect } from "react-redux";

class ChangePassword extends Component {
  constructor(props) {
    super();
    this.state = {
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
      passwordchanged: null,
      isError: {
        newpassword: false,
        confirmpassword: false,
      },
      errorMessage: {
        newpassword: "",
        confirmpassword: "",
      },
    };
  }
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };
  toggleShowone = () => {
    this.setState({
      hiddenone: !this.state.hiddenone,
    });
  };
  toggleShowtwo = () => {
    this.setState({
      hiddentwo: !this.state.hiddentwo,
    });
  };
  toggleShowthree = () => {
    this.setState({
      hiddenthree: !this.state.hiddenthree,
    });
  };
  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };
  submitPassword = (e) => {
    e.preventDefault();
    this.setState({
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
      isError: {
        newpassword: false,
        confirmpassword: false,
      },
      errorMessage: {
        newpassword: "",
        confirmpassword: "",
      },
    });
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const userProfileId = this.props.LoginData.loginSuccesData.userProfileId;
    let body = {
      oldPassword: this.state.currentpassword,
      newPassword: this.state.newpassword,
      userProfileId: userProfileId,
      sessionId: cookieValue,
    };
    ApiService.ResetPassword(body).then((res) => {
      if (res.data.status !== "success") {
        this.setState({
          passwordchanged: false,
        });
      } else if (res.data.status === "success") {
        this.setState({
          passwordchanged: true,
        });
      }
    });
  };
  validatePassword = () => {
    var passw = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    );
    if (this.state.newpassword.length < 5) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          newpassword: "Type your Password",
        },
        isError: { ...this.state.isError, newpassword: false },
      });
    } else if (!passw.test(this.state.newpassword)) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          newpassword:
            "Please use atleast one uppercase, one lowercase, one number and one special character",
        },
        isError: { ...this.state.isError, newpassword: false },
      });
    } else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          newpassword: "Password is valid",
        },
        isError: { ...this.state.isError, newpassword: true },
      });
    }
  };

  MatchPassword = () => {
    if (this.state.confirmpassword !== this.state.newpassword) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          confirmpassword: "Confirm password and New Password doesn't match",
        },
        isError: { ...this.state.isError, confirmpassword: false },
      });
    } else {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          confirmpassword: "Password Match",
        },
        isError: { ...this.state.isError, confirmpassword: true },
      });
    }
  };
  render() {
    let isEnabled = false;
    if (this.state.isError.newpassword && this.state.isError.confirmpassword) {
      isEnabled = true;
    }

    let passwordchanged;
    if (this.state.passwordchanged === false) {
      passwordchanged = (
        <h5
          className="no-error"
          style={{ padding: "0 0 15px 0", fontWeight: "bold" }}
        >
          <b>Wrong Current Password</b>
        </h5>
      );
    } else if (this.state.passwordchanged === true) {
      passwordchanged = (
        <h5
          className="error-signup"
          style={{ padding: "0 0 15px 0", fontWeight: "bold" }}
        >
          <b>Password Changed Successfully</b>
        </h5>
      );
    } else {
      passwordchanged = <></>;
    }

    return (
      <div className="editpro">
        <MDBCardBody>
          <div className="text-center">
            <h3 className="mb-5">
              <strong>Change Password</strong>
            </h3>
          </div>
          {passwordchanged}
          <MDBInput
            label="Current Password"
            icon="user"
            group
            name="currentpassword"
            type={this.state.hiddenone ? "text" : "password"}
            value={this.state.currentpassword}
            onChange={this.handleChange("currentpassword")}
          >
            {this.state.hiddenone ? (
              <i
                toggle="#input-pwd"
                className="fa fa-eye field-icon toggle-password"
                icon="pencil"
                onClick={this.toggleShowone}
              ></i>
            ) : (
              <i
                toggle="#input-pwd"
                className="fa fa-eye-slash field-icon toggle-password"
                icon="pencil"
                onClick={this.toggleShowone}
              ></i>
            )}
          </MDBInput>

          <MDBInput
            label="New Password"
            icon="lock"
            group
            type="text"
            validate
            name="newpassword"
            type={this.state.hiddentwo ? "text" : "password"}
            value={this.state.newpassword}
            onChange={this.handleChange("newpassword")}
            onBlur={this.validatePassword}
          >
            {this.state.hiddentwo ? (
              <i
                toggle="#input-pwd"
                className="fa fa-eye field-icon toggle-password"
                icon="pencil"
                onClick={this.toggleShowtwo}
              ></i>
            ) : (
              <i
                toggle="#input-pwd"
                className="fa fa-eye-slash field-icon toggle-password"
                icon="pencil"
                onClick={this.toggleShowtwo}
              ></i>
            )}
          </MDBInput>

          <div
            className={`no-error${
              this.state.isError.newpassword ? " error-signup" : ""
            }`}
            style={{ marginTop: "0" }}
          >
            {this.state.errorMessage.newpassword}
          </div>

          <MDBInput
            label="Confirm Password"
            icon="lock"
            group
            type="text"
            validate
            name="confirmpassword"
            type={this.state.hiddenthree ? "text" : "password"}
            value={this.state.confirmpassword}
            onChange={this.handleChange("confirmpassword")}
            onBlur={this.MatchPassword}
          >
            {this.state.hiddenthree ? (
              <i
                toggle="#input-pwd"
                className="fa fa-eye field-icon toggle-password"
                icon="pencil"
                onClick={this.toggleShowthree}
              ></i>
            ) : (
              <i
                toggle="#input-pwd"
                className="fa fa-eye-slash field-icon toggle-password"
                icon="pencil"
                onClick={this.toggleShowthree}
              ></i>
            )}
          </MDBInput>

          <div
            className={`no-error${
              this.state.isError.confirmpassword ? " error-signup" : ""
            }`}
            style={{ marginTop: "0" }}
          >
            {this.state.errorMessage.confirmpassword}
          </div>
          <div className="form-over-border"></div>
          <div className="text-center mb-4 md-12">
            <button
              type="submit"
              onClick={this.submitPassword}
              className="btn-login z-depth-1a btn-block"
              disabled={!isEnabled}
            >
              Save Changes
            </button>
          </div>
        </MDBCardBody>
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(ChangePassword);
