import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import ChangePassword from "./changepassword";
import EditUserDetail from "./editUserDetail";
import BlockingSearch from "./blocking";

class EditProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
      activeIndex: 0,
      fullName: "",
      userName: "",
      bio: "",
      emailId: "",
      skillTags: "",
      passion: [],
      loading: true,
      charLimit: 30,

      isError: {
        fullName: true,
        userName: true,
        emailId: true,
      },
      errorMessage: {
        fullName: "",
        userName: "",
        emailId: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.location.ActiveTab) {
      this.setState({
        activeIndex: this.props.location.ActiveTab,
      });
    } else {
      this.setState({
        activeIndex: 0,
      });
    }
  }

  handleChange = (_, activeIndex) => this.setState({ activeIndex });
  render() {
    const { activeIndex } = this.state;

    return (
      <div className="container-fluid profile-bg">
        <MDBRow>
          <MDBCol
            md="8"
            className="mx-auto px-0"
            style={{ background: "white", minHeight: "100vh" }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <VerticalTabs
                value={activeIndex}
                onChange={this.handleChange}
                className="editprotabs"
              >
                <MyTab label="Edit Profile" className="tab-vertical edit-tab" />
                <MyTab
                  label="Change Password"
                  className="tab-vertical edit-tab"
                />
                <MyTab
                  label="Block Account"
                  className="tab-vertical edit-tab"
                />
              </VerticalTabs>

              {activeIndex === 0 && (
                <TabContainer className="editpro">
                  <EditUserDetail />
                </TabContainer>
              )}
              {activeIndex === 1 && (
                <TabContainer>
                  <ChangePassword />
                </TabContainer>
              )}
              {activeIndex === 2 && (
                <TabContainer>
                  <BlockingSearch />
                </TabContainer>
              )}
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

const VerticalTabs = withStyles((theme) => ({
  flexContainer: {
    flexDirection: "column",
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

const MyTab = withStyles((theme) => ({
  selected: {
    color: "#b81b21",
    borderBottom: "2px solid #b81b21",
  },
}))(Tab);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 5 }}>
      {props.children}
    </Typography>
  );
}

export default EditProfile;
