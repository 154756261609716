import React, { Component } from "react";
import CameraEnhanceIcon from "@material-ui/icons/CameraEnhance";
import VideoCallIcon from "@material-ui/icons/VideoCall";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Slide } from "@material-ui/core";
import { NavLink } from "react-router-dom";


export default class PostType extends Component {
  constructor(props) {
    super();
    this.state = {
      base64TextString:'',
      filename:''
    }
  }
  handleImageChange = (e) => {
    sessionStorage.removeItem('videourl')
    sessionStorage.removeItem('passion')
    let file = e.target.files[0]
    this.setState({
      filename: e.target.files[0].name
   })
    if(file){
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this)
      reader.readAsBinaryString(file)
     
      
    }
  }

  _handleReaderLoaded = (readerEvt) =>{
    let binaryString = readerEvt.target.result
    this.setState({
      base64TextString: btoa(binaryString)
    })
    localStorage.setItem("image", JSON.stringify(this.state.base64TextString));
    localStorage.setItem("image-name", this.state.filename)
      // this.props.history.push('/post-detail');
      window.location = '/post-detail';
      
  }

  render() {
     
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.props.open}
        onClose={this.props.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
        }}
      >
        <Slide direction="down" in={this.props.open}>
          <div className="upload-post">
            <button 
            // onClick={this.props.close}
            >

              <CameraEnhanceIcon />
              <form onSubmit={(e)=>this.onFileSubmit(e)} id="imageform">
              <input
                type="file"
                accept="image/*"
                onChange={(e)=>this.handleImageChange(e)}
                name="image"
              />
            
              </form>
             
            </button>
            {/* <button type="submit" onClick={() =>
            document
              .getElementById('imageform')
              .dispatchEvent(new Event('submit', { cancelable: true }))
          }>submit</button> */}
           
            <NavLink to={"/select-passion"} 
            onClick={this.props.close}
            >
              <VideoCallIcon />
            </NavLink>
          </div>
        </Slide>
      </Modal>
    );
  }
}
