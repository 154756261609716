import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBInput,
  MDBTooltip,
  MDBDataTable,
} from "mdbreact";
import "./booker.css";
import ApiService from "../service/ApiService";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

class BookingList extends Component {
  constructor() {
    super();
    this.state = {
      bookings: [],
      loading: true,
    };
  }

  componentDidMount() {
    let booker_id =
      this.props.LoginData?.bookerlogin && this.props.LoginData.bookerlogin[1];
    this.loadBookings(booker_id);
  }

  loadBookings = (id) => {
    ApiService.BookingList(id).then((res) => {
      this.setState({
        bookings: res.data,
        loading: false,
      });
    });
  };

  render() {
    return (
      <div style={{ background: "#ebebeb",width:"100%" }}>
        <MDBContainer className="booking-list-cont">
          <h4 className="text-center pb-2">Your Bookings</h4>
          <div className="acc-block booking-accordion">
            {this.state.bookings && this.state.bookings.length > 0 ? (
              this.state.bookings.map((booking, i) => {
                return (
                  <Accordion className="booking-list-dtl ">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="booking-id">
                        {booking.userProfileId}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <h5>
                          <b>Quotation : </b>
                          {booking.quotaion}
                        </h5>
                        <h5>
                          <b>Requirement : </b>
                          {booking.requirement}
                        </h5>
                        <h5>
                          <b>Accepted Amount : </b>
                          {booking.acceptedAmt}
                        </h5>
                        <h5>
                          <b>Date : </b>
                          {booking.date}
                        </h5>
                        <h5>
                          <b>status : {booking.status}</b>
                        </h5>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              <h3>No data</h3>
            )}
          </div>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    LoginData: state.LoginData,
  };
};

export default connect(mapStateToProps)(BookingList);
