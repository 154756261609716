import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
    constructor(props) {
        super();
    }
    render() {
        return(
            <div className="container-fluid" style={{minHeight:"80vh",background:"#efefef",textAlign:"center"}}>
            <h1 className="text-center" style={{margin:"12vh 0 2vh 0"}}>404 Not found</h1>
            <h4 className="text-center">This page doesn't exist</h4>
            <Link to="/" style={{color:"#b81b21"}}>Go Back</Link>
            </div>
        )
    }

}