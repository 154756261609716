import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ApiService from "../service/ApiService";
import { Link } from "react-router-dom";
import artistSearch from "../images/search-talent.png";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Drawer from "@material-ui/core/Drawer";
import Select from "react-select";
import "./skillSearch.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import SelectFun from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import { MenuOpen } from "@material-ui/icons";
import { connect } from "react-redux";

const handleDragStart = (e) => e.preventDefault();
const animatedComponents = makeAnimated();
class SearchSkill extends Component {
  constructor(props) {
    super();

    this.state = {
      openSide: false,
      citiesList: [],
      skill: "",
      state: "none",
      city: "none",
      totalResults: 500,

      searchResult: [],
      pageNum: 0,
      pageSize: 100,
      totalPages: null,
      skillsResult: "",
      inputValue: "",
    };
  }

  toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      openSide: !this.state.openSide,
    });
  };

  handleChangeState = (e) => {
    this.setState({
      state: e.target.value,
    });
    ApiService.GetDistrict(e.target.value).then((res) => {
      this.setState({
        citiesList: res.data,
      });
    });
  };
  handleDistChange = (e) => {
    this.setState({
      city: e.target.value,
    });
  };

  componentDidMount() {
    this.handleSearch(this.props.location.state.search);

    // this.scrollListener = window.addEventListener("scroll", (e) => {
    //   this.handleScroll(e);
    // });
    this.setState({
      skillsResult: this.props.location.state.search,
    });
  }

  // handleScroll = (e) => {
  //   const { scrolling, totalPages, pageNum } = this.state;
  //   if (scrolling) return;
  //   if (totalPages <= pageNum) return;
  //   const lastdiv = document.querySelector(".all-res > .res-box:last-child");
  //   if (lastdiv != null && totalPages > 1) {
  //     const lastdivoffset = lastdiv.offsetTop + lastdiv.clientHeight;
  //     const pageOffset = window.pageYOffset + window.innerHeight;
  //     var bottomOffset = 20;
  //     if (pageOffset > lastdivoffset - bottomOffset) this.loadmorePosts();
  //   }
  // };

  loadmorePosts = () => {
    if (this.state.pageNum === 0) {
      return null;
    } else {
      this.setState(
        (prevState) => ({
          pageNum: prevState.pageNum + 1,
        }),
        this.handleSearch(this.state.skillsResult)
      );
   
    }
  };
  handleSearch = (skilltag) => {
    // e.preventDefault();
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId
    const userProfileId = this.props.LoginData.loginSuccesData.userProfileId;

    let body = {
      userProfileId: userProfileId,
      sessionId: cookieValue,
      skill: skilltag,
      state: this.state.state,
      district: this.state.city,
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
    };

    ApiService.SearchBySkill(body).then((res) => {
      // let totalp = this.state.totalResults / this.state.pageSize;
      let totalp = res.data.dataItemslist.length / this.state.pageSize;
      this.setState({
        searchResult: res.data.dataItemslist,
        totalPages: totalp,
      });
    });
  };

  handleInputChange = (value) => {
    this.setState({
      inputValue: value,
    });
   
  };
  handleValueChange = (value) => {
    this.setState({
      skillsResult: value.skillTagDescription,
    });
    this.handleSearch(value.skillTagDescription);
  };
  loadOptions = () => {
    return ApiService.SearchArtistTags(this.state.inputValue).then((res) => {
      return res.data;
    });
  };
  render() {
    const { activeIndex } = this.state;
    let enabled = false;
    if (
      this.state.skillsResult &&
      this.state.skillsResult.length > 0 &&
      this.state.state &&
      this.state.city
    ) {
      enabled = true;
    }
    return (
      <div className="profile-bg">
        <MDBContainer className="search-art-cont search-art-skill-cont">
          {/* Search Artist */}
          {this.state.openSide && (
            <>
              <div className="search-artist">
                <div className="row mr-0 ml-0">
                  <div className="col-md-4">
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={this.state.selectedValue}
                      getOptionLabel={(e) => e.skillTagDescription}
                      getOptionValue={(e) => e.skillTagId}
                      loadOptions={this.loadOptions}
                      onInputChange={this.handleInputChange}
                      onChange={this.handleValueChange}
                      placeholder="Type something here"
                      styles={{
                        indicatorSeparator: () => {}, // removes the "stick"
                        dropdownIndicator: (defaultStyles) => ({
                          ...defaultStyles,
                          display: "none", // your changes to the arrow
                        }),
                      }}
                      className="mt-3 select-search-skill"
                    />
                    <pre></pre>
                  </div>
                  <div className="col-md-3 pl-0">
                    <FormControl className="select-dis-search">
                      <InputLabel htmlFor="age-native-simple">State</InputLabel>
                      <SelectFun
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={this.handleChangeState}
                        value={this.state.state}
                      >
                        <MenuItem value="Andhra-Pradesh">
                          Andhra Pradesh
                        </MenuItem>
                        <MenuItem value="Arunachal-Pradesh">
                          Arunachal Pradesh
                        </MenuItem>
                        <MenuItem value="Assam">Assam</MenuItem>
                        <MenuItem value="Bihar">Bihar</MenuItem>
                        <MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
                        <MenuItem value="Goa">Goa</MenuItem>
                        <MenuItem value="Gujarat">Gujarat</MenuItem>
                        <MenuItem value="Himachal-Pradesh">
                          Himachal Pradesh
                        </MenuItem>
                        <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                        <MenuItem value="Karnataka">Karnataka</MenuItem>
                        <MenuItem value="Kerala">Kerala</MenuItem>
                        <MenuItem value="Madhya-Pradesh">
                          Madhya Pradesh
                        </MenuItem>
                        <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                        <MenuItem value="Manipur">Manipur</MenuItem>
                        <MenuItem value="Meghalaya">Meghalaya</MenuItem>
                        <MenuItem value="Mizoram">Mizoram</MenuItem>
                        <MenuItem value="Nagaland">Nagaland</MenuItem>
                        <MenuItem value="Odisha">Odisha</MenuItem>
                        <MenuItem value="Puducherry">Puducherry</MenuItem>
                        <MenuItem value="Punjab">Punjab</MenuItem>
                        <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                        <MenuItem value="Sikkim">Sikkim</MenuItem>
                        <MenuItem value="Tamil-Nadu">Tamil Nadu</MenuItem>
                        <MenuItem value="Telangana">Telangana</MenuItem>
                        <MenuItem value="Uttar-Pradesh ">
                          Uttar Pradesh
                        </MenuItem>
                        <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                        <MenuItem value="West-Bengal">West Bengal</MenuItem>
                        <MenuItem value="Andaman-Nicobar-Islands">Andaman and Nicobar Islands</MenuItem>
                              <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                              <MenuItem value="Dadra-Nagar-Haveli-Daman-Diu">Dadra and Nagar Haveli and Daman and Diu</MenuItem>
                              <MenuItem value="Delhi">Delhi</MenuItem>
                              <MenuItem value="Ladakh">Ladakh</MenuItem>
                              <MenuItem value="Lakshadweep">Lakshadweep</MenuItem>
							                <MenuItem value="Puducherry">Puducherry</MenuItem>
                      </SelectFun>
                    </FormControl>
                  </div>
                  <div className="col-md-3 pl-0">
                    <FormControl className="select-dis-search">
                      <InputLabel htmlFor="age-native-simple">
                        District
                      </InputLabel>
                      <SelectFun
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={this.handleDistChange}
                        value={this.state.city}
                        className="select-dis-search"
                        placeholder="District"
                      >
                        {this.state.citiesList.map((districts, i) => (
                          <MenuItem value={districts} key={i}>
                            {districts}
                          </MenuItem>
                        ))}
                      </SelectFun>
                    </FormControl>
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn-login z-depth-1a btn-block mt-3 pt-2 pb-2"
                      onClick={this.handleSearch(this.state.skillsResult)}
                      disabled={!enabled}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row mr-0 ml-0">
            <div className="search-skill-art col-md-12">
              <h4 className="text-left">
                Showing results for {this.state.skillsResult}
              </h4>
              <div className="find-artist">
                <button onClick={this.toggleDrawer()}>
                  <MenuOpen />
                </button>
              </div>
            </div>
          </div>

          <div className="row mr-0 ml-0 all-res">
            {this.state.searchResult &&
              this.state.searchResult.length > 0 ?
              this.state.searchResult.map((profile) => {
                return (
                  <div className="col-md-4 col-sm-6 col-6 res-box">
                    <div className="skill-search-result">
                      {profile.profilePic !== null ? (
                        <img src={profile.profilePic} />
                      ) : (
                        <img src="./user-img.png"></img>
                      )}

                      <h4>{profile.userName}</h4>
                      <ul className="skl-tags">
                        {profile.skillTags &&
                          profile.skillTags.length > 0 &&
                          profile.skillTags.map((skills) => {
                            return <li>{skills}</li>;
                          })}
                      </ul>
                      <Link
                        to={{
                          pathname: `/default-profile/${profile.userProfileId}`,
                          state: profile.userProfileId,
                        }}
                      >
                        view profile
                      </Link>
                    </div>
                  </div>
                );
              }):(<div className="empty-skill"><h4>No profile matching this critaria</h4></div>)}
           
          </div>
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(SearchSkill);
