import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Link } from "react-router-dom";
import ApiService from "../service/ApiService";
import "./auth.css";
const animatedComponents = makeAnimated();

export default class Skills extends Component {
  constructor(props) {
    super();
    this.state = {
      selectOptions: [],
      value: [],
    };
  }
  componentDidMount() {
    if (localStorage.getItem("userform" && "passion")) {
      this.getOptionsCall();
    } else {
      this.props.history.push("/feed");
    }
  }

  async getOptionsCall() {
    let responseBody = new Array([]);
    let savedskills = localStorage.getItem("passion");
    var fSkills = JSON.parse(savedskills.toUpperCase());
    ApiService.getSkillTags(fSkills).then((res) => {
      responseBody = res.data;
      const options = responseBody.map((d) => ({
        value: d.skillTagId,
        label: d.skillTagDescription,
      }));

      this.setState({ selectOptions: options });
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    if (localStorage.getItem("skills")) {
      let newskills = this.state.value;
      localStorage.setItem("skills", JSON.stringify(newskills));
      this.props.history.push("/thankyou");
    }
  }

  handleChange(e) {
    this.setState({ value: e });
  }
   handleCancel(e){
    e.preventDefault();
    localStorage.clear('userform');
    this.props.history.push("/")
  }
  

  render() {
    const mySkills =
      this.state.value === null ? "" : this.state.value.map((v) => v.label);
    localStorage.setItem("skills", JSON.stringify(mySkills));
    let isEnabled = true
   if ( this.state.value === null || this.state.value.length < 1){
     isEnabled =true
   }
   else{
     isEnabled=false
   }
    
    // handle onChange event of the dropdown

    return (
      <div className="container-fluid category-bg">
        <div className="row">
          <h2>Select Your Skills</h2>
          <h4>{this.state.skilltags}</h4>
          <div className="upload-img">
            <img src={require("../images/tags.png")} alt="upload-video" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-3 mb-5 col-sm-10 mx-auto">
            <form>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                // defaultValue={[colourOptions[0], colourOptions[1]]}
                isMulti
                onChange={this.handleChange.bind(this)}
                options={this.state.selectOptions}
                styles={{
                  indicatorSeparator: () => {}, // removes the "stick"
                  dropdownIndicator: (defaultStyles) => ({
                    ...defaultStyles,
                    display: "none", // your changes to the arrow
                  }),
                }}
              />

              <div
                className="video-thumb"
                style={{ marginBottom: "120px" }}
              ></div>
              <div className="row upload-controls">
                <div className="col-md-2 col-sm-9">
                  <button value="cancel" className="button-cancel">
                    <Link to="/" onClick={this.handleCancel.bind(this)}>Cancel</Link>
                  </button>
                </div>

                <div className="col-md-1 offset-md-7 col-sm-1">
                  <button value="back" className="button-cancel">
                    <Link to="/category">Back</Link>
                  </button>
                </div>
                <div className="col-md-2 col-sm-2">
                  <button
                    value="Next"
                    className="btn-login"
                    onClick={this.handleSubmit.bind(this)}
                    disabled={isEnabled}
                  >
                    <Link to="/post-detail">Next</Link>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
