import { BOOKERLOGIN, BOOKERREGISTER, LOGIN, LOGIN_ERROR, LOGOUT, USERREGISTER } from "../constants";
const initialState = {
  loginSuccesData: {},
  loginErrorData: {},
  isadmin: null,
  bookerlogin: {},
};
const LoginData = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return {
        ...state,
        loginErrorData: action.payload,
      };

    case LOGIN:
      return {
        ...state,
        loginSuccesData: action.payload.data,
        isadmin: action.payload.data.isAdmin,
        
      };
      case USERREGISTER:
      return {
        ...state,
        loginSuccesData: action.payload.data,
      };
    case BOOKERLOGIN:
      return {
        ...state,
        bookerlogin: action.payload.data,
        isadmin: action.payload.data.isAdmin,
      };

      case BOOKERREGISTER:
          return{
              ...state,
              bookerlogin: action.payload.data,
          }
    case LOGOUT:
     
      return {
        initialState,
      };

    default:
      return state;
  }
};

export default LoginData;
