import React, { Component } from "react";
import { connect } from "react-redux";
import ApiService from "../service/ApiService";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Link } from "react-router-dom";


class NotificationPage extends Component {
  constructor(props) {
    super();
    this.state = {
      notificationlist: [],
      notificationlength: null,
      notifPerPage: 10,
      notifPageSize: 0,
      notifTotalPages: null,
      totalnumberofnotifications: null,
    };
  }
  componentDidMount() {
    this.fetchAllNotifications();
  }

  fetchAllNotifications = () => {
    const { notifPerPage, notifPageSize, notificationlist } = this.state;
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.AllNotification(
      viewerId,
      cookieValue,
      notifPageSize,
      notifPerPage
    ).then((res) => {
      if (res.data.status === "success" && res.data.dataItemslist.length > 0) {
        let nlist = res.data.dataItemslist;
        // let numberlist = res.data.dataItemslist.length;
        let totalnumberofnotifications = parseInt(
          res.data.dataItemslist[0].totalNoOfNotifications
        );

        this.setState({
          notificationlist: [...notificationlist, ...nlist],
          notificationlength: totalnumberofnotifications,
        });
      }
    });
  };
  loadmoreNotif = () => {
    this.setState(
      (prevState) => ({
        notifPageSize: prevState.notifPageSize + 1,
      }),
      this.fetchAllNotifications
    );
  };

  toggleState = (id) => {
    let updatedList = this.state.notificationlist.map((x) => {
      if (x.notification.notificationId === id) {
        if (x.notification.active !== true) {
          // let notificationupdate = this.state.notificationlength - 1;
          // this.props.sendNumberToParent(notificationupdate);
          this.setState({
            notificationlength: this.state.notificationlength - 1,
          });
          const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
          const userProfile = this.props.LoginData.loginSuccesData.userProfileId;
          ApiService.NotificationClick(
            x.notification.notificationId,
            userProfile,
            cookieValue
          ).then((res) => {});
        }
      }
      if (x.notification.notificationId === id) {
        x.notification.active = true;
      }
      return x;
    });

    this.setState({
      notificationlist: updatedList,
    });
  };
  onClick = (id) => {
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.DeleteNotification(id, viewerId, cookieValue).then((res) => {
      if (res.data.status === "success") {
        this.setState(
          {
            notificationlist: [],
          },
          this.fetchAllNotifications
        );
      }
    });
  };
  render() {
    return (
      <div className="container-fluid grey-bg">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="white-bg main-notifs">
              <h2>Notifications</h2>
              <ul id="notification-list">
                {this.state.notificationlength !== null ? (
                  this.state.notificationlist.map((x, index) => {
                    var str = x.notification.notificationMessage;
                    var removepost = str.replace("post", "");

                    var word = [removepost];
                    word.push(
                      <Link
                        className="notif-post-link"
                        key={index}
                        to={{
                          pathname: `/viewpost`,
                          state: x.notification.postId,
                        }}
                      >
                        post
                      </Link>
                    );
                    var linkinsertion = word;

                    let classNames = ["notificationread"];

                    if (x.notification.active) {
                      classNames.push("active");
                    }
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this.toggleState(x.notification.notificationId)
                        }
                      >
                        {x.notification.notificationFromUserImg === null ? (
                          <img src="/user-img.png" alt=""></img>
                        ) : (
                          <img
                            src={x.notification.notificationFromUserImg}
                            className="img-fluid"
                            alt="#"
                          />
                        )}

                        <div>
                          <p className="main-title text-left">
                            {/* {x.notification.notificationMessage} */}
                            {x.notification.notificationMessage.includes("post")
                              ? linkinsertion
                              : x.notification.notificationMessage}
                            {/* {linkinsertion} */}
                          </p>
                          <span>{x.notification.timeStamp}</span>
                        </div>

                        <button
                          onClick={() =>
                            this.onClick(x.notification.notificationId)
                          }
                        >
                          Delete
                        </button>
                      </li>
                    );
                  })
                ) : (
                  <p className="no-notif">No notifications to show</p>
                )}
              </ul>
              {this.state.notificationlength ===
              this.state.notificationlist.length ? (
                <></>
              ) : (
                <button onClick={this.loadmoreNotif} className="load-more">
                  <AddCircleOutlineIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    LoginData: state.LoginData,
  };
};

export default connect(mapStateToProps)(NotificationPage);
