
import {BookerLogin, BookerRegister, Login, RegisterUser} from "../axios/userLoginApiService";
import { BOOKERLOGIN, BOOKERREGISTER, LOGIN ,LOGIN_ERROR, USERREGISTER} from "../constants";

export const LoginActionFn = (data) => {
  return (dispatch) => {
    Login(data)
      .then((response) => {
      
        if (response && response.status && response.status === 200) {
          dispatch({ type: LOGIN, payload: response });
        }
      })
      .catch(({ ...error }) => {
        dispatch({ type: LOGIN_ERROR, payload: error.response});

        throw error;
      });
  };
};
export const BookerActionFn = (data) => {
    return (dispatch) => {
      BookerLogin(data)
        .then((response) => {
         
          if (response && response.status && response.status === 200) {
            dispatch({ type: BOOKERLOGIN, payload: response });
          }
        })
        .catch(({ ...error }) => {
          dispatch({ type: LOGIN_ERROR, payload: error.response });
  
          throw error;
        });
    };
  };

  function delayresponse(ms) {
    return function (x) {
      return new Promise((resolve) => setTimeout(() => resolve(x), ms));
    };
  }

  export const RegisterActionFn = (data) => {
      
    return (dispatch) => {
      RegisterUser(data)
      .then(delayresponse(5000))
        .then((response) => {
        
          if (response && response.status && response.status === 200) {
            dispatch({ type: USERREGISTER, payload: response });
          }
        })
        .catch(({ ...error }) => {
          dispatch({ type: LOGIN_ERROR, payload: error.response});
  
          throw error;
        });
    };
  };

  export const BookerRegisterActionFn = (data) => {
      
    return (dispatch) => {
      BookerRegister(data)
        .then((response) => {
        
          if (response && response.status && response.status === 200) {
            dispatch({ type: BOOKERREGISTER, payload: response });
          }
        })
        .catch(({ ...error }) => {
          dispatch({ type: LOGIN_ERROR, payload: error.response});
  
          throw error;
        });
    };
  };

