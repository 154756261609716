import React, { Component } from "react";
import { connect } from "react-redux";
import ApiService from "../service/ApiService";

class AboutMe extends Component {

  state = {
    editMode: false,
    aboutMe: "",
  };

  componentDidMount() {
    this.setState({
      aboutMe: this.props.about,
    });
  }

  sendData = () => {
    this.props.update({
      aboutMe: this.state.aboutMe,
    });
    this.setState({ editMode: false });
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    let data = {
      userProfileId:viewerId,
      sessionId:cookieValue,
      aboutText:this.state.aboutMe
    }
    ApiService.saveAbout(data)
    .then((res)=>{
    
    })
  };

  render() {
    var { editMode } = this.state;
    return (
      <div>
        {this.state.editMode ? (
          <div>
            <textarea
            className="form-control"
              value={this.state.aboutMe}
              onChange={(e) => this.setState({ aboutMe: e.target.value })}
            />
            <button onClick={() => this.sendData()}>Save</button>
          </div>
        ) : (
          <div className="aboutme">
            <p>{this.props.about}</p>
            {this.props.self === "true" && 
             <span onClick={() => this.setState({ editMode: true })}>
             <i className="fa fa-pencil">
 
             </i>
             </span>
            }
           
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(AboutMe);