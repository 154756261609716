import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBInput } from "mdbreact";
import { Link } from "react-router-dom";

export default class Legal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-4 pr-0 pb-4 pl-0">
          <div className="privacy-head">
            <h3>Legal</h3>
            <h4>Privacy Policy</h4>
            <p>
              Famenet is committed to protecting your privacy. We have prepared
              this Privacy Policy to describe to you our practices regarding the
              Personal Data (as defined below) we collect from users of our
              website located at Famenet.in . In addition, this Privacy Policy
              tells you about your privacy rights and how the law protects you.
            </p>
            <p>This website is only for those indiviuals.</p>
            <p>
              It is important that you read this Privacy Policy together with
              any other privacy notice or fair processing notice we may provide
              on specific occasions when we are collecting or processing
              Personal Data about you so that you are fully aware of how and why
              we are using your data. This Privacy Policy supplements the other
              notices and is not intended to override them.
            </p>
          </div>

          
           <h3 className="text-center">Checkout more on <Link to={'./privacy-policy'}  style={{color:"#b81b21"}}>Privacy policy</Link> here </h3>


 <div className="privacy-head">
            <h4>Cookies Policy</h4>
            <p>
            At FameNet, we believe in being clear and open about how we collect and use data related to you.
            For now we donot use cookies in our website .
            As of now once the user logs some piece of data we store in sessionStorage for the seam less experience for the user.
            However we will update this section once we implement cookies in our website .
            </p>
            
          </div>

           <div className="privacy-head">
            <h4>Copyright Policy</h4>
            <p>
            Our Copyright ploicy is straight forward .Users have the complete authority and ownership of the posts they upload inside 
            FameNet ecosystem .However in case of any report abuse or griveancesagainst any post ,FameNet bears the responsibility and the authority
            to take it down or delete it from the platform . Our only intention is to build a healthy eco system for artists dedicated to their art .
            </p>
            
            <h3 className="text-center">In case you need some more help can check our <Link to={'./help'}  style={{color:"#b81b21"}}>Help</Link> section here </h3>
 
          </div>
          </div>
</>
    );
  }
}
