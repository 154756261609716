import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import Button from "@material-ui/core/Button";
// import SwipeableViews from 'react-swipeable-views';
// import DialogTitle from '@material-ui/core/DialogTitle';
import ProfileImage from "./ProfileImage.jsx";
import Logout from "./logout";
import PassionTabs from "./profile-tabs";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ApiService from "../service/ApiService";
import CoverImage from "./CoverImage";
import "./profile.css";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AboutMe from "./AboutArtist";
import { ReplyAllRounded } from "@material-ui/icons";
export default class UserProfileShareable extends Component {
  constructor(props) {
    super();

    this.state = {
      setopen: false,
      fullName: "",
      isFollowingTheUser: "false",
      noOfFollowers: "",
      noOfFollowing: "",
      bio: "",
      passion: [],
      postCount: "",
      posts: [],
      profileImage: "",
      coverPicImage: "",
      skillTags: [],
      userName: "",
      userProfileId: "",
      isSelfProfile: false,
      loading: true,
      followreqfromchild: null,
      viewee: null,
      about: "",
      copySuccess: false,
    };
  }
  componentDidMount() {
    
      this.setState({
        viewee: this.props.match.params.id,
      });
      this.fetchuserProfileAdmin();

      
  }

  fetchuserProfileAdmin = () => {
    // const { id } = this.props.match.params;

    ApiService.ProfileAdminView(this.props.match.params.id).then((res) => {
      this.setState({
        fullName: res.data.dataItem.fullName,
        userName: res.data.dataItem.userName,
        noOfFollowers: res.data.dataItem.noOfFollowers,
        noOfFollowing: res.data.dataItem.noOfFollowing,
        passion: res.data.dataItem.passion,
        postCount: res.data.dataItem.postCount,
        posts: res.data.dataItem.posts,
        profileImage: res.data.dataItem.profileImage,
        skillTags: res.data.dataItem.skillTags,
        bio: res.data.dataItem.bio,
        coverPicImage: res.data.dataItem.coverPicImage,
        isSelfProfile: res.data.dataItem.isSelfProfile,
        loading: false,
        isFollowingTheUser: res.data.dataItem.isFollowingTheUser,
        about:res.data.dataItem.about
      });
    });
  };

  

  getDelPost = (del) => {
   
    if (del === true) {
      let noOfPosts = new Number(this.state.postCount);
      this.setState({
        postCount: noOfPosts - 1,
      });
    }
  };

  updateAbout = (updatedAbout) => {
    this.setState({
      about: updatedAbout.aboutMe,
    });
  };

 
  getCover = (coverimage) => {
    this.setState({
      coverPicImage: coverimage,
    });
  };
  getProfile = (profileimage) => {
    this.setState({
      profileImage: profileimage,
    });
  };
  getFollow = (followFromChild) => {
    let noOfFollowers = new Number(this.state.noOfFollowers);
    this.setState({
      followreqfromchild: followFromChild,
      noOfFollowers: noOfFollowers + 1,
      isFollowingTheUser: "true",
    });
  };
  getUnfollow = (followFromChild) => {
    let noOfFollowers = new Number(this.state.noOfFollowers);
    this.setState({
      followreqfromchild: followFromChild,
      noOfFollowers: noOfFollowers - 1,
      isFollowingTheUser: "false",
    });
  };

  getSkillSearch = (skill) =>{
    this.props.history.push({
      pathname: '/searchbyskill',
      state: { search: skill }
    })
  }
  handleClickShowAlert = (e) => {
    this.setState({
      copySuccess: e,
    });

    setTimeout(() => {
      this.setState({
        copySuccess: false,
      });
    }, 2000);
  };

  render() {
    if (this.state.loading === true) {
      return (
        <div style={{ display: "block", margin: "0 auto" }}>
          <img
            alt="thankyou"
            src={require("../images/loader.svg")}
            className="mx-auto"
            style={{ display: "block", margin: "30vh auto" }}
          />
        </div>
      );
    }

    const handleOpen = () => {
      this.setState({ setopen: !this.state.setopen });
    };
    const handleClose = () => {
      this.setState({ setopen: false });
    };
    let coverimgProps = {
      coverimg: this.state.coverPicImage,
      isSelfProfile: this.state.isSelfProfile,
      isFollowingTheUser: this.state.isFollowingTheUser,
    };

    return (
      <div className="profile-bg">
        <MDBContainer className="profile_page pt-0">
          <div className="container shadow-bg mx-0">
            <MDBRow>
              <MDBCol md="12" className="px-3 my-3">
                <CoverImage
                  {...coverimgProps}
                  sendFollowToParent={this.getFollow}
                  sendUnfollow={this.getUnfollow}
                  vieweeid={this.state.viewee}
                  sendCover={this.getCover}
                />
              </MDBCol>
              <MDBCol
                lg="3"
                md="3"
                sm="4"
                className="profile-img-box mr-0 pr-0"
              >
                <div className="profile-img">
                  <ProfileImage
                    selfprofile={this.state.isSelfProfile}
                    profileimg={this.state.profileImage}
                    vieweeid={this.state.viewee}
                    sendProfile={this.getProfile}
                  />
                </div>
                <div className="followers">
                  <h3 className="mobile-name">{this.state.userName}</h3>

                  <div className="mobile-desc">
                    <p>{this.state.bio}</p>
                    <ul className="mobile-tags">
                      {this.state.skillTags && this.state.skillTags.length >0 && this.state.skillTags.map((skillTags) => (
                        <li key={skillTags.toString()} onClick={()=>{this.getSkillSearch(skillTags)}}>{skillTags}</li>
                      ))}
                    </ul>
                  </div>
                  {this.state.postCount === "0" ? (
                    <h4>
                      <strong>No Posts</strong>
                    </h4>
                  ) : (
                    <h4>
                      <strong>{this.state.postCount} Posts</strong>
                    </h4>
                  )}

                  {localStorage.getItem("isAdmin") ||
                  localStorage.getItem("isBooker") ? (
                    <>
                      <Link>
                        {this.state.noOfFollowers === null ? (
                          <>0 Admirers</>
                        ) : (
                          <>{this.state.noOfFollowers} Admirers</>
                        )}
                      </Link>
                      <Link>
                        {this.state.noOfFollowing === null ? (
                          <>0 Admiring</>
                        ) : (
                          <>{this.state.noOfFollowing} Admiring</>
                        )}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={{
                          pathname: "/people",
                          ActiveTab: 0,
                          vieweeid: this.state.viewee,
                          self: this.state.isSelfProfile,
                        }}
                      >
                        {this.state.noOfFollowers === null ? (
                          <>0 Admirers</>
                        ) : (
                          <>{this.state.noOfFollowers} Admirers</>
                        )}
                      </Link>
                      <Link
                        to={{
                          pathname: "/people",
                          ActiveTab: 1,
                          vieweeid: this.state.viewee,
                          self: this.state.isSelfProfile,
                        }}
                      >
                        {this.state.noOfFollowing === null ? (
                          <>0 Admiring</>
                        ) : (
                          <>{this.state.noOfFollowing} Admiring</>
                        )}
                      </Link>
                    </>
                  )}
                </div>
              </MDBCol>
              <MDBCol sm="6" md={this.state.isSelfProfile === "true" ? ("7"):("8")} className="profile-desc">
                <h3 className="web-name">{this.state.userName}</h3>

                <p>{this.state.bio}</p>
                <ul className="web-tags">
                  {this.state.skillTags&&this.state.skillTags.length>0&&this.state.skillTags.map((skillTags) => (
                    <li key={skillTags.toString()} onClick={()=>{this.getSkillSearch(skillTags)}}>{skillTags}</li>
                  ))}
                </ul>
              </MDBCol>
              {this.state.isSelfProfile === "true" && (
              <MDBCol md="1" sm="1" className="profile_settings mr-0" >
                <h6
                  className={ `${
                    this.state.copySuccess === this.state.viewee
                      ? "copied-profile"
                      : "profile-copied profile-hide"
                  }`}

                 
                >
                  Link copied{" "}
                </h6>
                <span
                  onClick={(e) =>
                    navigator.clipboard.writeText(
                      `https://famenet.in/default-profile/${this.state.viewee}`
                    ) && this.handleClickShowAlert(this.state.viewee)
                  }
                  className="copy-btn"
                  style={{ cursor: "pointer",float:"right",marginTop:"5px" }}
                >
                  <ReplyAllRounded
                    style={{ transform: "scaleX(-1)", marginTop: "-10px" }}
                  />
                </span>
              </MDBCol>
              )}
              {this.state.isSelfProfile === "true" ? (
                <MDBCol md="1" sm="1" className="profile_settings">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOpen}
                  >
                    <SettingsOutlinedIcon />
                  </Button>
                </MDBCol>
              ) : (
                <MDBCol md="1" sm="1" className="profile_settings mr-0" >
                <h6
                  className={`${
                    this.state.copySuccess === this.state.viewee
                      ? "copied-profile"
                      : "profile-copied profile-hide"
                  }`}

                 
                >
                  Link copied{" "}
                </h6>
                <span
                  onClick={(e) =>
                    navigator.clipboard.writeText(
                      `https://famenet.in/default-profile/${this.state.viewee}`
                    ) && this.handleClickShowAlert(this.state.viewee)
                  }
                  style={{ cursor: "pointer",float:"right",marginTop:"5px" }}
                >
                  <ReplyAllRounded
                    style={{ transform: "scaleX(-1)", marginTop: "-10px" }}
                  />
                </span>
              </MDBCol>
              )}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.setopen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                style={{
                  display: "flex",
                }}
              >
                <Fade in={this.state.setopen}>
                  <div className="setting-dialog">
                    <h4>Settings</h4>
                    <ul>
                      <li>
                        <Link to={{ pathname: "/edit-profile", ActiveTab: 0 }}>
                          Edit Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "/edit-profile",
                            ActiveTab: 1,
                          }}
                        >
                          Change Password
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "/edit-profile",
                            ActiveTab: 2,
                          }}
                        >
                          Block Account
                        </Link>
                      </li>
                      <li>
                        <Logout />
                      </li>
                    </ul>
                  </div>
                </Fade>
              </Modal>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <div className="about-me">
                  <h4>About Me</h4>
                  <AboutMe about={this.state.about} update={this.updateAbout} self={this.state.isSelfProfile}/>
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow className="profile-cat">
              <PassionTabs
                userPassion={this.state.passion}
                selfprofile={this.state.isSelfProfile}
                vieweeid={this.state.viewee}
                senddelpost={this.getDelPost}
              />
            </MDBRow>
          </div>
        </MDBContainer>
      </div>
    );
  }
}
