import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBInput,
} from "mdbreact";
import "./login/auth.css";

export default class Help extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      contact:"",
      description:"",
      loading:false,
      disablebtn:false,

    //   isError: {
    //     username: false,
    //     password: false,
    //   },
    //   errorMessage: {
    //     username: "",
    //     password: "",
    //     both: "",
    //   },
    };

   
  }

  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };


  

  
  render() {
    var isEnabled = false 
    if(this.state.name.length > 4 && this.state.email.length>2 && this.state.description.length>50){
      isEnabled = true
    }

    return (
      <div className="container-fluid bg-login">
        <div className="row">
          <div className="col-lg-7 col-md-5 contact-image">
               <h3>FAMENET INDIA</h3>
               <h4>The only platform to showcase your real talent</h4>
               <h4>Three easy steps to fame</h4>
               <ul><li>Share</li><li>Showcase</li><li>Shine</li></ul>
              <div style={{display:"flex"}}>
               <a href="https://www.facebook.com/FameNetOfficial"><i className="fa fa-facebook"></i></a>
               <a href="https://www.instagram.com/famenet.official/"><i className="fa fa-instagram"></i></a>
               <a href="/"><i className="fa fa-twitter"></i></a>
              
               </div>
               <div>Feel free to contact us:
                 <br/>
               Mob/Whatsapp: +91-7735221836
               <br/>
               Email: famenetplus@gmail.com
                </div>
          </div>
          <div className="col-md-5 col-sm-12 login-form">
            <form 
            id={this.props.id}
            name={this.props.name}
            method={this.props.method}
            action={this.props.action}
            >
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="10">
                    <MDBCardBody className="logininput">
                      <div className="text-center">
                        <h2 className="mb-5">
                          <strong>We'd love to hear from you</strong>
                        </h2>
                      </div>
                     
                      <MDBInput
                        label="Name"
                        icon="user"
                        group
                        type="text"
                        // onBlur={this.validateUserName}
                        onChange={this.handleChange("name")}
                        name="name"
                        value={this.state.name}
                        
                      />
                      <MDBInput
                        label="E-mail"
                        icon="envelope"
                        group
                        type="text"
                        // onBlur={this.validateUserName}
                        onChange={this.handleChange("email")}
                        name="email"
                        value={this.state.email}
                        
                      />
                      <MDBInput
                        label="Contact number"
                        icon="phone"
                        group
                        type="text"
                        // onBlur={this.validateUserName}
                        onChange={this.handleChange("contact")}
                        name="contact"
                        value={this.state.contact}
                        
                      />
                      <MDBInput
                        type="textarea"
                        rows="2"
                        label="How can we help?"
                        icon="pencil"
                        onChange={this.handleChange("description")}
                        value={this.state.description}
                        name="description"
                        maxLength="30"
                        id="description"
                      ></MDBInput>
                    
                         <br/>
                    
                    
                      <div className="text-center mb-3">
                        <button
                          type="button"
                          className="btn-block z-depth-1a btn-login"
                        //   onClick={this.sendMessage.bind(this)}
                          disabled={!isEnabled}
                        >
                          {this.state.loading && (
                            <i className="fa fa-refresh fa-spin"></i>
                          )}{" "}
                          &nbsp; Submit
                        </button>
                      </div>
                  
                    </MDBCardBody>
                  
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </form>
          </div>
        </div>
       
      </div>

      
    );
  }
}
