import React, { Component } from "react";
import ReactPlayer from "react-player";


export default class VideosG extends Component {
  constructor() {
    super();
    this.state = {
      videoName:null,
      videoUrl:null
    };
  }
  componentDidMount() {
      this.setState({
        videoName: this.props.match.params.name,
        videoUrl:'/public/videos/'+this.props.match.params.name+'.mp4'
      });
     
    }
  render() {
    return (
      <>
      
          <div className="container-fluid guide">
           
            <ReactPlayer
                   
                    url={this.state.videoUrl}
                    controls={true}
                  />
           
          </div>
     
      </>
    );
  }
}
