import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBInput,
  MDBModalFooter,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBFormInline,
} from "mdbreact";
import Apiservice from "../service/ApiService";
import "./auth.css";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { RadioGroup } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BookerActionFn, LoginActionFn } from "../../redux/actions/loginAction";

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) => state.LoginData);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordmodal, setPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetpasswordusername, setResetpasswordusername] = useState("");
  const [resetpasswordemail, setResetpasswordemail] = useState("");
  const [forgotpasswordsuccess, setForgotPassworsuccess] = useState("");
  const [closeresetbutton, Setcloseresetbutton] = useState(null);
  const [disablebtn, setDisablebtn] = useState(false);
  const [usertype, setUsertype] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [usertypeError, setUsertypeError] = useState(false);
  const [usernameMsg, setUsernameMsg] = useState("");
  const [passwordMsg, setPasswordMsg] = useState("");
  const [usertypeMsg, setUsertypeMsg] = useState("");
  const [both, setBoth] = useState("");
  const [hidden, setHidden] = useState(false);
  const [isFirst, setIsFirst] = useState(true);

 
  const toggleShow = () => {
    setHidden(!hidden);
  };
  const forgotpassword = () => {
    let modalNumber = passwordmodal;
    setPasswordModal(!modalNumber);
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    setDisablebtn(true);
    let userid = resetpasswordusername;
    let useremail = resetpasswordemail;
    Apiservice.ForgotPassword(userid, useremail).then((res) => {
      if (res.data === "success") {
        setForgotPassworsuccess(
          "A temporary password has been sent, Please check your registered e-mail id."
        );
        Setcloseresetbutton(true);
      } else {
        setForgotPassworsuccess("No matching profiles found");
        Setcloseresetbutton(false);
        setDisablebtn(false);
      }
    });
  };

  const validateUserName = () => {
    if (username.length < 3) {
      setUsernameError(false);
      setUsernameMsg("Type your username");
    } else {
      setUsernameMsg("");
      setUsernameError(true);
    }
  };

  const validateUsertype = () => {
    if (usertype.length < 3) {
      setUsertypeMsg("Please select an option");
      setUsertypeError(false);
    } else {
      setUsertypeMsg("");
      setUsertypeError(true);
    }
  };

  const validatePassword = () => {
    if (password.length < 4) {
      setPasswordMsg("Type your Password");
      setPasswordError(false);
    } else {
      setPasswordMsg("");
      setPasswordError(true);
    }
  };
  const isFormValid = () => {
    return usernameError && passwordError && usertypeError;
  };
 
  //   useEffect(() => {
  //     if (!isFirst) {
  //       if (LoginData && LoginData.loginSuccesData) {
  //         if (LoginData.loginSuccesData && LoginData.loginSuccesData.sessionId) {
  //           history.push("/feed");
  //         } else if (LoginData.loginErrorData) {
  //           setBoth("Please enter valid userid and password");
  //         }
  //       }
  //     }
  //   }, [LoginData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    var body = {
      username: username,
      password: password,
    };

    if (usertype === "booker") {
     
      dispatch(BookerActionFn(body));
      if (LoginData.bookerlogin[0] === "success") {
        setLoading(false);

        history.push("/home");
      } else if (LoginData.isadmin === "true") {
        history.push("/admin");
      }
      else if (
        LoginData.bookerlogin === "failure" ||
        LoginData.bookerlogin[0] === null 
      ) {
        setBoth("Username or Password is incorrect");
        setLoading(false);
      } 
    } else if (usertype === "artist") {
      dispatch(LoginActionFn(body));
      setIsFirst(false);

      if (LoginData.loginSuccesData.errorMessage) {
        setBoth("Username or Password is incorrect");
        setLoading(false);
      } else if (LoginData.loginSuccesData.sessionId) {
        if (LoginData.loginSuccesData.notificationList !== null) {
          let notif = LoginData.loginSuccesData.notificationList;
          props.NotificationList(notif);
        }
      } else if (LoginData.isadmin === "true") {
        props.history.push("/admin");
      }
    }
  };
  var isEnabled = false;
  if (username.length > 4 && password.length > 2 && usertype.length > 2) {
    isEnabled = true;
  }

  var isDisabled = false;
  if (
    resetpasswordusername.length > 4 &&
    resetpasswordemail.length > 5 &&
    disablebtn === false
  ) {
    isDisabled = true;
  }

  return (
    <div className="container-fluid bg-login">
      <div className="row">
        <div className="col-lg-3 offset-lg-3 col-md-5 offset-md-1 login-image">
          <h1>Discover India's most Talented people</h1>
          <img alt="timer" src={require("../images/login.png")} />
        </div>
        <div className="col-md-6 col-sm-12 login-form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="8">
                  <MDBCardBody className="logininput">
                    <div className="text-center">
                      <p
                        className="text-left mt-5"
                        style={{ color: "#000000", fontWeight: "500" }}
                      >
                        Join now to showcase your talent and connect to talented
                        artists across India
                        <br />
                        To know more{" "}
                        <Link to="/about" style={{ color: "#b81b21" }}>
                          click here
                        </Link>
                      </p>
                      <h2 className="mb-5 mt-0">
                        <strong>Sign in</strong>
                      </h2>
                    </div>
                    <div
                      className={`no-error ml-0${both ? " error-signup" : ""}`}
                    >
                      {both}
                    </div>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      className="pl-2"
                    >
                      <FormControlLabel
                        value="booker"
                        label="Hire an Artist"
                        control={
                          <Radio color="primary" className="form-radio" />
                        }
                        onChange={(e) => setUsertype(e.target.value)}
                      />
                      <FormControlLabel
                        control={
                          <Radio color="primary" className="form-radio" />
                        }
                        value="artist"
                        label="I am an Artist"
                        aria-label="bottom"
                        onChange={(e) => setUsertype(e.target.value)}
                      />
                    </RadioGroup>
                    <MDBInput
                      label="Username"
                      icon="envelope"
                      group
                      type="text"
                      onBlur={validateUserName}
                      onChange={(e) => setUsername(e.target.value)}
                      name="username"
                      value={username}
                    />
                    <div
                      className={`no-error${
                        usernameError ? " error-signup" : ""
                      }`}
                    >
                      {usernameMsg}
                    </div>

                    <MDBInput
                      label="Password"
                      icon="key"
                      group
                      type={hidden ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      onBlur={validatePassword}
                      value={password}
                    >
                      {hidden ? (
                        <i
                          toggle="#input-pwd"
                          className="fa fa-eye field-icon toggle-password"
                          icon="pencil"
                          onClick={toggleShow}
                        ></i>
                      ) : (
                        <i
                          toggle="#input-pwd"
                          className="fa fa-eye-slash field-icon toggle-password"
                          icon="pencil"
                          onClick={toggleShow}
                        ></i>
                      )}
                    </MDBInput>
                    <div
                      className={`no-error${
                        passwordError ? " error-signup" : ""
                      }`}
                    >
                      {passwordMsg}
                    </div>

                    <p className="font-small pur-text d-flex justify-content-end pb-3">
                      Forgot
                      <a
                        href="#!"
                        className="pur-text ml-1"
                        onClick={forgotpassword}
                      >
                        <strong>Password?</strong>
                      </a>
                    </p>
                    <div className="text-center mb-3">
                      <button
                        type="submit"
                        className="btn-block z-depth-1a btn-login"
                        disabled={!isEnabled}
                        onSubmit={handleSubmit}
                      >
                        {loading && <i className="fa fa-refresh fa-spin"></i>}{" "}
                        &nbsp; Sign in
                      </button>
                    </div>
                    {/* <p className="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2">
                        or Sign in with:
                      </p> */}
                    {/* <div className="row my-3 d-flex justify-content-center">
                        <MDBBtn social="gplus" color="danger">
                          Google
                        </MDBBtn>
                      </div> */}
                  </MDBCardBody>
                  <MDBModalFooter className="pt-3 mb-1 ">
                    <p className="font-small black-text d-flex login-footer">
                      Not a member?
                      <Link className="lm-1 pur-text" to={"/sign-up"}>
                        &nbsp;
                        <strong> Join now &nbsp;</strong>
                      </Link>
                      for free
                    </p>

                    <p className="font-small black-text d-flex login-footer pt-2">
                      If you want to book an artist
                      <Link
                        className="lm-1 pur-text"
                        to={"/signup-for-booking"}
                      >
                        &nbsp;
                        <strong> Sign up &nbsp;</strong>
                      </Link>
                      here
                    </p>
                  </MDBModalFooter>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </form>
        </div>
      </div>
      <MDBModal
        isOpen={passwordmodal}
        toggle={() => forgotpassword}
        backdrop={false}
        overflowScroll={false}
        className="forgotp-modal"
      >
        <MDBModalHeader>Reset your password</MDBModalHeader>
        <MDBModalBody>
          {closeresetbutton === null || closeresetbutton === false ? (
            <form onSubmit={handleForgotPassword}>
              {closeresetbutton === false ? (
                <>
                  <p className="no-error" style={{ marginBottom: "25px" }}>
                    {forgotpasswordsuccess}
                  </p>
                </>
              ) : (
                <></>
              )}

              <MDBInput
                label="Username"
                icon="envelope"
                group
                type="text"
                // onBlur={this.validateUserName}
                onChange={(e) => setResetpasswordusername(e.target.value)}
                name="resetpasswordusername"
                value={resetpasswordusername}
              />
              <MDBInput
                label="Email"
                icon="envelope"
                group
                type="text"
                // onBlur={this.validateUserName}
                onChange={(e) => setResetpasswordemail(e.target.value)}
                name="resetpasswordemail"
                value={resetpasswordemail}
              />
            </form>
          ) : (
            <>
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              <h4>{forgotpasswordsuccess}</h4>
            </>
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn onClick={forgotpassword} id="btn-famenet">
            Close
          </MDBBtn>
          {closeresetbutton !== true ? (
            <MDBBtn
              id="btn-famenet"
              onClick={handleForgotPassword}
              disabled={!isDisabled}
            >
              Submit
            </MDBBtn>
          ) : (
            <></>
          )}
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
};

export default Login;
