import React, { Component } from "react";
import { connect } from "react-redux";
import "./admin.css";
import ComplaintsList from './complaintsTable';
import DeactivatedPosts from './deactivatedPosts';
import SearchPost from './searchpost';

class Admin extends Component {
  constructor(props){
    super();
    this.state={
      showComplaints:false,
      showdeactivatedpost:false,
      loading:true,
    }
  }
  componentDidMount(){
    if(this.props.LoginData === 'true'){
      this.setState({
        loading:false
      })
    } 
    else{
        window.location = "./"
    }
    
  }
 
  showComplaints = () =>{
    this.setState({
      showComplaints:true,
      showdeactivatedpost:false
    })
  }
  showDeactivate = () =>{
    this.setState({
      showComplaints:false,
      showdeactivatedpost:true
    })
  }
  
handleSelect(val) {
  this.setState({ selected: val });
}
  render(){
    if(this.state.loading){
      return(
        <></>
      )
    }
    return(
      <div className="container-fluid admin-bg">
      <div className="container" style={{background:"#fff",minHeight:"100vh"}}>
        <div className="row" style={{paddingTop:"20px"}}>
        <div className="col-md-2">
        <div className="admin-image">
        <img src="user-img.png" alt=""></img>
        </div>
        </div>
        <div className="col-md-3 admin-detail">
      
    <h3><b>Name :</b> Mr. Admin</h3>
    <h4><b>Role :</b> Admin</h4>
    </div>
    <div className="col-md-3">
      

    </div>
    </div>
    <div className="row">
      <div className="col-md-4">
      <button onClick={this.showComplaints}>Load Complaints</button>
      <button onClick={this.showDeactivate}>Load deactivated</button>
      </div>
      <div className="col-md-6">
      
       <SearchPost/>
      </div>
    </div>
  
     <hr/>
{this.state.showComplaints ? ( <ComplaintsList/>):<></>}
{this.state.showdeactivatedpost ? (<DeactivatedPosts/>):<></>}
    </div>

  </div>
    )
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData.isadmin
    }
}

export default connect(mapStateToProps)(Admin);