import React, { Component } from "react";
import ApiService from "../service/ApiService";

import { connect } from "react-redux";

// comment Modal
class CommentModal extends Component {
    constructor(props) {
      super();
      this.state = {
        commentModalList: [],
        commentdeleted: false,
        commentsperpage: 5,
        commentpagesize: 0,
        commentsTotalPages: null,
        commentcount: 0,
      };
    }
    componentDidMount() {
      this.loadComments();
      this.setState({
        commentcount: this.props.commentcount,
        
      });
    }
  
    loadComments = () => {
      const { commentsperpage, commentpagesize, commentModalList } = this.state;
      let userid;
      if (this.props.LoginData.loginSuccesData.userProfileId) {
        userid = this.props.LoginData.loginSuccesData.userProfileId;
      } else if (this.props.LoginData.isadmin === 'true') {
        userid = "admin";
      }
  
      ApiService.ViewComments(
        userid,
        this.props.userpostid,
        commentpagesize,
        commentsperpage
      ).then((res) => {
        this.setState({
          commentModalList: [...commentModalList, ...res.data.dataItemslist],
        });
      });
    };
  
    loadMoreComments = () => {
      this.setState(
        (prevState) => ({
          commentpagesize: prevState.commentpagesize + 1,
        }),
        this.loadComments
      );
    };
    closeComments = () => {
      let Modalopen = false;
      this.props.commentsmodalclose(Modalopen);
    };
  
    deleteComment = (commentid) => {
      this.setState({
        commentModalList: [],
      });
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
      ApiService.DeleteComment(commentid, viewerId, cookieValue).then((res) => {
        if (res.data.status === "success") {
          this.setState(
            {
              commentdeleted: true,
              commentcount: this.state.commentcount - 1,
              commentpagesize: 0,
            },
            this.loadComments
          );
          this.props.commentdelete(true);
        }
      });
    };
  
    render() {
     
      return (
        <div className="modal-content modal-feed-view CommentMdl">
          <div className="modal-feed-head">
            <h4>Comments</h4>
            <button onClick={this.closeComments}>
              <i className="fa fa-times"></i>
            </button>
          </div>
  
          <ul className="modal-comments-list CommentsListLoad">
            {this.state.commentModalList.length === 0 ? (
              <p>No comments to show</p>
            ) : (
              <>
                {this.state.commentModalList.map((comment, index) => (
                  <li key={index.toString()}>
                    {comment.userProfileImg === null ? (
                      <img src="/user-img.png" alt="" />
                    ) : (
                      <img
                        src={comment.userProfileImg}
                        className="img-fluid"
                        alt=""
                      />
                    )}
  
                    <div className="comment-width">
                      <p className="main-title text-left">
                        <b>{comment.userProfileName}</b>
                      </p>
                      <p>{comment.commentText}</p>
                    </div>
                    <div className="comment-time">
                      <h5>
                        {comment.timeStamp}
  
                        {this.props.postuserid === this.props.user && 
                          <button
                            onClick={() => this.deleteComment(comment.commentId)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>}
                        
                      </h5>
                    </div>
                  </li>
                ))}
                {this.state.commentcount > this.state.commentModalList.length ? (
                  <button
                    className="lo_more"
                    onClick={this.loadMoreComments.bind(this)}
                  >
                    Load more
                  </button>
                ) : (
                  <>No more Comments to show</>
                )}
              </>
            )}
          </ul>
        </div>
      );
    }
  }

  const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(CommentModal);  