import React, { Component } from "react";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import { Link } from "react-router-dom";
import logo from "../components/images/logo.png";
import RenderNav from "./navigationRoute";
import { connect } from "react-redux";
import LoginData from "../redux/reducers/loginReducer";

class Header extends Component {
  constructor(props) {
    super();
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  

  render() {
   
   
    const body = document.body;
    // const triggerMenu = document.querySelector(".main-header .trigger-menu");

    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 10;

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 20) {
        body.classList.remove(scrollUp);
        return;
      }

      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (
        currentScroll < lastScroll &&
        body.classList.contains(scrollDown)
      ) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    });
    let logocheck;
    if (this.props.LoginData?.loginSuccesData.sessionId) {
      logocheck = (
        <Link to={"/feed"}>
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      );
    } else if (this.props.LoginData?.isadmin === "true") {
      logocheck = (
        <Link to={"/admin"}>
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      );
    } else if (this.props.LoginData?.bookerlogin && this.props.LoginData.bookerlogin[0] === "success") {
      logocheck = (
        <Link to={"/home"}>
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      );
    } else {
      logocheck = (
        <Link to={"/"}>
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      );
    }
    return (
      <header className="page-header">
        <MDBNavbar className="fame-header trigger-menu-wrapper" expand="md">
          <MDBNavbarBrand className="trigger-menu">{logocheck}</MDBNavbarBrand>
         <RenderNav />
        </MDBNavbar>
      </header>
    );
  }
}
const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(Header);
