import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBInput } from "mdbreact";
import { Link } from "react-router-dom";

export default class Sitemap extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-4 pr-0 pb-4 pl-0">
          <div className="privacy-head">
            <h3>Sitemap</h3>
            <p>Complete list of all  the useful links for reference</p>

           <h4 className="text-center">Checkout our Privacy Policy here : <Link to={'./privacy-policy'}  style={{color:"#b81b21"}}>Privacy policy</Link> </h4>
           <h4 className="text-center">Checkout our Legal page here : <Link to={'./legal'}  style={{color:"#b81b21"}}>Legal</Link> </h4>
           <h4 className="text-center">Checkout our Terms and Conditions here : <Link to={'./terms-conditions'}  style={{color:"#b81b21"}}>Terms &  Conditions</Link> </h4>
           <h4 className="text-center">Checkout our Help section  here : <Link to={'./help'}  style={{color:"#b81b21"}}>Help</Link> </h4>
           <h4 className="text-center">Checkout our About us  here : <Link to={'./about'}  style={{color:"#b81b21"}}>About Us</Link> </h4>
           <h4 className="text-center">Checkout our Sales & Refund  here : <Link to={'./refund-policy'}  style={{color:"#b81b21"}}>Sales & Refund</Link> </h4>
           <h4 className="text-center">Checkout our Booker Registration  here : <Link to={'./signup-for-booking'}  style={{color:"#b81b21"}}>Booker Registration</Link> </h4>
           <h4 className="text-center">Checkout our User Registration here : <Link to={'./sign-up'}  style={{color:"#b81b21"}}>Artist Registration</Link> </h4>
           <h4 className="text-center">Checkout our Forget Password here : <Link to={'./login'}  style={{color:"#b81b21"}}>Forgot Password</Link> </h4>
           <h4 className="text-center">Checkout our Login page here : <Link to={'./login'}  style={{color:"#b81b21"}}>Login Here</Link> </h4>
           <h4 className="text-center">Checkout our Guide page here : <Link to={'./guide'}  style={{color:"#b81b21"}}>Login Here</Link> </h4>

           </div>
 
          </div>
</>
    );
  }
}
