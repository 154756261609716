import React, { Component } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import "./feeds.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Laurel from "../images/laurel.png";
import FeedContent from "./FeedContent";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import ApiService from "../service/ApiService";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const handleDragStart = (e) => e.preventDefault();
class PopularViews extends Component {
  constructor(props) {
    super();

    this.state = {
      state: "none",
      district: "none",
      popularArtists: [],
    };
  }
  componentDidMount() {
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.ShowPopularArtists(
      this.state.state,
      this.state.district,
      viewerId,
      cookieValue
    ).then((res) => {
     
      this.setState({
        popularArtists: res.data.dataItemslist,
      });
    });
  }
  render() {
    return (
      <>
        <div className="profile-bg">
          <MDBContainer
            style={{ borderRadius: "10px" }}
            className="popular-art"
          >
            {/* famenet popular artists */}
            <MDBRow className="popular-views">
              <MDBCol size="12" className="text-center">
                <h4 className="pt-2 pl-2">Famenet Popular Artist</h4>
              </MDBCol>
              {this.state.popularArtists &&
              this.state.popularArtists.length > 0 ? (
                this.state.popularArtists.map((item) => {
                  return (
                    <div className="art-img col-lg-4 col-md-6 col-sm-6 pl-0">
                      <div className="frame-img">
                        {item.profilePic === null ? (
                          <img
                            src="./user-img.png"
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <img
                            src={item.profilePic}
                            style={{ borderRadius: "50%" }}
                          />
                        )}
                      </div>
                      <h4>{item.userName}</h4>
                      <Link
                        to={{
                          pathname: `/default-profile/${item.userProfileId}`,

                          state: item.userProfileId,
                        }}
                      >
                        view profile
                      </Link>
                    </div>
                  );
                })
              ) : (
                <div className="no-popular-profile">
                <h1>
                  Famenet Popular artists <br />
                  will appear here.
                </h1>
              </div>
              )}
            </MDBRow>
            {/* famenet popular artists */}

            
            {/* <MDBRow className="popular-views">
              <MDBCol size="12" className="text-center">
              
              </MDBCol>
              <div className="no-popular-profile">
                <h1>
                  Famenet Popular artists <br />
                  will appear here.
                </h1>
              </div>
            </MDBRow> */}
           
          </MDBContainer>
        </div>
      </>
    );
  }
}

function TabContainer(props) {
  return (
    <Typography component="div" className="feedspace">
      {props.children}
    </Typography>
  );
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(PopularViews);