import React, { Component } from "react";
import ApiService from "../service/ApiService";
import "./admin.css";
import { MDBDataTable, MDBTooltip } from "mdbreact";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";

export default class ComplaintsList extends Component {
  constructor(props) {
    super();
    this.state = {
      complaints: [],
      pagenum: 0,
      pagesize: 5,
      totalcomplaints: 0,
    };
  }

  componentDidMount() {
    this.loadcomplaints();
  }
  loadcomplaints = () => {
    const { pagenum, pagesize } = this.state;
    ApiService.LoadComplaints(pagenum, pagesize).then((res) => {
      let numofcomplaints = parseInt(res.data[0].totalNoOFGrievances);

      this.setState({
        complaints: res.data,
        totalcomplaints: numofcomplaints,
      });
    });
  };
  loadmorecomplaints = () => {
    this.setState(
      (prevState) => ({
        pagenum: prevState.pagenum + 1,
      }),
      this.loadcomplaints
    );
  };
  loadlesscomplaints = () => {
    this.setState(
      (prevState) => ({
        pagenum: prevState.pagenum - 1,
        complaints: [],
      }),
      this.loadcomplaints
    );
  };
  blockpost = (action, id) => {
    let doaction;
    if (action === "delete") {
      doaction = "delete";
    } else {
      doaction = "deactivate";
    }
    ApiService.DeactPost(id, doaction).then((res) => {
      if (res.data.status === "success") {
      }
    });
  };
  render() {
    let data = {
      columns: [
        {
          label: "Complaint Id",
          field: "complaintId",
          sort: "asc",
          width: 50,
        },
        {
          label: "Complaint",
          field: "complaint",
          sort: "asc",
          width: 370,
        },

        {
          label: "Post Id",
          field: "postId",
          sort: "asc",
          width: 100,
        },
        {
          label: "Complaint By",
          field: "person",
          sort: "asc",
          width: 100,
        },

        {
          label: "Complaint date",
          field: "date",
          sort: "asc",
          width: 100,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
      ],

      rows: this.state.complaints.map((complaint, index) => ({
        complaintId: complaint.complainId,
        complaint: complaint.complaint,

        postId: complaint.postId,
        person: complaint.complainer,

        date: complaint.timeStamp,

        action: (
          <>
            <MDBTooltip domElement tag="span" placement="top">
              <button
                onClick={() => this.blockpost("deactivate", complaint.postId)}
              >
                <BlockIcon />
              </button>
              <span>
                <b>Deactivate Post</b>
              </span>
            </MDBTooltip>
            <MDBTooltip domElement tag="span" placement="top">
              <button
                onClick={() => this.blockpost("delete", complaint.postId)}
              >
                <DeleteIcon />
              </button>
              <span>
                <b>Delete Post</b>
              </span>
            </MDBTooltip>
          </>
        ),
        status: complaint.status,
      })),
    };

    return (
      <>
        <h4>Complaints</h4>
        <MDBDataTable striped bordered hover data={data} paging={false} />

        <div className="pagination">
          {this.state.pagenum > 0 ? (
            <button onClick={this.loadlesscomplaints}>prev</button>
          ) : (
            <></>
          )}

          <button>{this.state.pagenum}</button>
          {this.state.complaints.length === this.state.totalcomplaints ? (
            <></>
          ) : (
            <button onClick={this.loadmorecomplaints}>Next</button>
          )}
        </div>
      </>
    );
  }
}
