import React, { Component } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import ApiService from "../service/ApiService";


class EditPost extends Component {
    constructor(props){
        super();
        this.state = {
          charLimit: 120,
          postTitle:'',
          postDescription:'',
          successmsg:''
        }
    }

    handleModalClose = () =>{

     let Modalopen = 'false'
    this.props.getmodalclose(Modalopen);
    }
    handleChange = (input) => (e) => {
      this.setState({
        [input]: e.target.value,
      });
    };
  componentDidMount(){
    this.setState({
      postTitle:this.props.postedit.title,
      postDescription:this.props.postedit.description
    })
  }
  handleSubmit = (e) =>{
    e.preventDefault()
    let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let profileid = this.props.LoginData.loginSuccesData.userProfileId;
    let postdata = {
      userProfileId : profileid,
	sessionId : cookieValue,
	title : this.state.postTitle,
	description : this.state.postDescription,
	postId : this.props.postedit.postId
    }
  ApiService.EditPost(postdata)
  .then((res)=>{
    if(res.data.status === 'success'){
      this.setState({
        successmsg:'Post updated Successfully!'
            })
    }
  })
  }

    render(){
        return(
           
          
            
                <div className="modal-content modal-profile">
              <div className="modal-feed-head">
              {this.props.postedit.userProfilePic === null ? (<img src="/user-img.png"  alt=""  className="modal-img-profile-feed"/>):  (<img
                  src={this.props.postedit.userProfilePic}
                  className="modal-img-profile-feed"
                  alt=""
                   
                />)}
                <div className="modal-profile-feed-name">
                  <h4 className="main-title text-left">
                    {this.props.postedit.userName}
                  </h4>
                </div>
                <button
                  onClick={this.handleModalClose}
                  id={this.props.postedit.postId}
                  className="modal-close-btn"
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="post-detail">
                <form className="edit-post-form">
                <label className="black-text">Title</label>
              <input type="text" className="form-control" onChange={this.handleChange("postTitle").bind(this)} value={this.state.postTitle} name="postTitle"  />
            
              <label className="black-text">Description</label>
              <textarea type="text" className="form-control py-2" rows="6" onChange={this.handleChange("postDescription")} value={this.state.postDescription} name="postDescription"  maxLength="120" />
              <div >Remaining Characters: {this.state.charLimit - this.state.postDescription.length}</div>

              {this.props.postedit.mediaType === "video" ? (
                <>
                  <ReactPlayer
                    className="videoFrame main-view"
                    url={
                        this.props.postedit.youTubeVideoDetails.youTubeVideoUrl
                    }
                    controls={true}
                  />
                </>
              ) : (
                <img
                  alt="random"
                  src={this.props.postedit.imageFileLocation}
                  className="img-responsive modal-main-view"
                />
              )}
              
              <div>
                <button onClick={this.handleSubmit}>Update</button>
        <p>{this.state.successmsg}</p>
              </div>

                </form>
                </div>

           
              
            </div>
        
         
        )
    }
}


const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(EditPost);