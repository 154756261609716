import React, { Component, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBModalFooter,
  MDBInput,
} from "mdbreact";
import ApiService from "../service/ApiService";
import "./auth.css";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { BookerRegisterActionFn } from "../../redux/actions/loginAction";

const BookerSignup = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) => state.LoginData.bookerlogin);
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullnameError, setFullnameError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [usernameEmsg, setUsernameEmsg] = useState("");
  const [fullnameEmsg, setFullnameEmsg] = useState("");
  const [emailEmsg, setEmailEmsg] = useState("");
  const [passwordEmsg, setPasswordEmsg] = useState("");
  const [hidden, setHidden] = useState(true);
  const [signupError, setSignupError] = useState(false);

  const toggleShow = () => {
    setHidden(!hidden);
  };

  const validateFullName = () => {
    if (fullname.length < 5) {
      setFullnameError(false);
      setFullnameEmsg("Type your Name");
    } else {
      setFullnameError(true);
      setFullnameEmsg("Full name is valid");
    }
  };
  const validatePassword = () => {
    var passw = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    );
    if (password.length < 5) {
      setPasswordError(false);
      setPasswordEmsg("Type your Password");
    } else if (!passw.test(password)) {
      setPasswordError(false);
      setPasswordEmsg(
        "Please use atleast one uppercase, one lowercase, one number and one special character"
      );
    } else {
      setPasswordError(true);
      setPasswordEmsg("Password is valid");
    }
  };
  const validateEmail = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (email.length < 2) {
      setEmailError(false);
      setEmailEmsg("Type your email");
    } else if (!pattern.test(email)) {
      setEmailError(false);
      setEmailEmsg("Email format is incorrect");
    } else {
      ApiService.validateEmail(email).then((res) => {
        const emailavailability = res.data;
        if (emailavailability === "invalid") {
          setEmailError(false);
          setEmailEmsg("Email is already in use");
        } else if (emailavailability === "valid") {
          setEmailEmsg("Email is valid");
          setEmailError(true);
        }
      });
    }
  };

  const validateUsername = () => {
    if (username.length < 2) {
      setUsernameError(false);
      setUsernameEmsg("Type your username");
    } else {
      ApiService.validateUsername(username).then((res) => {
        const usernameavail = res.data;
        if (usernameavail === "invalid") {
          setUsernameError(false);
          setUsernameEmsg("Username is already in use");
        } else if (usernameavail === "valid") {
          setUsernameError(true);
          setUsernameEmsg("username is valid");
        }
      });
    }
  };

  const isFormValid = () => {
    return fullnameError && emailError && usernameError && passwordError;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      fullnameError === false ||
      usernameError === false ||
      emailError === false ||
      passwordError === false
    ) {
      return;
    } else {
      var body = {
        bookerUserName: username,
        bookerCommunicationId: email,
        bookerPassword: password,
      };

      dispatch(BookerRegisterActionFn(body));

      await ApiService.BookerSignup(body).then((res) => {
        var response = res.data;
        if (response === "success") {
          history.push("/home");
        } else if (LoginData[0] === "failure") {
          setSignupError(true);
        }
      });
    }
  };
  const isEnabled = isFormValid();
  return (
    <div className="container-fluid bg-login">
      <div className="row">
        <div className="col-lg-3 offset-lg-3 col-md-5 offset-md-1 login-image">
          <h1>Discover India's most Talented people</h1>
          <img alt="timer" src={require("../images/signupimg.png")} />
        </div>
        <div className="col-md-6 col-sm-12 login-form">
          <form onSubmit={() => handleSubmit}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="8">
                  <MDBCardBody>
                    <div className="text-center">
                      <h2 className="mb-5">
                        <strong>Sign up to FameNet for Booking</strong>
                      </h2>
                    </div>
                    {signupError && (
                      <div
                        className="no-error
                      "
                      >
                        {LoginData[1]}
                        <br/>
                        <br/>
                      </div>
                      
                    )}

                    <MDBInput
                      label="Full Name"
                      required
                      id="FullName"
                      icon="user"
                      group
                      type="text"
                      name="fullname"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      onBlur={validateFullName}
                      className="inputtt"
                    ></MDBInput>

                    <div
                      className={`no-error${
                        fullnameError ? " error-signup" : ""
                      }`}
                    >
                      {fullnameEmsg}
                    </div>

                    <MDBInput
                      label="User Name"
                      icon="tag"
                      id="UserName"
                      group
                      type="text"
                      name="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onBlur={validateUsername}
                    ></MDBInput>
                    <div
                      className={`no-error${
                        usernameError ? " error-signup" : ""
                      }`}
                    >
                      {usernameEmsg}
                    </div>

                    <MDBInput
                      label="E-mail / Mobile"
                      icon="envelope"
                      id="Email"
                      group
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={validateEmail}
                    ></MDBInput>
                    <div
                      className={`no-error${emailError ? " error-signup" : ""}`}
                    >
                      {emailEmsg}
                    </div>

                    <MDBInput
                      label="Password"
                      icon="key"
                      group
                      name="password"
                      type={hidden ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={validatePassword}
                    >
                      {hidden ? (
                        <i
                          toggle="#input-pwd"
                          className="fa fa-eye field-icon toggle-password"
                          icon="pencil"
                          onClick={toggleShow}
                        ></i>
                      ) : (
                        <i
                          toggle="#input-pwd"
                          className="fa fa-eye-slash field-icon toggle-password"
                          icon="pencil"
                          onClick={toggleShow}
                        ></i>
                      )}
                    </MDBInput>
                    <div
                      className={`no-error${
                        passwordError ? " error-signup" : ""
                      }`}
                    >
                      {passwordEmsg}
                    </div>

                    <div className="form-over-border"></div>

                    <div className="text-center mb-4 md-12">
                      <button
                        className="btn-login z-depth-1a btn-block"
                        onClick={handleSubmit}
                        disabled={!isEnabled}
                        id="submits"
                      >
                        Next
                      </button>
                    </div>
                  </MDBCardBody>
                  <MDBModalFooter className="pt-3 mb-1 ">
                    <p className="font-small black-text d-flex login-footer">
                      Already a member?
                      <Link className="lm-1 pur-text" to={"/"}>
                        <strong>&nbsp; Sign in</strong>
                      </Link>
                    </p>
                  </MDBModalFooter>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookerSignup;
