import React, { Component } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import "./feeds.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import FeedContent from "./FeedContent";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Redirect, Link } from "react-router-dom";
import ApiService from "../service/ApiService";
import { connect } from "react-redux";

const handleDragStart = (e) => e.preventDefault();
class UserFeed extends Component {
  constructor(props) {
    super();

    this.state = {
      modal: false,
      activeIndex: null,
      circle: [],
      state: "none",
      district: "none",
      popularArtists: [],
    };
  }

  componentDidMount() {
    const cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
    ApiService.ShowPopularArtists(
      this.state.state,
      this.state.district,
      viewerId,
      cookieValue
    ).then((res) => {
      res.data.dataItemslist.map((item) => {
        this.state.popularArtists.push(item);
      })
      for(let t=this.state.popularArtists.length;t<6;t++){
        this.state.popularArtists.push( {profilePic:null,userProfileId:null,viewMore:false});
      }
      this.state.popularArtists.push( {profilePic:null,userProfileId:null,viewMore:true});

    const divv = this.state.popularArtists.map(m =>
      !m.viewMore ? (
      <div className="feed-carousel">
        {m.profilePic === null ? (
                          <img
                            src="./user-img.png"
                            className="sliderimg"
                            onDragStart={handleDragStart}

                            />
                        ) : (
                          <img
                            src={m.profilePic}
                            className="sliderimg"
                            onDragStart={handleDragStart}
                          />
                        )}
                           {m.userProfileId === null ? (
                        <Link>
                        view profile
                      </Link>
                        ) : (
                          <Link
                        to={{
                          pathname: `/default-profile/${m.userProfileId}`,

                          state: m.userProfileId,
                        }}
                      >
                        view profile
                      </Link>
                        )}
    </div>
    ):(
      <div className="feed-carousel">
      <button
        className="btn-view-popular"
        onClick={() => this.props.history.push("/popular-views")}
      >
        View More
      </button>
    </div>
    ))
    this.setState({
      finalItems:divv
    })
    });

    if (sessionStorage.getItem("activetab")) {
      this.setState({
        activeIndex: 1,
      });
      sessionStorage.removeItem("activetab");
    } else {
      this.setState({
        activeIndex: 0,
      });
    }
  
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleChange = (_, activeIndex) => this.setState({ activeIndex });
  handleLike() {
    if (this.state.circle.isLikedByViewer) {
      this.setLike();
    }
    this.setLike();
  }
  setLike() {
    this.setState({
      // likeActive: !this.state.likeActive,
      postLikeCount: this.state.isLikedByViewer
        ? this.state.postLikeCount - 1
        : this.state.postLikeCount + 1,
    });
  }
  responsive = {
    0: { items: 2 },
    600: { items: 4 },
    780: { items: 4 },
    1024: { items: 4 },
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div className="profile-bg">
        <MDBContainer>
          {/* famenet popular artists */}
          <MDBRow>
            <MDBCol>
              <div className="popular-artists">
                <h4>Famenet Popular Artists</h4>
                <AliceCarousel
                  mouseTracking
                  items={this.state.finalItems}
                  autoPlay={false}
                  responsive={this.responsive}
                  disableDotsControls={true}
                  infinite={false}
                  disableButtonsControls={false}
                >
                 
                </AliceCarousel>
              </div>
            </MDBCol>
          </MDBRow>
          {/* famenet popular artists */}
          <MDBRow>
            <MDBCol>
              <div className="feed-tabs">
                <Tabs
                  value={activeIndex}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  id="usertab"
                >
                  <Tab label="Recommended" />
                  <Tab label="Your Circle" />
                </Tabs>
              </div>
              {activeIndex === 0 && (
                <TabContainer>
                  <FeedContent feedtype="random" activeIndex={0} />
                </TabContainer>
              )}
              {activeIndex === 1 && (
                <TabContainer>
                  <FeedContent feedtype="following" activeIndex={1} />
                </TabContainer>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

function TabContainer(props) {
  return (
    <Typography component="div" className="feedspace">
      {props.children}
    </Typography>
  );
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(UserFeed);
