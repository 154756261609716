import React, { Component } from "react";
import { connect } from "react-redux";
import ApiService from "../service/ApiService";

class DeletePost extends Component {
  constructor(props) {
    super();
    this.state = {
      successmsg: "",
    };
  }

  handleModalClose = () => {
    let Modalopen = false;
    this.props.getmodalclose(Modalopen);
  };

  handleDelete = (e) => {
    e.preventDefault();
    let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
    let profileid = this.props.LoginData.loginSuccesData.userProfileId;
    let postid = this.props.postedit.postId;
    ApiService.DeletePost(postid, profileid, cookieValue).then((res) => {
      if (res.data.status === "success") {
        this.setState({
          successmsg: "Post Deleted Successfully!",
        });
      }
    });
  };
  closeModal = (e) => {
    e.preventDefault();
    let Modalopen = false;
    this.props.getdeletemodalclose(Modalopen);
  };

  render() {
    return (
      <div className="editmodal-wrap delete-modal">
        <div>
          <div className="modal-feed-head"></div>
          {this.state.successmsg === "" ? (
            <>
              {" "}
              <div className="post-detail">
                Do you really want to delete this post?
              </div>
              <ul>
                <li>
                  <button onClick={this.handleDelete.bind(this)}>Delete</button>
                </li>
                <li>
                  <button
                    onClick={this.handleModalClose}
                    id={this.props.postedit.postId}
                  >
                    Cancel
                  </button>
                </li>
              </ul>{" "}
            </>
          ) : (
            <>
              <div className="post-detail">
                {this.state.successmsg}
                <button onClick={this.closeModal}>Close</button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(DeletePost);