import React from "react";
import ApiService from "../service/ApiService";
import { Link } from "react-router-dom";
// import e from "express";

// import projectList from './data/mydata.json';

// const searchList = projectList.map(
//     ({ project_name }) => {
//       return{
//        value: project_name,
//        label: project_name
//       }
//      }
//     );
// const customStyles = {
//   control: base => ({
//     ...base,
//     height: 35,
//     minHeight: 35,
//     borderColor:"#b81b21",
//     color:"#000000 !important",
//   })
// };

export default class SearchPost extends React.Component {
  constructor(props) {
    super();
    this.togglePanel = this.togglePanel.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      open: true,
      active: false,
      search: "",
      result: "",
      openresult: false,
    };
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    if (!this.state.active) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    const currentState = this.state.openresult;
    const opened = this.state.active;
    this.setState({
      active: !opened,
      search: "",
      result: "",
      openresult: !currentState,
    });
  }

  handleSearch = (input) => (e) => {
    e.preventDefault();

    this.setState({
      [input]: e.target.value,
    });

    let search = e.target.value;
    if (search.length > 0) {
      ApiService.SearchbyPost(search).then((res) => {
        this.setState({
          result: res.data.dataItem,
          openresult: true,
        });
      });
    }
    return;
  };
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.togglePanel();
  }

  viewSearch = (e) => {
    e.preventDefault();
    this.setState({
      search: "",
      openresult: true,
    });
  };

  render() {
    return (
      <div className="search-div">
        <div
          ref={(node) => {
            this.node = node;
          }}
        >
          <div className="content">
            <input
              type="text"
              className=""
              placeholder="Search here"
              onChange={this.handleSearch("search").bind(this)}
              name="search"
              value={this.state.search}
              style={{
                width: "100%",

                borderWidth: "0 0 2px 0",
                background: "transparent",
                padding: "3px",
                borderColor: "#b81b21",
                position: "relative",
              }}
            ></input>
            {this.state.result.postId ? (
              <Link
                to={{
                  pathname: `../viewpost`,
                  state: this.state.result.postId,
                }}
                className="searchpost"
              >
                Search
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
}
