import React, { Component, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RegisterActionFn } from "../../redux/actions/loginAction";
import { RegisterUser } from "../../redux/axios/userLoginApiService";
import "./auth.css";

const ThankYou = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) => state.LoginData);
  useEffect(() => {
    postUser();
  }, []);

  const postUser = () => {
    if (localStorage.getItem("userform" && "passion" && "skills")) {
      let userdetail = JSON.parse(localStorage.getItem("userform"));
      let userpassion = JSON.parse(
        localStorage.getItem("passion").toUpperCase()
      );
      let skillTagList = JSON.parse(localStorage.getItem("skills"));
      let skillTags = [];
      skillTagList.forEach((d) => {
        skillTags.push(d.label);
      });
      var userobj = {
        fullName: userdetail.fullname,
        userName: userdetail.username,
        emailAddress: userdetail.email,
        password: userdetail.password,
        address: {
          state: userdetail.state,
          district: userdetail.city,
        },
        isAvailableForBookingEvents: userdetail.CheckedItems,
        bio: userdetail.bio,
        passion: userpassion,
        skillTags: skillTags,
      };

      dispatch(RegisterActionFn(userobj));
    } else {
      history.push("/feed");
    }
  };

  useEffect(() => {
    if (LoginData?.loginSuccesData?.sessionId) {
      document.cookie = `sessionId=${LoginData?.loginSuccesData?.sessionId}`;
      localStorage.setItem(
        "userProfileId",
        LoginData?.loginSuccesData?.userProfileId
      );
      localStorage.setItem(
        "userSelectedPassion",
        JSON.stringify(LoginData?.loginSuccesData?.passionList)
      );
      //   this.props.handleLogin(true);
      localStorage.removeItem("userform");
      localStorage.removeItem("passion");
      localStorage.removeItem("skills");

      history.push("/feed");
    }
  }, [LoginData]);

  return (
    <div className="container-fluid category-bg">
      <div className="row">
        <div className="col-md-5 mx-auto">
          {/* <img alt="thankyou" src={require('../images/check.svg')}  className="mx-auto d-block p-2 mt-5"/> */}
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <img
            alt="thankyou"
            src={require("../images/thankyou.png")}
            className="mx-auto d-block mt-3 thankyou-img"
          />
          <img
            alt="thankyou"
            src={require("../images/loader.svg")}
            className="mx-auto"
            style={{ display: "block", marginTop: "-50px" }}
          />

          <h2 style={{ marginTop: "-30px", marginBottom: "0" }}>
            Thank You for Signing Up
          </h2>
          <p className="text-center">You are all set. Creating your profile</p>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
