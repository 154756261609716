import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBInput } from "mdbreact";
import "./booker.css";
import ApiService from "../service/ApiService"
import { connect } from "react-redux";

class BookArtist extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      mobile: "",
      bookerId: "",
      artistId: "",
      requirement: "",
      quotation: "",
      accepted: "",
      loading: false,
      disablebtn: false,
      success:false,
    };
  }

  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  handleSubmit = () =>{
    let body = {
      bookerEmailId:this.state.email,
      bookerMobileNumber:this.state.mobile,
      bookerId:this.props.LoginData.bookerlogin[1],
      artistId:this.props.match.params.id,
      requirement:this.state.requirement,
      quotaion:this.state.quotation,
      accepted:null
    }

    ApiService.BookingArtist(body).then((res)=>{
    
      if(res.data === "success"){
      this.setState({
        success:true
      })
    }
    })
  }

  render() {
    var isEnabled = false;
    if (
      this.state.email.length > 4 &&
      this.state.mobile.length > 2 &&
      this.state.requirement.length > 50
    ) {
      isEnabled = true;
    }

    if(this.state.success){
      return (
        <div className="container-fluid booker-image">
        <div className="row">
        <div className="booker-form login-form">
        <form
            
            >
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="12">
                    <MDBCardBody className="logininput">
        <div className="profile-updated">
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
        <h3>Booking Succeed</h3>
      </div>
      </MDBCardBody>
      </MDBCol>
      </MDBRow>
      </MDBContainer>
      </form>
      </div>
      </div>
      </div>
      )

    }
    return (
      <div className="container-fluid booker-image">
        <div className="row">
          <div className="booker-form login-form">
            <form
              id={this.props.id}
              name={this.props.name}
              method={this.props.method}
              action={this.props.action}
            >
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="12">
                    <MDBCardBody className="logininput">
                      <div className="text-center">
                        <h2 className="mb-5">
                          <strong>Please mention about your requirement detials</strong>
                        </h2>
                      </div>
{/* start */}

{/* end */}
                      <MDBInput
                        label="Email"
                        icon="user"
                        group
                        type="text"
                        onChange={(e)=>{
                            this.setState({
                                email:e.target.value
                            })
                        }}
                        name="email"
                        value={this.state.email}
                      />
                      <MDBInput
                        label="Contact"
                        icon="mobile"
                        group
                        type="text"
                       
                        onChange={(e)=>{
                            this.setState({
                                mobile:e.target.value
                            })
                        }}
                        name="mobile"
                        value={this.state.mobile}
                      />
                      
                      <MDBInput
                        type="textarea"
                        rows="4"
                        label="Requirement"
                        icon="pencil"
                        onChange={(e)=>{
                            this.setState({
                                requirement:e.target.value
                            })
                        }}
                        value={this.state.requirement}
                        name="requirement"
                       
                      ></MDBInput>
                      <MDBInput
                        type="textarea"
                        rows="4"
                        label="Quotation"
                        icon="pencil"
                        onChange={(e)=>{
                            this.setState({
                                quotation:e.target.value
                            })
                        }}
                        value={this.state.quotation}
                        name="quotation"
                       
                      ></MDBInput>


                      <br />

                      <div className="text-center mb-3">
                        <button
                          type="button"
                          className="btn-block z-depth-1a btn-login"
                            onClick={this.handleSubmit.bind(this)}
                          disabled={!isEnabled}
                        >
                          {this.state.loading && (
                            <i className="fa fa-refresh fa-spin"></i>
                          )}{" "}
                          &nbsp; Submit
                        </button>
                      </div>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </form>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(BookArtist);
