import React, { Component } from "react";
import { Link } from "react-router-dom";
import ApiService from "../service/ApiService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

class UploadLink extends Component {
  constructor(props) {
    super();
    this.state = {
      videourl: "",
      filename: "",
      smallthumb: "",
      largethumb: "",
      consent: false,
      modelopen: false,
    };
  }
  handleChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
    if (this.state.videourl.length === 0) {
      let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      let profileid = this.props.LoginData.loginSuccesData.userProfileId;
      var videodetail = {
        youtubeVideoUrl: e.target.value,
        userProfileId: profileid,
        sessionId: cookieValue,
      };
      ApiService.youtubeThumbs(videodetail).then((res) => {
        this.setState({
          smallthumb: res.data.dataItem.thumbNails.medium.url,
          largethumb: res.data.dataItem.thumbNails.high.url,
        });

        sessionStorage.setItem("largevideothumb", this.state.largethumb);
        sessionStorage.setItem("mediumvideothumb", this.state.smallthumb);
      });
    } else {
      this.setState({
        smallthumb: "",
      });
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    if (sessionStorage.getItem("passion")) {
      if (this.state.consent === false) {
        this.setState({
          modelopen: true,
        });
      } else if (this.state.consent === true) {
        let video = this.state.videourl;
        sessionStorage.setItem("videourl", JSON.stringify(video));
        this.props.history.push("/post-detail");
      }
    }
  }
  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      consent: true,
      modelopen: false,
    });
  };
  handleCancel = (e) =>{
    e.preventDefault();
    this.setState({
      consent: false,
      modelopen: false,
    });
  }

  render() {
    let isEnabled = false
    if(this.state.smallthumb && this.state.largethumb !== ""){
      isEnabled = true
    }
    return (
      <div className="container-fluid category-bg">
        <div className="row">
          <h2>Paste the Link to Upload Video</h2>
          <div className="upload-img">
            <img
              src={require("../images/video-upload.svg")}
              alt="upload-video"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-3 mb-5 col-sm-10 mx-auto">
            <form>
              <div className="input-group link-post">
                <input
                  type="text"
                  className="form-control rounded-0"
                  id="validationDefaultUsername"
                  placeholder="Paste your youtube URL here"
                  aria-describedby="inputGroupPrepend2"
                  required
                  onChange={this.handleChange("videourl").bind(this)}
                  name="videourl"
                  value={this.state.videourl}
                />
                {/* <div className="input-group-prepend">
        <input type="submit" value="Upload" className="btn-post-link"  />
      </div> */}
              </div>
              <div className="video-thumb">
                {/* <ReactPlayer                                                    
          
           url={"https://www.youtube.com/watch?v=UVCP4bKy9Iw"}
           light="http://placekitten.com/200/300"
           playing
           controls
          /> */}
                <img src={this.state.smallthumb} className="videoFrame" alt=""></img>
              </div>
              <div className="row upload-controls">
                <div className="col-md-2 col-sm-9">
                  <button value="cancel" className="button-cancel">
                    <Link to="/feed">Cancel</Link>
                  </button>
                </div>

                <div className="col-md-1 offset-md-7 col-sm-1">
                  <button value="back" className="button-cancel">
                    <Link to="/select-passion">Back</Link>
                  </button>
                </div>
                <div className="col-md-2 col-sm-2">
                  <button
                    value="Next"
                    className="btn-login"
                    onClick={this.handleSubmit.bind(this)}
                    disabled={!isEnabled}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Dialog
          open={this.state.modelopen}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="agreedialog"
        >
          <DialogTitle id="alert-dialog-title">{"Do you Agree?"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ padding: "0 10%" }}
            >
The video you are posting,either you are the owner of this particular video and you have full copyright over it<strong> OR </strong>you have the permission form the owner 
              to share in your FameNet profile
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleCancel} id="btn-famenet" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.handleClose} id="btn-famenet" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(UploadLink);