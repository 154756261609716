import React from "react";
import ApiService from "../service/ApiService";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
class SearchBar extends React.Component {
  constructor(props) {
    super();
    this.togglePanel = this.togglePanel.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      open: true,
      active: false,
      search: "",
      result: [],
      openresult: false,
    };
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e) {
    if (!this.state.active) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    const currentState = this.state.openresult;
    const opened = this.state.active;
    this.setState({
      active: !opened,
      search: "",
      result: [],
      openresult: !currentState,
    });
  }

  handleSearch = (input) => (e) => {
    e.preventDefault();

    this.setState({
      [input]: e.target.value,
    });
    if (this.props.LoginData.isadmin === "true" ||  this.props.LoginData?.bookerlogin && this.props.LoginData.bookerlogin[0] === "success") {
      let searchtext = e.target.value;
      if (searchtext.length > 1) {
        ApiService.SearchbyProfile(searchtext).then((res) => {
          if (res.data.dataItemslist.length > 0) {
            this.setState({
              result: res.data.dataItemslist,
            });
          }
        });
      }
    } else if (this.props.LoginData.loginSuccesData.userProfileId) {
      let cookieValue = this.props.LoginData.loginSuccesData.sessionId;
      let profileid = this.props.LoginData.loginSuccesData.userProfileId;
      let search = e.target.value;
      if (search.length > 1) {
        ApiService.Search("normal", search, cookieValue, profileid).then(
          (res) => {
            if (res.data.dataItemslist.length > 0) {
              this.setState({
                result: res.data.dataItemslist,
              });
            }
          }
        );
      }
      return;
    }
  };
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.togglePanel();
  }

  viewSearch = (e) => {
    e.preventDefault();
    this.setState({
      search: "",
    });
  };

  render() {
    return (
      <div className="search-div">
        <div
          ref={(node) => {
            this.node = node;
          }}
        >
          <i className="fa fa-search" onClick={(e) => this.togglePanel(e)}></i>

          <div className="content">
            <form autoComplete="off">
              {this.state.open ? (
                <input
                  type="text"
                  className={this.state.active ? "new-class" : null}
                  placeholder="Search here"
                  onChange={this.handleSearch("search").bind(this)}
                  name="search"
                  value={this.state.search}
                  style={{
                    width: "0",
                    position: "absolute",
                    right: "0",
                    top: "0",
                    transition: "all 500ms 0s ease-out",
                    opacity: "0",
                    borderWidth: "0 0 2px 0",
                    background: "transparent",
                    padding: "3px",
                    borderColor: "#fff",
                  }}
                ></input>
              ) : null}
            </form>
          </div>
        </div>
        {this.state.openresult && (
          <div className="search-result">
            <ul>
              {this.state.result.map((result, i) => (
                <li key={i}>
                  <Link
                    to={{
                      pathname: this.props.LoginData.loginSuccesData.userProfileId ? `/default-profile/${result.userProfileId}` : `/userprofile/${result.userProfileId}`,
                      state: result.userProfileId,
                    }}
                  >
                    {result.profilePic === null ? (
                      <img src="/user-img.png" alt="" />
                    ) : (
                      <img src={result.profilePic} alt=""></img>
                    )}

                    <h5>
                      {result.userName}
                      <br />
                      <span>{result.fullName}</span>
                    </h5>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(SearchBar);