import axios from "axios";
import store from "../store/store";

const baseURL = "https://service1.famenet.in/fameplus-user-profile-service";

export const axiosData = () => {
  const data = store.getState();

  const BaseApi = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json'
    },

  });
  return BaseApi;
}
