import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ImgIcon } from "../images/img.svg";
import { MDBCol, MDBRow, MDBModal } from "mdbreact";
import ApiService from "../service/ApiService";
import { ReactComponent as ActIcon } from "../images/video.svg";
import { ReactComponent as SingIcon } from "../images/mic.svg";
import { ReactComponent as DanceIcon } from "../images/dancing.svg";
import ReactPlayer from "react-player";
import PostType from "../postType";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EditPost from "./editPost";
import DeletePost from "./deletePost";
import { connect } from "react-redux";

class ProfilePosts extends Component {
  constructor(props) {
    super();
    this.state = {
      modal: false,
      editModal: false,
      vieweeid: null,
      deleteModal: false,
      selectedPassion: "",
      userposts: [],
      numberofposts: "",
      isself: false,
      selectedPost: null,
      open: false,
      userdata: "",
      loading: true,
      width: window.innerWidth,
      commentLists: "",
      commentModalList: [],
      like: "false",
      per: 15,
      page: 0,
      totalPages: null,
      scrolling: false,
      addcomment: "false",
      dataModal: {
        userName: "",
        userProfilePic: "",
        description: "",
        imageFileLocation: "",
        isLikedByViewer: "",
        mediaType: "",
        passionType: "",
        postCommentCount: "",
        postId: "",
        title: "",
        youTubeVideoDetails: {
          youTubeVideoUrl: "",
        },
      },
    };
    this.baseState = this.state;
  }

  refreshcomment = (refresh) => {
    this.setState({
      addcomment: refresh,
    });
  };

  toggle = (data) => {
    this.setState({
      modal: true,
      dataModal: data,
    });
    
  };
  editModal = (data) => {
    this.setState({
      editModal: true,
      dataModal: data,
    });
  };
  modalclose = (modalreq) => {
    this.setState(
      {
        editModal: false,
        userposts: [],
      },
      this.fetchUserPosts
    );
  };
  deleteModal = (data) => {
    this.setState({
      deleteModal: true,
      dataModal: data,
    });
  };
  deletemodalclose = (modalreq) => {
    this.setState(
      {
        deleteModal: modalreq,
        userposts: [],
      },
      this.fetchUserPosts
    );
    let value = true;
    this.props.getdel(value);
  };
  closemodalonly = (req) => {
    this.setState({
      deleteModal: req,
    });
  };

  handleLike = (e) => {
    e.preventDefault();
    this.setState({
      like: "true",
    });
  };
  updateComment = (newComment) => {
    this.setState({
      commentLists: newComment,
    });
  };
  handleModalClose = (i) => {
    this.setState({
      modal: false,
    });
  };

  componentDidMount() {
    this.fetchUserPosts();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
    this.setState({
      selectedPassion: this.props.passion,
      vieweeid: this.props.vieweeid,
      isself: this.props.selfprofile,
    });
  }
  componentDidUpdate() {
    if (this.state.vieweeid !== this.props.vieweeid) {
      this.setState(
        (prevState) => ({
          userposts: [],
          vieweeid: this.props.vieweeid,
          isself: this.props.selfprofile,
        }),
        this.fetchUserPosts
      );
    }
  }

  handleScroll = (e) => {
    const { scrolling, totalPages, page } = this.state;
    if (scrolling) return;
    if (totalPages <= page) return;
    const lastdiv = document.querySelector(
      ".all-posts > .mobile-profile:last-child"
    );
    if (lastdiv != null && this.state.totalPages > 1) {
      const lastdivoffset = lastdiv.offsetTop + lastdiv.clientHeight;
      const pageoffset = window.pageYOffset + window.innerHeight;
      var bottomoffset = 20;
      if (pageoffset > lastdivoffset - bottomoffset) this.loadmorePosts();
    }
  };
  fetchUserPosts = () => {
     if (this.props.LoginData.loginSuccesData.userProfileId) {
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId
      const profileid = this.props.LoginData.loginSuccesData.userProfileId;
      const vieweeUserid = this.props.vieweeid;
      const { userposts, per, page } = this.state;
      ApiService.viewPosts(
        vieweeUserid,
        this.props.passion,
        profileid,
        cookieValue,
        page,
        per
      ).then((res) => {
        let totalp = res.data.dataItemslist.length / per;

        this.setState({
          userposts: [...userposts, ...res.data.dataItemslist],
          scrolling: false,
          totalPages: totalp,
          numberofposts: res.data.dataItemslist.length,
          isself: this.props.selfprofile,
          loading: false,
          vieweeid: this.props.vieweeid,
        });
      });
    }
    else {
      const vieweeUserid = this.props.vieweeid;
      const { userposts, per, page } = this.state;
      ApiService.PostsAdminView(
        vieweeUserid,
        this.props.passion,
        page,
        per
      ).then((res) => {
        let totalp = res.data.dataItemslist.length / per;

        this.setState({
          userposts: [...userposts, ...res.data.dataItemslist],
          scrolling: false,
          totalPages: totalp,
          numberofposts: res.data.dataItemslist.length,
          isself: this.props.selfprofile,
          loading: false,
          vieweeid: this.props.vieweeid,
        });
      });
    }
  };
  loadmorePosts = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
        scrolling: true,
      }),
      this.fetchUserPosts
    );
  };
  render() {
    const handleOpen = () => {
      this.setState({ open: !this.state.open });
    };
    const handleClose = () => {
      this.setState({ open: false });
    };

    let passionIcon;
    if (this.state.selectedPassion === "ACTING") {
      passionIcon = <ActIcon />;
    } else if (this.state.selectedPassion === "MUSIC") {
      passionIcon = <SingIcon />;
    } else if (this.state.selectedPassion === "DANCING") {
      passionIcon = <DanceIcon />;
    } else if (this.state.selectedPassion === "PICTURE") {
      passionIcon = <ImgIcon />;
    }

    let noPost;
    if (this.props.selfprofile === "true") {
      noPost = (
        <>
          {" "}
          <p>Upload Your First Post</p>
          <button onClick={handleOpen}>
            <i className="fa fa-camera"></i> Upload
          </button>
          <PostType open={this.state.open} close={handleClose} />
        </>
      );
    }
    if (this.state.loading === true) {
      return (
        <div
          style={{
            display: "block",
            margin: "30vh auto",
            height: "50vh",
            width: "50px",
          }}
        >
          <i
            className="fa fa-spinner fa-pulse"
            style={{
              fontSize: "60px",

              color: "#b81b21",
            }}
          />
        </div>
      );
    } else if (this.state.numberofposts === 0) {
      return (
        <div className="default-post">
          <div className="default-img">{passionIcon}</div>

          <h3>No Posts Yet</h3>
          {noPost}
        </div>
      );
    } else {
      return (
        <>
          <MDBRow className="all-posts">
            {this.state.userposts !== null ? (
              this.state.userposts.map((postData, i) => {
                return (
                  <MDBCol
                    md="4"
                    sm="4"
                    key={postData.postId}
                    className="mobile-profile"
                  >
                    <ul className="postlist">
                      <li>
                        <div className="pic_cont">
                          <div className="content-overlay"></div>
                          {postData.mediaType === "video" ? (
                            <>
                              <img
                                alt="random"
                                src={
                                  postData.youTubeVideoDetails
                                    .youTubeVideoHighThumbNail
                                }
                                className="img-responsive"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                alt="random"
                                src={postData.imageFileLocation}
                                className="img-responsive"
                              />
                            </>
                          )}
                          <div className="content-details fadeIn-top">
                            <ul>
                              <li>
                                {" "}
                                <button onClick={() => this.toggle(postData)}>
                                  <VisibilityIcon />
                                </button>
                              </li>
                              {this.props.selfprofile === "true" ? (
                                <>
                                  <li>
                                    {" "}
                                    <button
                                      onClick={() => this.editModal(postData)}
                                    >
                                      <EditIcon />
                                    </button>
                                  </li>
                                  <li>
                                    {" "}
                                    <button
                                      onClick={() => this.deleteModal(postData)}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </ul>

                            <ul>
                              <li>
                                <span>
                                  <img
                                    src={require("../images/clap-white.svg")}
                                    className="post-count-img"
                                    alt=""
                                  />
                                  {postData.postLikeCount}
                                </span>
                              </li>
                              <li>
                                <span>
                                  <ChatBubbleOutlineOutlinedIcon />{" "}
                                  {postData.postCommentCount}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </MDBCol>
                );
              })
            ) : (
              <></>
            )}
          </MDBRow>
          <MDBModal
            isOpen={this.state.modal}
            size="lg"
            id={this.state.dataModal.postid}
            // overflowScroll={false}
            // backdrop={false}
          >
            <div className="modal-content modal-profile">
              <div className="modal-feed-head">
                {this.state.dataModal.userProfilePic === null ? (
                  <img
                    src="/user-img.png"
                    alt=""
                    className="modal-img-profile-feed"
                  />
                ) : (
                  <img
                    src={this.state.dataModal.userProfilePic}
                    // src="https://upload.wikimedia.org/wikipedia/commons/e/e6/Anushka_Sharma_promoting_Zero.jpg"
                    className="modal-img-profile-feed"
                    alt=""
                  />
                )}

                <div className="modal-profile-feed-name">
                  <h4 className="main-title text-left">
                    {this.state.dataModal.userName}
                  </h4>
                </div>
                <button
                  onClick={this.handleModalClose}
                  id={this.state.dataModal.postId}
                  className="modal-close-btn"
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <div className="post-detail">
                <h4>{this.state.dataModal.title}</h4>
                <p>{this.state.dataModal.description}</p>
              </div>
              {this.state.dataModal.mediaType === "video" ? (
                <>
                  <ReactPlayer
                    className="videoFrame main-view"
                    url={
                      this.state.dataModal.youTubeVideoDetails.youTubeVideoUrl
                    }
                    controls={true}
                  />
                </>
              ) : (
                <img
                  alt="random"
                  src={this.state.dataModal.imageFileLocation}
                  className="img-responsive modal-main-view"
                />
              )}

              {/* <ViewComment
                commentlist={this.state.commentLists}
                postid={this.state.dataModal.postId}
                refreshFunction={this.updateComment}
                postcomment={this.state.dataModal.postCommentCount}
                postlike={this.state.dataModal.postLikeCount}
                liked={this.state.dataModal.isLikedByViewer}
                self={this.state.isself}
              /> */}
              <br />
              {this.props.LoginData.loginSuccesData.userProfileId ? (
                 <Link
                 className="btn-view"
                 to={{
                   pathname: "../viewpost",
                   state: this.state.dataModal.postId,
                 }}
                 onClick={this.handleModalClose}
                 id={this.state.dataModal.postId}
               >
                 View full post
               </Link>
              ):(
                <Link
                 className="btn-view"
                 to={{
                   pathname: `../famepost/${this.state.dataModal.postId}`,
                   state: this.state.dataModal.postId,
                 }}
                 onClick={this.handleModalClose}
                 id={this.state.dataModal.postId}
               >
                 View Full Post
               </Link>
              )}
             
            </div>
          </MDBModal>

          <MDBModal
            // className="edit-modal"
            isOpen={this.state.editModal}
            size="lg"
            id={this.state.dataModal.postId}
            overflowScroll={false}
          >
            {/* <Fade 
            in={this.state.editModal}
            > */}
            <EditPost
              getmodalclose={this.modalclose}
              postedit={this.state.dataModal}
            />
            {/* </Fade> */}
          </MDBModal>

          <MDBModal
            className="delete-modal"
            isOpen={this.state.deleteModal}
            id={this.state.dataModal.postId}
          >
            <DeletePost
              getdeletemodalclose={this.deletemodalclose}
              postedit={this.state.dataModal}
              getmodalclose={this.closemodalonly}
            />
          </MDBModal>
        </>
      );
    }
  }
}
const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(ProfilePosts);
