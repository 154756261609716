import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBInput } from "mdbreact";

export default class PrivacyPolicy extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-4 pr-0 pb-4 pl-0">
          <div className="privacy-head">
            <h3>Privacy Policy</h3>
            <h5>(Effective on March 2022)</h5>
            <p>
              Famenet is committed to protecting your privacy. We have prepared
              this Privacy Policy to describe to you our practices regarding the
              Personal Data (as defined below) we collect from users of our
              website located at Famenet.in . In addition, this Privacy Policy
              tells you about your privacy rights and how the law protects you.
            </p>
            <p>This website is only for those indiviuals.</p>
            <p>
              It is important that you read this Privacy Policy together with
              any other privacy notice or fair processing notice we may provide
              on specific occasions when we are collecting or processing
              Personal Data about you so that you are fully aware of how and why
              we are using your data. This Privacy Policy supplements the other
              notices and is not intended to override them.
            </p>
          </div>

          <div className="container-fluid pripolicy">
            <div className="acc-block">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Data we collect from user and what we do of it
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      In Famenet during the time of registration we collect only
                      six basic personal information i.e. Full name ,email id
                      ,location Full name we collect in order to show in the
                      profile of the respective account.
                    </p>
                    <p>
                      Email id we collect to send welcome message and email for
                      temporary password ,in case user forgets his password
                      Finally location is a n optional field ,if the user is
                      interested in sharing ,will share else not .
                    </p>
                    <p>
                      And if shared with us ,we will use it to contact them if
                      any event or any occasion occurs near to their said
                      location. We do not collect any card(credit/debit) related
                      data.
                    </p>
                    <p>We do not collect any digital wallet related data.</p>
                    <p>
                      Basically we do not collect any money or transaction
                      related information.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Youtube Related information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                   <p>For now we only support the youtube videos to be shared in our eco  system.</p>
                   <p>So the user needs to have the full copyright  of the video that he is sharing ,failing of which he may have to face legal actions .</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
             <Accordion>
             <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>What we do of the data collected</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                   <p>Please note we do not sell or compromise your personal data to any external parties .</p>
                   <p>Your data is stored in Famenet Ecosystem and is secure over there.</p>
                   <p>On deletion of the data by the user ,the data gets finally  deleted .</p>
                   <p>Since its your data ,you have full control over it .
</p>
                  </Typography>
                </AccordionDetails>
             </Accordion>
            </div>
          </div>
        </div>
      </>
    );
  }
}
