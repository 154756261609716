import React, {Component} from "react";
import { Button } from "@material-ui/core";
import ApiService from "../service/ApiService";


export default class Logout extends Component{
    state={
        navigate: false
    }

    logout = () => {
       
        this.setState({navigate:true});
        const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("sessionId"))
      .split("=")[1];
    const viewerId = localStorage.getItem("userProfileId")
        let userdata = {
            userProfileid:viewerId,
            sessionId:cookieValue
        }
        ApiService.Logout(userdata)
        .then((res)=>{
          
        })
        localStorage.clear("userProfileId");
        document.cookie = "sessionId = ";

        window.location = '/';

 };
 render(){
     return <Button onClick={this.logout}>Logout</Button>
 }
}
