import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import ApiService from "../components/service/ApiService";
import Comments from "../components/feed/comments";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import ReplyAllRoundedIcon from "@material-ui/icons/ReplyAllRounded";
import Youtube from "./images/youtube.png"

import "../components/feed/feeds.css";
import { connect } from "react-redux";
class ViewPost extends Component {
  constructor(props) {
    super();
    this.state = {
      postdata: [],
      defaultImg: "user-img.png",
      loading: true,
      open: false,
      commentLists: "",
      like: "false",
      addcomment: "false",
      Nopostmessage: false,
      copySuccess: false,
    };
  }
  componentDidMount() {
    this.fetchPost();
  }

  fetchPost = () => {
    if (this.props.LoginData?.isadmin === 'true' || this.props.LoginData?.bookerlogin[0] === "success") {
      const postId = this.props.location.state;

      ApiService.SearchbyPost(postId).then((res) => {
        this.setState({
          postdata: res.data.dataItem,
          loading: false,
          Nopostmessage: false,
        });
      });

    } else if (this.props.LoginData.loginSuccesData.userProfileId) {
      const cookieValue = this.props.LoginData.loginSuccesData.sessionId
      const viewerId = this.props.LoginData.loginSuccesData.userProfileId;
      const postId = this.props.location.state;

      ApiService.viewUserPost(postId, cookieValue, viewerId).then((res) => {
        if (res.data.dataItem.errorCode) {
          this.setState({
            Nopostmessage: true,
            loading: false,
          });
        }
        this.setState({
          postdata: res.data.dataItem,
          loading: false,
        });
      });
    }
  };

  handleLike = (e) => {
    e.preventDefault();
    this.setState({
      like: "true",
    });
  };
  updateComment = (newComment) => {
    this.setState({
      commentLists: newComment,
      addcomment: "true",
    });
  };
  refreshcomment = (refresh) => {
    this.setState({
      addcomment: refresh,
    });
  };
  handleClickShowAlert = (e) => {
    this.setState({
      copySuccess: e,
    });

    setTimeout(() => {
      this.setState({
        copySuccess: false,
      });
    }, 2000);
  };
  
  render() {
   
    if (this.state.loading === true && this.state.postdata.length < 1) {
      return (
        <div
          style={{
            display: "block",
            margin: "30vh auto",
            height: "50vh",
            width: "50px",
          }}
        >
          <i
            className="fa fa-spinner fa-pulse"
            style={{
              fontSize: "60px",

              color: "#b81b21",
            }}
          />
        </div>
      );
    } else if (this.state.Nopostmessage === true) {
      return (
        <div style={{ overflow: "auto", width: "100%", background: "#ebebeb" }}>
          <div className="back-placement">
            <button onClick={() => this.props.history.goBack()}>
              <i className="fa fa-arrow-left"></i> Back
            </button>
          </div>
          <ul
            className="circle"
            style={{
              width: "100%",
              background: "#ebebeb",
              paddingTop: "1vw",
              marginTop: "0",
            }}
          >
            <li>
              <div className=" feed-view-single" style={{borderRadius:"10px"}}>
                <div className="feed-head" style={{ minHeight: "80vh" }}>
                  <h4
                    style={{
                      textAlign: "center",
                      display: "block",
                      width: "100%",
                      padding: "35vh 0",
                    }}
                  >
                    <PriorityHighIcon className="exclaim-icon" />
                    <br />
                    The post has either been deleted by the owner or currently
                    unavailable
                  </h4>
                </div>
              </div>
            </li>
          </ul>
        </div>
      );
    }
    return (
      <div style={{ overflow: "auto", width: "100%", background: "#ebebeb" }}>
        <div className="back-placement">
          <button onClick={() => this.props.history.goBack()}>
            <i className="fa fa-arrow-left"></i> Back
          </button>
        </div>
        <ul
          className="circle"
          style={{
            width: "100%",
            background: "#ebebeb",
            paddingTop: "1vw",
            marginTop: "0",
          }}
        >
          <li key={this.state.postdata.postId}>
           
            <div className=" feed-view-single" >
              <div className="feed-head">
                <Link
                  to={{
                    pathname: this.props.LoginData.loginSuccesData.userProfileId ? `/default-profile/${this.state.postdata.userProfileId}` : `/userprofile/${this.state.postdata.userProfileId}`,

                    state: this.state.postdata.userProfileId,
                  }}
                >
                  {this.state.postdata.userProfilePic === null ? (
                    <img
                      src={this.state.defaultImg}
                      className="img-profile-feed"
                      alt="post"
                    />
                  ) : (
                    <img
                      src={this.state.postdata.userProfilePic}
                      alt="post"
                      className="img-profile-feed"
                    />
                  )}

                  <div className="profile-feed-name">
                    <h4 className="main-title text-left">
                      {this.state.postdata.userName}
                    </h4>
                  </div>
                </Link>
                <div className="profile-feed-time">
                  <h5>{this.state.postdata.timeStamp}</h5>
                  <h6
                    className={`${
                      this.state.copySuccess === this.state.postdata.postId
                        ? "copied-link new-copied"
                        : "hide-copied new-hide"
                    }`}
                  >
                    Link copied{" "}
                  </h6>

                  <span
                    onClick={(e) =>
                      navigator.clipboard.writeText(
                        `https://famenet.in/famepost/${this.state.postdata.postId}`
                      ) && this.handleClickShowAlert(this.state.postdata.postId)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <ReplyAllRoundedIcon
                      style={{ transform: "scaleX(-1)", marginTop: "-10px" }}
                    />
                  </span>
                </div>
              </div>
              <div className="post-detail">
                <h4>{this.state.postdata.title}</h4>
                <p>{this.state.postdata.description}</p>
              </div>
              {this.state.postdata.mediaType === "image" ? (
                <img
                  src={this.state.postdata.imageFileLocation}
                  alt="post"
                  className="main-view"
                />
              ) : (
                <>
                <img src={Youtube} className="yt-logo-view" />
                <ReactPlayer
                  className="videoFrame main-view"
                  url={this.state.postdata.youTubeVideoDetails.youTubeVideoUrl}
                  controls={true}
                />
                </>
              )}
              {/* <iframe width="100%" height="400px" src="https://youtu.be/6qYpEcXl4QI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              {/* <ul className="prfl-buttons">
                      <li>
                        <Likes
                          postlike={circleFeed.postLikeCount}
                          liked={circleFeed.isLikedByViewer}
                          postid={circleFeed.postId}
                        />
                      </li>
                    </ul> */}
              <Comments
                commentlist={this.state.commentLists}
                postid={this.state.postdata.postId}
                refreshFunction={this.updateComment}
                postcomment={this.state.postdata.postCommentCount}
                postlike={this.state.postdata.postLikeCount}
                liked={this.state.postdata.isLikedByViewer}
                postuserid={this.state.postdata.userProfileId}
              />
            </div>
          </li>
      
        </ul>

        {/* <button onClick={this.LoadMore}>Load More</button> */}
      </div>
    );
  }
}

const mapStateToProps = (state) =>{
    
    return {
        LoginData : state.LoginData
    }
}

export default connect(mapStateToProps)(ViewPost);
